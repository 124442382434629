$(document).ready(function() {
    
    if ( $(window).width() <= 1023) {      
      $('.menu-inner ul ul ul').removeClass('dropdown-1');
      $('.drop-second>ul').addClass('dropdown');
      $('.icon-arrow-nav').removeClass('text-with-icon');
      $('.icon-arrow-nav').removeClass('icon-arrow-right');
      $('.icon-arrow-nav').removeClass('icon-white');
    }
    if ($(window).width() <= 1023){ 
        $('<button class=\'dropdown-toggler\'>+</button>').insertAfter('.drop-second > a');                
        $('.drop-second button').on('click', function(){
          $(this).next('ul').slideToggle();
          $(this).next('ul').toggleClass('active');
          if ( $( this ).next('ul').hasClass( "active" ) ) {
            $(this).html("-");
          }else{
            $(this).html("+");
          }
        });           
    }
  
    $(".bg-dark-green").hide();
    $(".bg-dark-green-ep").hide();

    function isResponsiveDevice() {
        if($('#responsiveChecker').css('display') === 'none'){
            return true;
        }else{
            return false;
        }
    } 
    function preventReturn(ev) {
        if (ev.preventDefault) {
            ev.preventDefault();
        }
        else {
            ev.returnValue = false;
        }
    }  
     $.inRegExArray = function(t, e, i) {
            if (!e) return -1;
            i = i || 0;
            for (var n = i; n < e.length; n++)
                if (e[n].test(t)) return n;
            return -1
        };
    var IsExternal = function() {
        var t = function(t) {
                if (0 === t.indexOf("//") && (t = location.protocol + t), "http" != t.substring(0, 4)) return !1;
                var e = t.toLowerCase().replace(/([a-z]*)?(:\/\/)/, "").split("/")[0];
                return "www." == e.substring(0, 4) && (e = e.substring(4)), e
            },
            e = [new RegExp("//[^/]*" + t(location.href), "i")],
            i = $("body").data("local-domains");
        i = "string" == typeof i ? i.split(",") : [];
        for (var n = 0; n < i.length; n++) {
            var o = $.trim(i[n]);
            o.length > 0 && e.push(new RegExp(o, "i"))
        }
        return function(t) {
            return void 0 !== t && ((t.indexOf(":") > -1 || t.indexOf("//") > -1) && 0 != t.indexOf("mailto") && -1 == t.indexOf('ctfassets') && -1 == t.indexOf('contentful') && -1 == t.indexOf('www.MyElanco.co.uk') && -1 == t.indexOf('www.myelanco.co.uk') && -1 == t.indexOf('tel') && -1 == t.indexOf('mailto') && -1 == t.indexOf('www.elancostatements.com') && -1 == t.indexOf('javascript:void(0);'))
        }
    }();
    $.fn.addFancyTooltips = function() {
        var t = $(this).find("[rel~=tooltip]"),
            e = !1,
            i = !1;
        t.bind("mouseenter", function() {
            e = $(this);
            var t = e.attr("title");
            if (i = $('<div id="tooltip-external"></div>'), !t || "" == t) return !1;
            e.removeAttr("title"), i.css("opacity", 0).css("z-index",1000).html(t).appendTo("body");
            var n = function() {
                var t = Math.min(.75 * $(window).width(), 600);
                $(window).width() < 1.5 * i.outerWidth() ? i.css("max-width", t + "px") : i.css("max-width", 340);
                var n = e.offset().left + e.outerWidth() / 2 - i.outerWidth() / 2,
                    o = e.offset().top - i.outerHeight() - 20;
                if (n < 0 ? (n = e.offset().left + e.outerWidth() / 2 - 20, i.addClass("left")) : i.removeClass("left"), n + i.outerWidth() > $(window).width() ? (n = e.offset().left - i.outerWidth() + e.outerWidth() / 2 + 20, i.addClass("right")) : i.removeClass("right"), o < 0) {
                    var o = e.offset().top + e.outerHeight();
                    i.addClass("top")
                } else i.removeClass("top");
                i.css({
                    left: n,
                    top: o
                }).animate({
                    top: "+=10",
                    opacity: 1
                }, 50)
            };
            n(), $(window).resize(n);
            var o = function() {
                i.animate({
                    top: "-=10",
                    opacity: 0
                }, 50, function() {
                    $(this).remove()
                }), e.attr("title", t)
            };
            e.bind("mouseleave", o), i.bind("click", o)
        })
    };

   addTooltipsForExternalLinks = function() {
    var t = $("body").data("external-notice");
    "string" == typeof t && (t = $.trim(t)), ("string" != typeof t || t.length < 1) && (t = "Please note: If you click this link, you will be leaving the Elanco website, MyElanco.ie. Different privacy policies and/or terms and conditions may apply."), $("body").find("a").each(function() {
        var e = $(this);
        if ((e.attr("href")!=undefined) && IsExternal(e.attr("href")) && "tooltip" != e.attr("rel")) {
            var i = t;
            if (isResponsiveDevice() == true) {
            e.attr("title", i), e.attr("rel", "tooltip"), e.attr("target", "_blank")
            }else{
                $(this).click(function(e){
                var i = t;
                preventReturn(e);
                var check=confirm("Please note: If you click this link, you will be leaving the Elanco website, MyElanco.ie. Different privacy policies and/or terms and conditions may apply.");
                    if (check==true)
                        {
                            newWinURL = $(this).attr('href');
                            window.open(newWinURL);
                        }
                    }); 
                }        
            }
        }), $("body").addFancyTooltips()
    };
   $(function() {
    setTimeout(addTooltipsForExternalLinks,2000);
    });
   var selector = '.menu li';

        var url = window.location.href;
        // alert(url);
        var currentPathArray = window.location.pathname.split('/');
        // alert(currentPathArray.length);
         $(selector).each(function() {
          //1 level
          if($(this).find('a').attr('href').toUpperCase()==('/'+currentPathArray [1]).toUpperCase()){
            if (currentPathArray.length > 2) {
                //2 level
                var Subselector = $(this).find('ul:not(.level3) li'); 
                $(Subselector).each(function(){
                 var newSelect = $(this).find('a').attr('href').split('/');
                  if(newSelect.length > 2 && newSelect[2] !== undefined){
                    // $(this).parents().addClass('selected');
                    if(newSelect[2]==(currentPathArray [2])){                      
                       var c = currentPathArray [3];                    
                     
                        $(this).parents().addClass('selected');
                        $(this).find('a').filter( document.getElementsByClassName( c ) ).parent().addClass('selected');
                         //3 level
                         var SuperSubselector = $(this).find('ul:not(.level3) li');
                         if (currentPathArray.length > 3) {
                            $(SuperSubselector).each(function(){
                             var newSelect2 = $(this).find('a').attr('href').split('/');
                             if(newSelect2.length == 4 && newSelect2[3] !== undefined){
                                if(newSelect2[3]==(currentPathArray [3])){
                                    // alert('no');
                                    $(this).parents().addClass('selected');
                                    $(this).addClass('selected');
                                }
                              }
                              if(newSelect2.length > 4){
                                if(newSelect2[4] == (currentPathArray [4])){
                                    $(this).parents().addClass('selected');
                                    $(this).addClass('selected');
                                }
                              }
                            });
                         }
                         else{
                            var url = window.location.href;
                            var lastParameter = url.substr(url.lastIndexOf('/') + 1)
                            if((lastParameter != 'companion-equine') && (lastParameter != 'ruminant')){
                                $(this).addClass('selected');
                            }
                         }
                    }
                  }

                });
            }
            else{
              $(this).addClass('selected');
            }
          }
         });
});
var answers = ["D","B","A","B","A"];
var tot = answers.length;
function getCheckedValue( radioName ){
    var radios = document.getElementsByName( radioName ); 
    for(var y=0; y<radios.length; y++)
      if(radios[y].checked) return radios[y].value;
}

function getScore(){
  var score = 0;
  for (var i=0; i<tot; i++)
    if(getCheckedValue("question"+i)===answers[i]) score += 1; 
  return score;
}

function returnScore(){
  alert("Your score is "+ getScore() +"/"+ tot);
}

$(window).on('load',function(){
   if((window.location.pathname != '/login') &&  (window.location.pathname.indexOf("/elanco") != 0) && (window.location.pathname != '/logged') ){
        if( Cookies.get('isBanner') == undefined ){
          if($('#message-banner').text() != ''){
           $(".bg-dark-green").show();
           Cookies.set('isBanner', 'yes');
         }
        }else{
            $(".bg-dark-green").hide();
        }
      }else if (window.location.pathname == '/logged' ){
         //if( Cookies.get('isBannerBlue') == 'yes' ){
         //  $(".bg-dark-blue").hide();
        //}else if(Cookies.get('isBannerBlue') == undefined){
          if( Cookies.get('isBanner') == undefined ){
             if($('#message-banner').text() != ''){
              $(".bg-dark-green").show();
              Cookies.set('isBanner', 'yes');
            }
            }else{
              $(".bg-dark-green").hide();
          //}
        }
      }else if (window.location.pathname.indexOf("/elanco") == 0 ){
         if( Cookies.get('isBannerEp') == undefined ){
          if($('#message-banner-ep').text() != ''){
            $(".bg-dark-green-ep").show();
            Cookies.set('isBannerEp', 'yes');
          }else{
            $(".bg-dark-green-ep").hide();
          }
        }else{
            $(".bg-dark-green-ep").hide();
        }
      } else{
        Cookies.remove('isBanner');
        Cookies.remove('isBannerEp');
        Cookies.remove('isBannerBlue');
      }
});
