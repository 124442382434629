/* Wed, 05 Jul 2017 11:27:00 GMT */
!function (a) {
  a.fn.appendAround = function () {
    return this.each(function () {
      function b(b) {
        return 'none' === a(b).css('display')
      }
      function c() {
        if (b(g)) {
          var a = 0;
          i.each(function () {
            b(this) || a || (d.appendTo(this), a++, g = this)
          })
        }
      }
      var d = a(this),
      e = 'data-set',
      f = d.parent(),
      g = f[0],
      h = f.attr(e),
      i = a('[' + e + '=\'' + h + '\']');
      c(),
      a(window).bind('resize', c)
    })
  },
  a('.append-around').appendAround()
}(jQuery),
!function (a, b, c, d) {
  'use strict';
  function e(a, b, c) {
    return setTimeout(j(a, c), b)
  }
  function f(a, b, c) {
    return !!Array.isArray(a) && (g(a, c[b], c), !0)
  }
  function g(a, b, c) {
    var e;
    if (a) if (a.forEach) a.forEach(b, c);
     else if (a.length !== d) for (e = 0; e < a.length; ) b.call(c, a[e], e, a),
    e++;
     else for (e in a) a.hasOwnProperty(e) && b.call(c, a[e], e, a)
  }
  function h(b, c, d) {
    var e = 'DEPRECATED METHOD: ' + c + '\n' + d + ' AT \n';
    return function () {
      var c = new Error('get-stack-trace'),
      d = c && c.stack ? c.stack.replace(/^[^\(]+?[\n$]/gm, '').replace(/^\s+at\s+/gm, '').replace(/^Object.<anonymous>\s*\(/gm, '{anonymous}()@')  : 'Unknown Stack Trace',
      f = a.console && (a.console.warn || a.console.log);
      return f && f.call(a.console, e, d),
      b.apply(this, arguments)
    }
  }
  function i(a, b, c) {
    var d,
    e = b.prototype;
    d = a.prototype = Object.create(e),
    d.constructor = a,
    d._super = e,
    c && la(d, c)
  }
  function j(a, b) {
    return function () {
      return a.apply(b, arguments)
    }
  }
  function k(a, b) {
    return typeof a == oa ? a.apply(b ? b[0] || d : d, b)  : a
  }
  function l(a, b) {
    return a === d ? b : a
  }
  function m(a, b, c) {
    g(q(b), function (b) {
      a.addEventListener(b, c, !1)
    })
  }
  function n(a, b, c) {
    g(q(b), function (b) {
      a.removeEventListener(b, c, !1)
    })
  }
  function o(a, b) {
    for (; a; ) {
      if (a == b) return !0;
      a = a.parentNode
    }
    return !1
  }
  function p(a, b) {
    return a.indexOf(b) > - 1
  }
  function q(a) {
    return a.trim().split(/\s+/g)
  }
  function r(a, b, c) {
    if (a.indexOf && !c) return a.indexOf(b);
    for (var d = 0; d < a.length; ) {
      if (c && a[d][c] == b || !c && a[d] === b) return d;
      d++
    }
    return - 1
  }
  function s(a) {
    return Array.prototype.slice.call(a, 0)
  }
  function t(a, b, c) {
    for (var d = [
    ], e = [
    ], f = 0; f < a.length; ) {
      var g = b ? a[f][b] : a[f];
      r(e, g) < 0 && d.push(a[f]),
      e[f] = g,
      f++
    }
    return c && (d = b ? d.sort(function (a, c) {
      return a[b] > c[b]
    })  : d.sort()),
    d
  }
  function u(a, b) {
    for (var c, e, f = b[0].toUpperCase() + b.slice(1), g = 0; g < ma.length; ) {
      if (c = ma[g], e = c ? c + f : b, e in a) return e;
      g++
    }
    return d
  }
  function v() {
    return ua++
  }
  function w(b) {
    var c = b.ownerDocument || b;
    return c.defaultView || c.parentWindow || a
  }
  function x(a, b) {
    var c = this;
    this.manager = a,
    this.callback = b,
    this.element = a.element,
    this.target = a.options.inputTarget,
    this.domHandler = function (b) {
      k(a.options.enable, [
        a
      ]) && c.handler(b)
    },
    this.init()
  }
  function y(a) {
    var b,
    c = a.options.inputClass;
    return new (b = c ? c : xa ? M : ya ? P : wa ? R : L) (a, z)
  }
  function z(a, b, c) {
    var d = c.pointers.length,
    e = c.changedPointers.length,
    f = b & Ea && d - e === 0,
    g = b & (Ga | Ha) && d - e === 0;
    c.isFirst = !!f,
    c.isFinal = !!g,
    f && (a.session = {
    }),
    c.eventType = b,
    A(a, c),
    a.emit('hammer.input', c),
    a.recognize(c),
    a.session.prevInput = c
  }
  function A(a, b) {
    var c = a.session,
    d = b.pointers,
    e = d.length;
    c.firstInput || (c.firstInput = D(b)),
    e > 1 && !c.firstMultiple ? c.firstMultiple = D(b)  : 1 === e && (c.firstMultiple = !1);
    var f = c.firstInput,
    g = c.firstMultiple,
    h = g ? g.center : f.center,
    i = b.center = E(d);
    b.timeStamp = ra(),
    b.deltaTime = b.timeStamp - f.timeStamp,
    b.angle = I(h, i),
    b.distance = H(h, i),
    B(c, b),
    b.offsetDirection = G(b.deltaX, b.deltaY);
    var j = F(b.deltaTime, b.deltaX, b.deltaY);
    b.overallVelocityX = j.x,
    b.overallVelocityY = j.y,
    b.overallVelocity = qa(j.x) > qa(j.y) ? j.x : j.y,
    b.scale = g ? K(g.pointers, d)  : 1,
    b.rotation = g ? J(g.pointers, d)  : 0,
    b.maxPointers = c.prevInput ? b.pointers.length > c.prevInput.maxPointers ? b.pointers.length : c.prevInput.maxPointers : b.pointers.length,
    C(c, b);
    var k = a.element;
    o(b.srcEvent.target, k) && (k = b.srcEvent.target),
    b.target = k
  }
  function B(a, b) {
    var c = b.center,
    d = a.offsetDelta || {
    },
    e = a.prevDelta || {
    },
    f = a.prevInput || {
    };
    b.eventType !== Ea && f.eventType !== Ga || (e = a.prevDelta = {
      x: f.deltaX || 0,
      y: f.deltaY || 0
    }, d = a.offsetDelta = {
      x: c.x,
      y: c.y
    }),
    b.deltaX = e.x + (c.x - d.x),
    b.deltaY = e.y + (c.y - d.y)
  }
  function C(a, b) {
    var c,
    e,
    f,
    g,
    h = a.lastInterval || b,
    i = b.timeStamp - h.timeStamp;
    if (b.eventType != Ha && (i > Da || h.velocity === d)) {
      var j = b.deltaX - h.deltaX,
      k = b.deltaY - h.deltaY,
      l = F(i, j, k);
      e = l.x,
      f = l.y,
      c = qa(l.x) > qa(l.y) ? l.x : l.y,
      g = G(j, k),
      a.lastInterval = b
    } else c = h.velocity,
    e = h.velocityX,
    f = h.velocityY,
    g = h.direction;
    b.velocity = c,
    b.velocityX = e,
    b.velocityY = f,
    b.direction = g
  }
  function D(a) {
    for (var b = [
    ], c = 0; c < a.pointers.length; ) b[c] = {
      clientX: pa(a.pointers[c].clientX),
      clientY: pa(a.pointers[c].clientY)
    },
    c++;
    return {
      timeStamp: ra(),
      pointers: b,
      center: E(b),
      deltaX: a.deltaX,
      deltaY: a.deltaY
    }
  }
  function E(a) {
    var b = a.length;
    if (1 === b) return {
      x: pa(a[0].clientX),
      y: pa(a[0].clientY)
    };
    for (var c = 0, d = 0, e = 0; b > e; ) c += a[e].clientX,
    d += a[e].clientY,
    e++;
    return {
      x: pa(c / b),
      y: pa(d / b)
    }
  }
  function F(a, b, c) {
    return {
      x: b / a || 0,
      y: c / a || 0
    }
  }
  function G(a, b) {
    return a === b ? Ia : qa(a) >= qa(b) ? 0 > a ? Ja : Ka : 0 > b ? La : Ma
  }
  function H(a, b, c) {
    c || (c = Qa);
    var d = b[c[0]] - a[c[0]],
    e = b[c[1]] - a[c[1]];
    return Math.sqrt(d * d + e * e)
  }
  function I(a, b, c) {
    c || (c = Qa);
    var d = b[c[0]] - a[c[0]],
    e = b[c[1]] - a[c[1]];
    return 180 * Math.atan2(e, d) / Math.PI
  }
  function J(a, b) {
    return I(b[1], b[0], Ra) + I(a[1], a[0], Ra)
  }
  function K(a, b) {
    return H(b[0], b[1], Ra) / H(a[0], a[1], Ra)
  }
  function L() {
    this.evEl = Ta,
    this.evWin = Ua,
    this.pressed = !1,
    x.apply(this, arguments)
  }
  function M() {
    this.evEl = Xa,
    this.evWin = Ya,
    x.apply(this, arguments),
    this.store = this.manager.session.pointerEvents = [
    ]
  }
  function N() {
    this.evTarget = $a,
    this.evWin = _a,
    this.started = !1,
    x.apply(this, arguments)
  }
  function O(a, b) {
    var c = s(a.touches),
    d = s(a.changedTouches);
    return b & (Ga | Ha) && (c = t(c.concat(d), 'identifier', !0)),
    [
      c,
      d
    ]
  }
  function P() {
    this.evTarget = bb,
    this.targetIds = {
    },
    x.apply(this, arguments)
  }
  function Q(a, b) {
    var c = s(a.touches),
    d = this.targetIds;
    if (b & (Ea | Fa) && 1 === c.length) return d[c[0].identifier] = !0,
    [
      c,
      c
    ];
    var e,
    f,
    g = s(a.changedTouches),
    h = [
    ],
    i = this.target;
    if (f = c.filter(function (a) {
      return o(a.target, i)
    }), b === Ea) for (e = 0; e < f.length; ) d[f[e].identifier] = !0,
    e++;
    for (e = 0; e < g.length; ) d[g[e].identifier] && h.push(g[e]),
    b & (Ga | Ha) && delete d[g[e].identifier],
    e++;
    return h.length ? [
      t(f.concat(h), 'identifier', !0),
      h
    ] : void 0
  }
  function R() {
    x.apply(this, arguments);
    var a = j(this.handler, this);
    this.touch = new P(this.manager, a),
    this.mouse = new L(this.manager, a),
    this.primaryTouch = null,
    this.lastTouches = [
    ]
  }
  function S(a, b) {
    a & Ea ? (this.primaryTouch = b.changedPointers[0].identifier, T.call(this, b))  : a & (Ga | Ha) && T.call(this, b)
  }
  function T(a) {
    var b = a.changedPointers[0];
    if (b.identifier === this.primaryTouch) {
      var c = {
        x: b.clientX,
        y: b.clientY
      };
      this.lastTouches.push(c);
      var d = this.lastTouches,
      e = function () {
        var a = d.indexOf(c);
        a > - 1 && d.splice(a, 1)
      };
      setTimeout(e, cb)
    }
  }
  function U(a) {
    for (var b = a.srcEvent.clientX, c = a.srcEvent.clientY, d = 0; d < this.lastTouches.length; d++) {
      var e = this.lastTouches[d],
      f = Math.abs(b - e.x),
      g = Math.abs(c - e.y);
      if (db >= f && db >= g) return !0
    }
    return !1
  }
  function V(a, b) {
    this.manager = a,
    this.set(b)
  }
  function W(a) {
    if (p(a, jb)) return jb;
    var b = p(a, kb),
    c = p(a, lb);
    return b && c ? jb : b || c ? b ? kb : lb : p(a, ib) ? ib : hb
  }
  function X() {
    if (!fb) return !1;
    var b = {
    },
    c = a.CSS && a.CSS.supports;
    return ['auto',
    'manipulation',
    'pan-y',
    'pan-x',
    'pan-x pan-y',
    'none'].forEach(function (d) {
      b[d] = !c || a.CSS.supports('touch-action', d)
    }),
    b
  }
  function Y(a) {
    this.options = la({
    }, this.defaults, a || {
    }),
    this.id = v(),
    this.manager = null,
    this.options.enable = l(this.options.enable, !0),
    this.state = nb,
    this.simultaneous = {
    },
    this.requireFail = [
    ]
  }
  function Z(a) {
    return a & sb ? 'cancel' : a & qb ? 'end' : a & pb ? 'move' : a & ob ? 'start' : ''
  }
  function $(a) {
    return a == Ma ? 'down' : a == La ? 'up' : a == Ja ? 'left' : a == Ka ? 'right' : ''
  }
  function _(a, b) {
    var c = b.manager;
    return c ? c.get(a)  : a
  }
  function aa() {
    Y.apply(this, arguments)
  }
  function ba() {
    aa.apply(this, arguments),
    this.pX = null,
    this.pY = null
  }
  function ca() {
    aa.apply(this, arguments)
  }
  function da() {
    Y.apply(this, arguments),
    this._timer = null,
    this._input = null
  }
  function ea() {
    aa.apply(this, arguments)
  }
  function fa() {
    aa.apply(this, arguments)
  }
  function ga() {
    Y.apply(this, arguments),
    this.pTime = !1,
    this.pCenter = !1,
    this._timer = null,
    this._input = null,
    this.count = 0
  }
  function ha(a, b) {
    return b = b || {
    },
    b.recognizers = l(b.recognizers, ha.defaults.preset),
    new ia(a, b)
  }
  function ia(a, b) {
    this.options = la({
    }, ha.defaults, b || {
    }),
    this.options.inputTarget = this.options.inputTarget || a,
    this.handlers = {
    },
    this.session = {
    },
    this.recognizers = [
    ],
    this.oldCssProps = {
    },
    this.element = a,
    this.input = y(this),
    this.touchAction = new V(this, this.options.touchAction),
    ja(this, !0),
    g(this.options.recognizers, function (a) {
      var b = this.add(new a[0](a[1]));
      a[2] && b.recognizeWith(a[2]),
      a[3] && b.requireFailure(a[3])
    }, this)
  }
  function ja(a, b) {
    var c = a.element;
    if (c.style) {
      var d;
      g(a.options.cssProps, function (e, f) {
        d = u(c.style, f),
        b ? (a.oldCssProps[d] = c.style[d], c.style[d] = e)  : c.style[d] = a.oldCssProps[d] || ''
      }),
      b || (a.oldCssProps = {
      })
    }
  }
  function ka(a, c) {
    var d = b.createEvent('Event');
    d.initEvent(a, !0, !0),
    d.gesture = c,
    c.target.dispatchEvent(d)
  }
  var la,
  ma = [
    '',
    'webkit',
    'Moz',
    'MS',
    'ms',
    'o'
  ],
  na = b.createElement('div'),
  oa = 'function',
  pa = Math.round,
  qa = Math.abs,
  ra = Date.now;
  la = 'function' != typeof Object.assign ? function (a) {
    if (a === d || null === a) throw new TypeError('Cannot convert undefined or null to object');
    for (var b = Object(a), c = 1; c < arguments.length; c++) {
      var e = arguments[c];
      if (e !== d && null !== e) for (var f in e) e.hasOwnProperty(f) && (b[f] = e[f])
    }
    return b
  }
   : Object.assign;
  var sa = h(function (a, b, c) {
    for (var e = Object.keys(b), f = 0; f < e.length; ) (!c || c && a[e[f]] === d) && (a[e[f]] = b[e[f]]),
    f++;
    return a
  }, 'extend', 'Use `assign`.'),
  ta = h(function (a, b) {
    return sa(a, b, !0)
  }, 'merge', 'Use `assign`.'),
  ua = 1,
  va = /mobile|tablet|ip(ad|hone|od)|android/i,
  wa = 'ontouchstart' in a,
  xa = u(a, 'PointerEvent') !== d,
  ya = wa && va.test(navigator.userAgent),
  za = 'touch',
  Aa = 'pen',
  Ba = 'mouse',
  Ca = 'kinect',
  Da = 25,
  Ea = 1,
  Fa = 2,
  Ga = 4,
  Ha = 8,
  Ia = 1,
  Ja = 2,
  Ka = 4,
  La = 8,
  Ma = 16,
  Na = Ja | Ka,
  Oa = La | Ma,
  Pa = Na | Oa,
  Qa = [
    'x',
    'y'
  ],
  Ra = [
    'clientX',
    'clientY'
  ];
  x.prototype = {
    handler: function () {
    },
    init: function () {
      this.evEl && m(this.element, this.evEl, this.domHandler),
      this.evTarget && m(this.target, this.evTarget, this.domHandler),
      this.evWin && m(w(this.element), this.evWin, this.domHandler)
    },
    destroy: function () {
      this.evEl && n(this.element, this.evEl, this.domHandler),
      this.evTarget && n(this.target, this.evTarget, this.domHandler),
      this.evWin && n(w(this.element), this.evWin, this.domHandler)
    }
  };
  var Sa = {
    mousedown: Ea,
    mousemove: Fa,
    mouseup: Ga
  },
  Ta = 'mousedown',
  Ua = 'mousemove mouseup';
  i(L, x, {
    handler: function (a) {
      var b = Sa[a.type];
      b & Ea && 0 === a.button && (this.pressed = !0),
      b & Fa && 1 !== a.which && (b = Ga),
      this.pressed && (b & Ga && (this.pressed = !1), this.callback(this.manager, b, {
        pointers: [
          a
        ],
        changedPointers: [
          a
        ],
        pointerType: Ba,
        srcEvent: a
      }))
    }
  });
  var Va = {
    pointerdown: Ea,
    pointermove: Fa,
    pointerup: Ga,
    pointercancel: Ha,
    pointerout: Ha
  },
  Wa = {
    2: za,
    3: Aa,
    4: Ba,
    5: Ca
  },
  Xa = 'pointerdown',
  Ya = 'pointermove pointerup pointercancel';
  a.MSPointerEvent && !a.PointerEvent && (Xa = 'MSPointerDown', Ya = 'MSPointerMove MSPointerUp MSPointerCancel'),
  i(M, x, {
    handler: function (a) {
      var b = this.store,
      c = !1,
      d = a.type.toLowerCase().replace('ms', ''),
      e = Va[d],
      f = Wa[a.pointerType] || a.pointerType,
      g = f == za,
      h = r(b, a.pointerId, 'pointerId');
      e & Ea && (0 === a.button || g) ? 0 > h && (b.push(a), h = b.length - 1)  : e & (Ga | Ha) && (c = !0),
      0 > h || (b[h] = a, this.callback(this.manager, e, {
        pointers: b,
        changedPointers: [
          a
        ],
        pointerType: f,
        srcEvent: a
      }), c && b.splice(h, 1))
    }
  });
  var Za = {
    touchstart: Ea,
    touchmove: Fa,
    touchend: Ga,
    touchcancel: Ha
  },
  $a = 'touchstart',
  _a = 'touchstart touchmove touchend touchcancel';
  i(N, x, {
    handler: function (a) {
      var b = Za[a.type];
      if (b === Ea && (this.started = !0), this.started) {
        var c = O.call(this, a, b);
        b & (Ga | Ha) && c[0].length - c[1].length === 0 && (this.started = !1),
        this.callback(this.manager, b, {
          pointers: c[0],
          changedPointers: c[1],
          pointerType: za,
          srcEvent: a
        })
      }
    }
  });
  var ab = {
    touchstart: Ea,
    touchmove: Fa,
    touchend: Ga,
    touchcancel: Ha
  },
  bb = 'touchstart touchmove touchend touchcancel';
  i(P, x, {
    handler: function (a) {
      var b = ab[a.type],
      c = Q.call(this, a, b);
      c && this.callback(this.manager, b, {
        pointers: c[0],
        changedPointers: c[1],
        pointerType: za,
        srcEvent: a
      })
    }
  });
  var cb = 2500,
  db = 25;
  i(R, x, {
    handler: function (a, b, c) {
      var d = c.pointerType == za,
      e = c.pointerType == Ba;
      if (!(e && c.sourceCapabilities && c.sourceCapabilities.firesTouchEvents)) {
        if (d) S.call(this, b, c);
         else if (e && U.call(this, c)) return;
        this.callback(a, b, c)
      }
    },
    destroy: function () {
      this.touch.destroy(),
      this.mouse.destroy()
    }
  });
  var eb = u(na.style, 'touchAction'),
  fb = eb !== d,
  gb = 'compute',
  hb = 'auto',
  ib = 'manipulation',
  jb = 'none',
  kb = 'pan-x',
  lb = 'pan-y',
  mb = X();
  V.prototype = {
    set: function (a) {
      a == gb && (a = this.compute()),
      fb && this.manager.element.style && mb[a] && (this.manager.element.style[eb] = a),
      this.actions = a.toLowerCase().trim()
    },
    update: function () {
      this.set(this.manager.options.touchAction)
    },
    compute: function () {
      var a = [
      ];
      return g(this.manager.recognizers, function (b) {
        k(b.options.enable, [
          b
        ]) && (a = a.concat(b.getTouchAction()))
      }),
      W(a.join(' '))
    },
    preventDefaults: function (a) {
      var b = a.srcEvent,
      c = a.offsetDirection;
      if (this.manager.session.prevented) return void b.preventDefault();
      var d = this.actions,
      e = p(d, jb) && !mb[jb],
      f = p(d, lb) && !mb[lb],
      g = p(d, kb) && !mb[kb];
      if (e) {
        var h = 1 === a.pointers.length,
        i = a.distance < 2,
        j = a.deltaTime < 250;
        if (h && i && j) return
      }
      return g && f ? void 0 : e || f && c & Na || g && c & Oa ? this.preventSrc(b)  : void 0
    },
    preventSrc: function (a) {
      this.manager.session.prevented = !0,
      a.preventDefault()
    }
  };
  var nb = 1,
  ob = 2,
  pb = 4,
  qb = 8,
  rb = qb,
  sb = 16,
  tb = 32;
  Y.prototype = {
    defaults: {
    },
    set: function (a) {
      return la(this.options, a),
      this.manager && this.manager.touchAction.update(),
      this
    },
    recognizeWith: function (a) {
      if (f(a, 'recognizeWith', this)) return this;
      var b = this.simultaneous;
      return a = _(a, this),
      b[a.id] || (b[a.id] = a, a.recognizeWith(this)),
      this
    },
    dropRecognizeWith: function (a) {
      return f(a, 'dropRecognizeWith', this) ? this : (a = _(a, this), delete this.simultaneous[a.id], this)
    },
    requireFailure: function (a) {
      if (f(a, 'requireFailure', this)) return this;
      var b = this.requireFail;
      return a = _(a, this),
      - 1 === r(b, a) && (b.push(a), a.requireFailure(this)),
      this
    },
    dropRequireFailure: function (a) {
      if (f(a, 'dropRequireFailure', this)) return this;
      a = _(a, this);
      var b = r(this.requireFail, a);
      return b > - 1 && this.requireFail.splice(b, 1),
      this
    },
    hasRequireFailures: function () {
      return this.requireFail.length > 0
    },
    canRecognizeWith: function (a) {
      return !!this.simultaneous[a.id]
    },
    emit: function (a) {
      function b(b) {
        c.manager.emit(b, a)
      }
      var c = this,
      d = this.state;
      qb > d && b(c.options.event + Z(d)),
      b(c.options.event),
      a.additionalEvent && b(a.additionalEvent),
      d >= qb && b(c.options.event + Z(d))
    },
    tryEmit: function (a) {
      return this.canEmit() ? this.emit(a)  : void (this.state = tb)
    },
    canEmit: function () {
      for (var a = 0; a < this.requireFail.length; ) {
        if (!(this.requireFail[a].state & (tb | nb))) return !1;
        a++
      }
      return !0
    },
    recognize: function (a) {
      var b = la({
      }, a);
      return k(this.options.enable, [
        this,
        b
      ]) ? (this.state & (rb | sb | tb) && (this.state = nb), this.state = this.process(b), void (this.state & (ob | pb | qb | sb) && this.tryEmit(b)))  : (this.reset(), void (this.state = tb))
    },
    process: function (a) {
    },
    getTouchAction: function () {
    },
    reset: function () {
    }
  },
  i(aa, Y, {
    defaults: {
      pointers: 1
    },
    attrTest: function (a) {
      var b = this.options.pointers;
      return 0 === b || a.pointers.length === b
    },
    process: function (a) {
      var b = this.state,
      c = a.eventType,
      d = b & (ob | pb),
      e = this.attrTest(a);
      return d && (c & Ha || !e) ? b | sb : d || e ? c & Ga ? b | qb : b & ob ? b | pb : ob : tb
    }
  }),
  i(ba, aa, {
    defaults: {
      event: 'pan',
      threshold: 10,
      pointers: 1,
      direction: Pa
    },
    getTouchAction: function () {
      var a = this.options.direction,
      b = [
      ];
      return a & Na && b.push(lb),
      a & Oa && b.push(kb),
      b
    },
    directionTest: function (a) {
      var b = this.options,
      c = !0,
      d = a.distance,
      e = a.direction,
      f = a.deltaX,
      g = a.deltaY;
      return e & b.direction || (b.direction & Na ? (e = 0 === f ? Ia : 0 > f ? Ja : Ka, c = f != this.pX, d = Math.abs(a.deltaX))  : (e = 0 === g ? Ia : 0 > g ? La : Ma, c = g != this.pY, d = Math.abs(a.deltaY))),
      a.direction = e,
      c && d > b.threshold && e & b.direction
    },
    attrTest: function (a) {
      return aa.prototype.attrTest.call(this, a) && (this.state & ob || !(this.state & ob) && this.directionTest(a))
    },
    emit: function (a) {
      this.pX = a.deltaX,
      this.pY = a.deltaY;
      var b = $(a.direction);
      b && (a.additionalEvent = this.options.event + b),
      this._super.emit.call(this, a)
    }
  }),
  i(ca, aa, {
    defaults: {
      event: 'pinch',
      threshold: 0,
      pointers: 2
    },
    getTouchAction: function () {
      return [jb]
    },
    attrTest: function (a) {
      return this._super.attrTest.call(this, a) && (Math.abs(a.scale - 1) > this.options.threshold || this.state & ob)
    },
    emit: function (a) {
      if (1 !== a.scale) {
        var b = a.scale < 1 ? 'in' : 'out';
        a.additionalEvent = this.options.event + b
      }
      this._super.emit.call(this, a)
    }
  }),
  i(da, Y, {
    defaults: {
      event: 'press',
      pointers: 1,
      time: 251,
      threshold: 9
    },
    getTouchAction: function () {
      return [hb]
    },
    process: function (a) {
      var b = this.options,
      c = a.pointers.length === b.pointers,
      d = a.distance < b.threshold,
      f = a.deltaTime > b.time;
      if (this._input = a, !d || !c || a.eventType & (Ga | Ha) && !f) this.reset();
       else if (a.eventType & Ea) this.reset(),
      this._timer = e(function () {
        this.state = rb,
        this.tryEmit()
      }, b.time, this);
       else if (a.eventType & Ga) return rb;
      return tb
    },
    reset: function () {
      clearTimeout(this._timer)
    },
    emit: function (a) {
      this.state === rb && (a && a.eventType & Ga ? this.manager.emit(this.options.event + 'up', a)  : (this._input.timeStamp = ra(), this.manager.emit(this.options.event, this._input)))
    }
  }),
  i(ea, aa, {
    defaults: {
      event: 'rotate',
      threshold: 0,
      pointers: 2
    },
    getTouchAction: function () {
      return [jb]
    },
    attrTest: function (a) {
      return this._super.attrTest.call(this, a) && (Math.abs(a.rotation) > this.options.threshold || this.state & ob)
    }
  }),
  i(fa, aa, {
    defaults: {
      event: 'swipe',
      threshold: 10,
      velocity: 0.3,
      direction: Na | Oa,
      pointers: 1
    },
    getTouchAction: function () {
      return ba.prototype.getTouchAction.call(this)
    },
    attrTest: function (a) {
      var b,
      c = this.options.direction;
      return c & (Na | Oa) ? b = a.overallVelocity : c & Na ? b = a.overallVelocityX : c & Oa && (b = a.overallVelocityY),
      this._super.attrTest.call(this, a) && c & a.offsetDirection && a.distance > this.options.threshold && a.maxPointers == this.options.pointers && qa(b) > this.options.velocity && a.eventType & Ga
    },
    emit: function (a) {
      var b = $(a.offsetDirection);
      b && this.manager.emit(this.options.event + b, a),
      this.manager.emit(this.options.event, a)
    }
  }),
  i(ga, Y, {
    defaults: {
      event: 'tap',
      pointers: 1,
      taps: 1,
      interval: 300,
      time: 250,
      threshold: 9,
      posThreshold: 10
    },
    getTouchAction: function () {
      return [ib]
    },
    process: function (a) {
      var b = this.options,
      c = a.pointers.length === b.pointers,
      d = a.distance < b.threshold,
      f = a.deltaTime < b.time;
      if (this.reset(), a.eventType & Ea && 0 === this.count) return this.failTimeout();
      if (d && f && c) {
        if (a.eventType != Ga) return this.failTimeout();
        var g = !this.pTime || a.timeStamp - this.pTime < b.interval,
        h = !this.pCenter || H(this.pCenter, a.center) < b.posThreshold;
        this.pTime = a.timeStamp,
        this.pCenter = a.center,
        h && g ? this.count += 1 : this.count = 1,
        this._input = a;
        var i = this.count % b.taps;
        if (0 === i) return this.hasRequireFailures() ? (this._timer = e(function () {
          this.state = rb,
          this.tryEmit()
        }, b.interval, this), ob)  : rb
      }
      return tb
    },
    failTimeout: function () {
      return this._timer = e(function () {
        this.state = tb
      }, this.options.interval, this),
      tb
    },
    reset: function () {
      clearTimeout(this._timer)
    },
    emit: function () {
      this.state == rb && (this._input.tapCount = this.count, this.manager.emit(this.options.event, this._input))
    }
  }),
  ha.VERSION = '2.0.8',
  ha.defaults = {
    domEvents: !1,
    touchAction: gb,
    enable: !0,
    inputTarget: null,
    inputClass: null,
    preset: [
      [ea,
      {
        enable: !1
      }
      ],
      [
        ca,
        {
          enable: !1
        },
        [
          'rotate'
        ]
      ],
      [
        fa,
        {
          direction: Na
        }
      ],
      [
        ba,
        {
          direction: Na
        },
        [
          'swipe'
        ]
      ],
      [
        ga
      ],
      [
        ga,
        {
          event: 'doubletap',
          taps: 2
        },
        [
          'tap'
        ]
      ],
      [
        da
      ]
    ],
    cssProps: {
      userSelect: 'none',
      touchSelect: 'none',
      touchCallout: 'none',
      contentZooming: 'none',
      userDrag: 'none',
      tapHighlightColor: 'rgba(0,0,0,0)'
    }
  };
  var ub = 1,
  vb = 2;
  ia.prototype = {
    set: function (a) {
      return la(this.options, a),
      a.touchAction && this.touchAction.update(),
      a.inputTarget && (this.input.destroy(), this.input.target = a.inputTarget, this.input.init()),
      this
    },
    stop: function (a) {
      this.session.stopped = a ? vb : ub
    },
    recognize: function (a) {
      var b = this.session;
      if (!b.stopped) {
        this.touchAction.preventDefaults(a);
        var c,
        d = this.recognizers,
        e = b.curRecognizer;
        (!e || e && e.state & rb) && (e = b.curRecognizer = null);
        for (var f = 0; f < d.length; ) c = d[f],
        b.stopped === vb || e && c != e && !c.canRecognizeWith(e) ? c.reset()  : c.recognize(a),
        !e && c.state & (ob | pb | qb) && (e = b.curRecognizer = c),
        f++
      }
    },
    get: function (a) {
      if (a instanceof Y) return a;
      for (var b = this.recognizers, c = 0; c < b.length; c++) if (b[c].options.event == a) return b[c];
      return null
    },
    add: function (a) {
      if (f(a, 'add', this)) return this;
      var b = this.get(a.options.event);
      return b && this.remove(b),
      this.recognizers.push(a),
      a.manager = this,
      this.touchAction.update(),
      a
    },
    remove: function (a) {
      if (f(a, 'remove', this)) return this;
      if (a = this.get(a)) {
        var b = this.recognizers,
        c = r(b, a);
        - 1 !== c && (b.splice(c, 1), this.touchAction.update())
      }
      return this
    },
    on: function (a, b) {
      if (a !== d && b !== d) {
        var c = this.handlers;
        return g(q(a), function (a) {
          c[a] = c[a] || [
          ],
          c[a].push(b)
        }),
        this
      }
    },
    off: function (a, b) {
      if (a !== d) {
        var c = this.handlers;
        return g(q(a), function (a) {
          b ? c[a] && c[a].splice(r(c[a], b), 1)  : delete c[a]
        }),
        this
      }
    },
    emit: function (a, b) {
      this.options.domEvents && ka(a, b);
      var c = this.handlers[a] && this.handlers[a].slice();
      if (c && c.length) {
        b.type = a,
        b.preventDefault = function () {
          b.srcEvent.preventDefault()
        };
        for (var d = 0; d < c.length; ) c[d](b),
        d++
      }
    },
    destroy: function () {
      this.element && ja(this, !1),
      this.handlers = {
      },
      this.session = {
      },
      this.input.destroy(),
      this.element = null
    }
  },
  la(ha, {
    INPUT_START: Ea,
    INPUT_MOVE: Fa,
    INPUT_END: Ga,
    INPUT_CANCEL: Ha,
    STATE_POSSIBLE: nb,
    STATE_BEGAN: ob,
    STATE_CHANGED: pb,
    STATE_ENDED: qb,
    STATE_RECOGNIZED: rb,
    STATE_CANCELLED: sb,
    STATE_FAILED: tb,
    DIRECTION_NONE: Ia,
    DIRECTION_LEFT: Ja,
    DIRECTION_RIGHT: Ka,
    DIRECTION_UP: La,
    DIRECTION_DOWN: Ma,
    DIRECTION_HORIZONTAL: Na,
    DIRECTION_VERTICAL: Oa,
    DIRECTION_ALL: Pa,
    Manager: ia,
    Input: x,
    TouchAction: V,
    TouchInput: P,
    MouseInput: L,
    PointerEventInput: M,
    TouchMouseInput: R,
    SingleTouchInput: N,
    Recognizer: Y,
    AttrRecognizer: aa,
    Tap: ga,
    Pan: ba,
    Swipe: fa,
    Pinch: ca,
    Rotate: ea,
    Press: da,
    on: m,
    off: n,
    each: g,
    merge: ta,
    extend: sa,
    assign: la,
    inherit: i,
    bindFn: j,
    prefixed: u
  });
  var wb = 'undefined' != typeof a ? a : 'undefined' != typeof self ? self : {
  };
  wb.Hammer = ha,
  'function' == typeof define && define.amd ? define(function () {
    return ha
  })  : 'undefined' != typeof module && module.exports ? module.exports = ha : a[c] = ha
}(window, document, 'Hammer'),
$(document).ready(function () {
  var a = $(window);
  $('.block-scroller').each(function () {
    function b() {
      e.height(h.height()),
      l = e.width() - f.outerWidth() - 20
    }
    function c() {
      k < l && (j = 0, d(j))
    }
    function d(a) {
      h.css({
        transform: 'translateX( ' + a + 'px)'
      })
    }
    var e = $(this),
    f = e.children('.heading'),
    g = e.children('.blocks'),
    h = g.children('.inner-blocks'),
    i = new Hammer.Manager(g[0]),
    j = 0,
    k = (h.children().length, h.width()),
    l = e.width() - f.outerWidth() - 20;
    e.find('img').attr('draggable', !1),
    e.find('a').attr('draggable', !1),
    i.add(new Hammer.Pan({
      threshold: 0,
      pointers: 0
    })),
    setTimeout(function () {
      b(),
      i.on('panstart', function (a) {
      }),
      i.on('panend', function (a) {
        j += a.deltaX,
        j > 0 ? j = 0 : j < - (k - l) && (j = - (k - l))
      }),
      i.on('pan', function (a) {
        var b = j + a.deltaX;
        return !(k < l) && (console.log(b), b > 0 ? b = 0 : b < - (k - l) && (b = - (k - l)), console.log(b, - (k - l)), void d(b))
      })
    }, 500),
    a.on('resize', function () {
      b(),
      c()
    })
  })
}),
document.getElementById('business-plan-data-table') && !function (a) {
  'use strict';
  function b(a) {
    console.log('toggle', a),
    e.removeClass(),
    e.addClass(a)
  }
  var c = a('#business-plan-data-table'),
  d = c.find('.business-plan-data-table-controls'),
  e = c.find('table');
  d.on('change', 'input', function () {
    var c = a(this),
    d = c.val();
    b(d)
  })
}(jQuery),
$(document).ready(function () {
  function a(a) {
    $(a).show().siblings().hide()
  }
  $('.content-toggler').each(function () {
    var b = $(this),
    c = b.val();
    a(c),
    b.on('change', function () {
      c = b.val(),
      a(c)
    })
  })
}),
// $(document).ready(function () {
//   $('.date-picker').datepicker({
//     dateFormat: 'dd-mm-yy'
//   })
// }),
function (a, b) {
  a.fn.drawDoughnutChart = function () {
    function b(b) {
      var c = a(this).data().order;
      G.text(l[c].title + ': ' + l[c].value).fadeIn(200),
      t.onPathEnter.apply(a(this), [
        b,
        l
      ])
    }
    function c(b) {
      G.hide(),
      t.onPathLeave.apply(a(this), [
        b,
        l
      ])
    }
    function d(a) {
      G.css({
        top: a.pageY + t.tipOffsetY,
        left: a.pageX - G.width() / 2 + t.tipOffsetX
      })
    }
    function e(a) {
      var b = - Math.PI / 2,
      c = 1;
      t.animation && t.animateRotate && (c = a),
      F[0].setAttribute('opacity', a);
      for (var d = 0, e = l.length; d < e; d++) {
        var f = c * (l[d].value / B * (2 * s)),
        g = b + f,
        h = (g - b) % (2 * s) > s ? 1 : 0,
        i = o + q(b) * z,
        j = p + r(b) * z,
        k = o + q(b) * A,
        m = p + r(b) * A,
        n = o + q(g) * z,
        u = p + r(g) * z,
        v = o + q(g) * A,
        w = p + r(g) * A,
        y = [
          'M',
          i,
          j,
          'A',
          z,
          z,
          0,
          h,
          1,
          n,
          u,
          'L',
          v,
          w,
          'A',
          A,
          A,
          0,
          h,
          0,
          k,
          m,
          'Z'
        ];
        x[d][0].setAttribute('d', y.join(' ')),
        b += f
      }
    }
    function f(a, b) {
      var c = t.animation ? j(y(a), null, 0)  : 1;
      b(c)
    }
    function g(a) {
      var b = t.animation ? 1 / j(t.animationSteps, Number.MAX_VALUE, 1)  : 1,
      c = t.animation ? 0 : 1;
      v(function () {
        c += b,
        f(c, a),
        c <= 1 ? v(arguments.callee)  : t.afterDrawed.call(k)
      })
    }
    function h(a) {
      return Math.min.apply(null, a)
    }
    function i(a) {
      return !isNaN(parseFloat(a)) && isFinite(a)
    }
    function j(a, b, c) {
      return i(b) && a > b ? b : i(c) && a < c ? c : a
    }
    var k = this,
    l = k.data('values'),
    m = k.width(),
    n = m,
    o = m / 2,
    p = n / 2,
    q = Math.cos,
    r = Math.sin,
    s = Math.PI,
    t = a.extend({
      segmentShowStroke: !1,
      segmentStrokeColor: '#0C1013',
      segmentStrokeWidth: 0,
      baseColor: 'rgba(0,0,0,0)',
      baseOffset: 4,
      edgeOffset: 10,
      percentageInnerCutout: 89,
      animation: !0,
      animationSteps: 90,
      animationEasing: 'easeInOutExpo',
      animateRotate: !0,
      tipOffsetX: - 8,
      tipOffsetY: - 45,
      tipClass: 'doughnutTip',
      summaryClass: 'doughnutSummary',
      summaryTitle: '',
      summaryTitleClass: 'doughnutSummaryTitle',
      summaryNumberClass: 'doughnutSummaryNumber',
      beforeDraw: function () {
      },
      afterDrawed: function () {
      },
      onPathEnter: function (a, b) {
      },
      onPathLeave: function (a, b) {
      }
    }),
    u = {
      linear: function (a) {
        return a
      },
      easeInOutExpo: function (a) {
        var b = a < 0.5 ? 8 * a * a * a * a : 1 - 8 * --a * a * a * a;
        return b > 1 ? 1 : b
      }
    },
    v = function () {
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (a) {
        window.setTimeout(a, 1000 / 60)
      }
    }();
    t.beforeDraw.call(k);
    var w = a('<svg width="' + m + '" height="' + n + '" viewBox="0 0 ' + m + ' ' + n + '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"></svg>').appendTo(k),
    x = [
    ],
    y = u[t.animationEasing],
    z = h([n / 2,
    m / 2]) - t.edgeOffset,
    A = z * (t.percentageInnerCutout / 100),
    B = 0,
    C = z + t.baseOffset,
    D = A - t.baseOffset,
    E = (function () {
      var b = document.createElementNS('http://www.w3.org/2000/svg', 'path'),
      c = a(b).appendTo(w),
      d = - 1.57,
      e = 4.7131,
      f = o + q(d) * C,
      g = p + r(d) * C,
      h = o + q(d) * D,
      i = p + r(d) * D,
      j = o + q(e) * C,
      k = p + r(e) * C,
      l = o + q(e) * D,
      m = p + r(e) * D,
      n = [
        'M',
        f,
        g,
        'A',
        C,
        C,
        0,
        1,
        1,
        j,
        k,
        'L',
        l,
        m,
        'A',
        D,
        D,
        0,
        1,
        0,
        h,
        i,
        'Z'
      ];
      c[0].setAttribute('d', n.join(' ')),
      c[0].setAttribute('fill', t.baseColor)
    }(), document.createElementNS('http://www.w3.org/2000/svg', 'g')),
    F = a(E).appendTo(w);
    F[0].setAttribute('opacity', 0);
    for (var G = a('<div class="' + t.tipClass + '" />').appendTo('body').hide(), H = (G.width(), G.height(), 0), I = l.length; H < I; H++) {
      B += l[H].value;
      var J = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      J.setAttribute('stroke-width', t.segmentStrokeWidth),
      J.setAttribute('stroke', t.segmentStrokeColor),
      J.setAttribute('fill', l[H].color),
      J.setAttribute('data-order', H),
      x[H] = a(J).appendTo(F),
      x[H].on('mouseenter', b).on('mouseleave', c).on('mousemove', d)
    }
    if (0 === B) {
      for (var H = 0, I = l.length; H < I; H++) l[H].value = 1;
      B = l.length
    }
    return g(e),
    k
  }
}(jQuery),
$('.doughnut-chart').each(function () {
  $(this).drawDoughnutChart()
}),
function (a) {
  'use strict';
  var b,
  c = a(window),
  d = a('.expanding-panel');
  d.each(function () {
    var b = a(this),
    d = b.children('.panel-heading'),
    e = b.children('.panel-body'),
    f = b.find('.panel-summary'),
    g = b.attr('id'),
    h = b.hasClass('expand-mobile-only');
    b.hasClass('expanded') ? f.hide()  : e.hide(),
    d.on('click', function () {
      return !(h && c.width() > 600) && (b.toggleClass('expanded'), void (b.hasClass('expanded') ? (f.slideUp(), e.slideDown())  : (f.slideDown(), e.slideUp())))
    }),
    g && window.location.hash && g === window.location.hash.substring(1) && (b.addClass('expanded'), f.hide(), e.show())
  }),
  c.on('resize', function (c) {
    clearTimeout(b),
    b = setTimeout(function () {
      d.each(function () {
        var b = a(this);
        b.hasClass('expand-mobile-only')
      })
    }, 250)
  })
}(jQuery),
!function (a, b) {
  'function' == typeof define && define.amd ? define('ev-emitter/ev-emitter', b)  : 'object' == typeof module && module.exports ? module.exports = b()  : a.EvEmitter = b()
}('undefined' != typeof window ? window : this, function () {
  function a() {
  }
  var b = a.prototype;
  return b.on = function (a, b) {
    if (a && b) {
      var c = this._events = this._events || {
      },
      d = c[a] = c[a] || [
      ];
      return - 1 == d.indexOf(b) && d.push(b),
      this
    }
  },
  b.once = function (a, b) {
    if (a && b) {
      this.on(a, b);
      var c = this._onceEvents = this._onceEvents || {
      },
      d = c[a] = c[a] || {
      };
      return d[b] = !0,
      this
    }
  },
  b.off = function (a, b) {
    var c = this._events && this._events[a];
    if (c && c.length) {
      var d = c.indexOf(b);
      return - 1 != d && c.splice(d, 1),
      this
    }
  },
  b.emitEvent = function (a, b) {
    var c = this._events && this._events[a];
    if (c && c.length) {
      var d = 0,
      e = c[d];
      b = b || [
      ];
      for (var f = this._onceEvents && this._onceEvents[a]; e; ) {
        var g = f && f[e];
        g && (this.off(a, e), delete f[e]),
        e.apply(this, b),
        d += g ? 0 : 1,
        e = c[d]
      }
      return this
    }
  },
  a
}),
function (a, b) {
  'use strict';
  'function' == typeof define && define.amd ? define(['ev-emitter/ev-emitter'], function (c) {
    return b(a, c)
  })  : 'object' == typeof module && module.exports ? module.exports = b(a, require('ev-emitter'))  : a.imagesLoaded = b(a, a.EvEmitter)
}(window, function (a, b) {
  function c(a, b) {
    for (var c in b) a[c] = b[c];
    return a
  }
  function d(a) {
    var b = [
    ];
    if (Array.isArray(a)) b = a;
     else if ('number' == typeof a.length) for (var c = 0; c < a.length; c++) b.push(a[c]);
     else b.push(a);
    return b
  }
  function e(a, b, f) {
    return this instanceof e ? ('string' == typeof a && (a = document.querySelectorAll(a)), this.elements = d(a), this.options = c({
    }, this.options), 'function' == typeof b ? f = b : c(this.options, b), f && this.on('always', f), this.getImages(), h && (this.jqDeferred = new h.Deferred), void setTimeout(function () {
      this.check()
    }.bind(this)))  : new e(a, b, f)
  }
  function f(a) {
    this.img = a
  }
  function g(a, b) {
    this.url = a,
    this.element = b,
    this.img = new Image
  }
  var h = a.jQuery,
  i = a.console;
  e.prototype = Object.create(b.prototype),
  e.prototype.options = {
  },
  e.prototype.getImages = function () {
    this.images = [
    ],
    this.elements.forEach(this.addElementImages, this)
  },
  e.prototype.addElementImages = function (a) {
    'IMG' == a.nodeName && this.addImage(a),
    this.options.background === !0 && this.addElementBackgroundImages(a);
    var b = a.nodeType;
    if (b && j[b]) {
      for (var c = a.querySelectorAll('img'), d = 0; d < c.length; d++) {
        var e = c[d];
        this.addImage(e)
      }
      if ('string' == typeof this.options.background) {
        var f = a.querySelectorAll(this.options.background);
        for (d = 0; d < f.length; d++) {
          var g = f[d];
          this.addElementBackgroundImages(g)
        }
      }
    }
  };
  var j = {
    1: !0,
    9: !0,
    11: !0
  };
  return e.prototype.addElementBackgroundImages = function (a) {
    var b = getComputedStyle(a);
    if (b) for (var c = /url\((['"])?(.*?)\1\)/gi, d = c.exec(b.backgroundImage); null !== d; ) {
      var e = d && d[2];
      e && this.addBackground(e, a),
      d = c.exec(b.backgroundImage)
    }
  },
  e.prototype.addImage = function (a) {
    var b = new f(a);
    this.images.push(b)
  },
  e.prototype.addBackground = function (a, b) {
    var c = new g(a, b);
    this.images.push(c)
  },
  e.prototype.check = function () {
    function a(a, c, d) {
      setTimeout(function () {
        b.progress(a, c, d)
      })
    }
    var b = this;
    return this.progressedCount = 0,
    this.hasAnyBroken = !1,
    this.images.length ? void this.images.forEach(function (b) {
      b.once('progress', a),
      b.check()
    })  : void this.complete()
  },
  e.prototype.progress = function (a, b, c) {
    this.progressedCount++,
    this.hasAnyBroken = this.hasAnyBroken || !a.isLoaded,
    this.emitEvent('progress', [
      this,
      a,
      b
    ]),
    this.jqDeferred && this.jqDeferred.notify && this.jqDeferred.notify(this, a),
    this.progressedCount == this.images.length && this.complete(),
    this.options.debug && i && i.log('progress: ' + c, a, b)
  },
  e.prototype.complete = function () {
    var a = this.hasAnyBroken ? 'fail' : 'done';
    if (this.isComplete = !0, this.emitEvent(a, [
      this
    ]), this.emitEvent('always', [
      this
    ]), this.jqDeferred) {
      var b = this.hasAnyBroken ? 'reject' : 'resolve';
      this.jqDeferred[b](this)
    }
  },
  f.prototype = Object.create(b.prototype),
  f.prototype.check = function () {
    var a = this.getIsImageComplete();
    return a ? void this.confirm(0 !== this.img.naturalWidth, 'naturalWidth')  : (this.proxyImage = new Image, this.proxyImage.addEventListener('load', this), this.proxyImage.addEventListener('error', this), this.img.addEventListener('load', this), this.img.addEventListener('error', this), void (this.proxyImage.src = this.img.src))
  },
  f.prototype.getIsImageComplete = function () {
    return this.img.complete && void 0 !== this.img.naturalWidth
  },
  f.prototype.confirm = function (a, b) {
    this.isLoaded = a,
    this.emitEvent('progress', [
      this,
      this.img,
      b
    ])
  },
  f.prototype.handleEvent = function (a) {
    var b = 'on' + a.type;
    this[b] && this[b](a)
  },
  f.prototype.onload = function () {
    this.confirm(!0, 'onload'),
    this.unbindEvents()
  },
  f.prototype.onerror = function () {
    this.confirm(!1, 'onerror'),
    this.unbindEvents()
  },
  f.prototype.unbindEvents = function () {
    this.proxyImage.removeEventListener('load', this),
    this.proxyImage.removeEventListener('error', this),
    this.img.removeEventListener('load', this),
    this.img.removeEventListener('error', this)
  },
  g.prototype = Object.create(f.prototype),
  g.prototype.check = function () {
    this.img.addEventListener('load', this),
    this.img.addEventListener('error', this),
    this.img.src = this.url;
    var a = this.getIsImageComplete();
    a && (this.confirm(0 !== this.img.naturalWidth, 'naturalWidth'), this.unbindEvents())
  },
  g.prototype.unbindEvents = function () {
    this.img.removeEventListener('load', this),
    this.img.removeEventListener('error', this)
  },
  g.prototype.confirm = function (a, b) {
    this.isLoaded = a,
    this.emitEvent('progress', [
      this,
      this.element,
      b
    ])
  },
  e.makeJQueryPlugin = function (b) {
    b = b || a.jQuery,
    b && (h = b, h.fn.imagesLoaded = function (a, b) {
      var c = new e(this, a, b);
      return c.jqDeferred.promise(h(this))
    })
  },
  e.makeJQueryPlugin(),
  e
}),
!function (a, b) {
  'function' == typeof define && define.amd ? define('jquery-bridget/jquery-bridget', [
    'jquery'
  ], function (c) {
    return b(a, c)
  })  : 'object' == typeof module && module.exports ? module.exports = b(a, require('jquery'))  : a.jQueryBridget = b(a, a.jQuery);
}(window, function (a, b) {
  'use strict';
  function c(c, f, h) {
    function i(a, b, d) {
      var e,
      f = '$().' + c + '("' + b + '")';
      return a.each(function (a, i) {
        var j = h.data(i, c);
        if (!j) return void g(c + ' not initialized. Cannot call methods, i.e. ' + f);
        var k = j[b];
        if (!k || '_' == b.charAt(0)) return void g(f + ' is not a valid method');
        var l = k.apply(j, d);
        e = void 0 === e ? l : e
      }),
      void 0 !== e ? e : a
    }
    function j(a, b) {
      a.each(function (a, d) {
        var e = h.data(d, c);
        e ? (e.option(b), e._init())  : (e = new f(d, b), h.data(d, c, e))
      })
    }
    h = h || b || a.jQuery,
    h && (f.prototype.option || (f.prototype.option = function (a) {
      h.isPlainObject(a) && (this.options = h.extend(!0, this.options, a))
    }), h.fn[c] = function (a) {
      if ('string' == typeof a) {
        var b = e.call(arguments, 1);
        return i(this, a, b)
      }
      return j(this, a),
      this
    }, d(h))
  }
  function d(a) {
    !a || a && a.bridget || (a.bridget = c)
  }
  var e = Array.prototype.slice,
  f = a.console,
  g = 'undefined' == typeof f ? function () {
  }
   : function (a) {
    f.error(a)
  };
  return d(b || a.jQuery),
  c
}),
function (a, b) {
  'function' == typeof define && define.amd ? define('ev-emitter/ev-emitter', b)  : 'object' == typeof module && module.exports ? module.exports = b()  : a.EvEmitter = b()
}('undefined' != typeof window ? window : this, function () {
  function a() {
  }
  var b = a.prototype;
  return b.on = function (a, b) {
    if (a && b) {
      var c = this._events = this._events || {
      },
      d = c[a] = c[a] || [
      ];
      return - 1 == d.indexOf(b) && d.push(b),
      this
    }
  },
  b.once = function (a, b) {
    if (a && b) {
      this.on(a, b);
      var c = this._onceEvents = this._onceEvents || {
      },
      d = c[a] = c[a] || {
      };
      return d[b] = !0,
      this
    }
  },
  b.off = function (a, b) {
    var c = this._events && this._events[a];
    if (c && c.length) {
      var d = c.indexOf(b);
      return - 1 != d && c.splice(d, 1),
      this
    }
  },
  b.emitEvent = function (a, b) {
    var c = this._events && this._events[a];
    if (c && c.length) {
      var d = 0,
      e = c[d];
      b = b || [
      ];
      for (var f = this._onceEvents && this._onceEvents[a]; e; ) {
        var g = f && f[e];
        g && (this.off(a, e), delete f[e]),
        e.apply(this, b),
        d += g ? 0 : 1,
        e = c[d]
      }
      return this
    }
  },
  a
}),
function (a, b) {
  'use strict';
  'function' == typeof define && define.amd ? define('get-size/get-size', [
  ], function () {
    return b()
  })  : 'object' == typeof module && module.exports ? module.exports = b()  : a.getSize = b()
}(window, function () {
  'use strict';
  function a(a) {
    var b = parseFloat(a),
    c = - 1 == a.indexOf('%') && !isNaN(b);
    return c && b
  }
  function b() {
  }
  function c() {
    for (var a = {
      width: 0,
      height: 0,
      innerWidth: 0,
      innerHeight: 0,
      outerWidth: 0,
      outerHeight: 0
    }, b = 0; j > b; b++) {
      var c = i[b];
      a[c] = 0
    }
    return a
  }
  function d(a) {
    var b = getComputedStyle(a);
    return b || h('Style returned ' + b + '. Are you running this code in a hidden iframe on Firefox? See http://bit.ly/getsizebug1'),
    b
  }
  function e() {
    if (!k) {
      k = !0;
      var b = document.createElement('div');
      b.style.width = '200px',
      b.style.padding = '1px 2px 3px 4px',
      b.style.borderStyle = 'solid',
      b.style.borderWidth = '1px 2px 3px 4px',
      b.style.boxSizing = 'border-box';
      var c = document.body || document.documentElement;
      c.appendChild(b);
      var e = d(b);
      f.isBoxSizeOuter = g = 200 == a(e.width),
      c.removeChild(b)
    }
  }
  function f(b) {
    if (e(), 'string' == typeof b && (b = document.querySelector(b)), b && 'object' == typeof b && b.nodeType) {
      var f = d(b);
      if ('none' == f.display) return c();
      var h = {
      };
      h.width = b.offsetWidth,
      h.height = b.offsetHeight;
      for (var k = h.isBorderBox = 'border-box' == f.boxSizing, l = 0; j > l; l++) {
        var m = i[l],
        n = f[m],
        o = parseFloat(n);
        h[m] = isNaN(o) ? 0 : o
      }
      var p = h.paddingLeft + h.paddingRight,
      q = h.paddingTop + h.paddingBottom,
      r = h.marginLeft + h.marginRight,
      s = h.marginTop + h.marginBottom,
      t = h.borderLeftWidth + h.borderRightWidth,
      u = h.borderTopWidth + h.borderBottomWidth,
      v = k && g,
      w = a(f.width);
      w !== !1 && (h.width = w + (v ? 0 : p + t));
      var x = a(f.height);
      return x !== !1 && (h.height = x + (v ? 0 : q + u)),
      h.innerWidth = h.width - (p + t),
      h.innerHeight = h.height - (q + u),
      h.outerWidth = h.width + r,
      h.outerHeight = h.height + s,
      h
    }
  }
  var g,
  h = 'undefined' == typeof console ? b : function (a) {
    console.error(a)
  },
  i = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
    'marginLeft',
    'marginRight',
    'marginTop',
    'marginBottom',
    'borderLeftWidth',
    'borderRightWidth',
    'borderTopWidth',
    'borderBottomWidth'
  ],
  j = i.length,
  k = !1;
  return f
}),
function (a, b) {
  'use strict';
  'function' == typeof define && define.amd ? define('desandro-matches-selector/matches-selector', b)  : 'object' == typeof module && module.exports ? module.exports = b()  : a.matchesSelector = b()
}(window, function () {
  'use strict';
  var a = function () {
    var a = Element.prototype;
    if (a.matches) return 'matches';
    if (a.matchesSelector) return 'matchesSelector';
    for (var b = [
      'webkit',
      'moz',
      'ms',
      'o'
    ], c = 0; c < b.length; c++) {
      var d = b[c],
      e = d + 'MatchesSelector';
      if (a[e]) return e
    }
  }();
  return function (b, c) {
    return b[a](c)
  }
}),
function (a, b) {
  'function' == typeof define && define.amd ? define('fizzy-ui-utils/utils', [
    'desandro-matches-selector/matches-selector'
  ], function (c) {
    return b(a, c)
  })  : 'object' == typeof module && module.exports ? module.exports = b(a, require('desandro-matches-selector'))  : a.fizzyUIUtils = b(a, a.matchesSelector)
}(window, function (a, b) {
  var c = {
  };
  c.extend = function (a, b) {
    for (var c in b) a[c] = b[c];
    return a
  },
  c.modulo = function (a, b) {
    return (a % b + b) % b
  },
  c.makeArray = function (a) {
    var b = [
    ];
    if (Array.isArray(a)) b = a;
     else if (a && 'number' == typeof a.length) for (var c = 0; c < a.length; c++) b.push(a[c]);
     else b.push(a);
    return b
  },
  c.removeFrom = function (a, b) {
    var c = a.indexOf(b);
    - 1 != c && a.splice(c, 1)
  },
  c.getParent = function (a, c) {
    for (; a != document.body; ) if (a = a.parentNode, b(a, c)) return a
  },
  c.getQueryElement = function (a) {
    return 'string' == typeof a ? document.querySelector(a)  : a
  },
  c.handleEvent = function (a) {
    var b = 'on' + a.type;
    this[b] && this[b](a)
  },
  c.filterFindElements = function (a, d) {
    a = c.makeArray(a);
    var e = [
    ];
    return a.forEach(function (a) {
      if (a instanceof HTMLElement) {
        if (!d) return void e.push(a);
        b(a, d) && e.push(a);
        for (var c = a.querySelectorAll(d), f = 0; f < c.length; f++) e.push(c[f])
      }
    }),
    e
  },
  c.debounceMethod = function (a, b, c) {
    var d = a.prototype[b],
    e = b + 'Timeout';
    a.prototype[b] = function () {
      var a = this[e];
      a && clearTimeout(a);
      var b = arguments,
      f = this;
      this[e] = setTimeout(function () {
        d.apply(f, b),
        delete f[e]
      }, c || 100)
    }
  },
  c.docReady = function (a) {
    var b = document.readyState;
    'complete' == b || 'interactive' == b ? a()  : document.addEventListener('DOMContentLoaded', a)
  },
  c.toDashed = function (a) {
    return a.replace(/(.)([A-Z])/g, function (a, b, c) {
      return b + '-' + c
    }).toLowerCase()
  };
  var d = a.console;
  return c.htmlInit = function (b, e) {
    c.docReady(function () {
      var f = c.toDashed(e),
      g = 'data-' + f,
      h = document.querySelectorAll('[' + g + ']'),
      i = document.querySelectorAll('.js-' + f),
      j = c.makeArray(h).concat(c.makeArray(i)),
      k = g + '-options',
      l = a.jQuery;
      j.forEach(function (a) {
        var c,
        f = a.getAttribute(g) || a.getAttribute(k);
        try {
          c = f && JSON.parse(f)
        } catch (b) {
          return void (d && d.error('Error parsing ' + g + ' on ' + a.className + ': ' + b))
        }
        var h = new b(a, c);
        l && l.data(a, e, h)
      })
    })
  },
  c
}),
function (a, b) {
  'function' == typeof define && define.amd ? define('outlayer/item', [
    'ev-emitter/ev-emitter',
    'get-size/get-size'
  ], b)  : 'object' == typeof module && module.exports ? module.exports = b(require('ev-emitter'), require('get-size'))  : (a.Outlayer = {
  }, a.Outlayer.Item = b(a.EvEmitter, a.getSize))
}(window, function (a, b) {
  'use strict';
  function c(a) {
    for (var b in a) return !1;
    return b = null,
    !0
  }
  function d(a, b) {
    a && (this.element = a, this.layout = b, this.position = {
      x: 0,
      y: 0
    }, this._create())
  }
  function e(a) {
    return a.replace(/([A-Z])/g, function (a) {
      return '-' + a.toLowerCase()
    })
  }
  var f = document.documentElement.style,
  g = 'string' == typeof f.transition ? 'transition' : 'WebkitTransition',
  h = 'string' == typeof f.transform ? 'transform' : 'WebkitTransform',
  i = {
    WebkitTransition: 'webkitTransitionEnd',
    transition: 'transitionend'
  }
  [
    g
  ],
  j = {
    transform: h,
    transition: g,
    transitionDuration: g + 'Duration',
    transitionProperty: g + 'Property',
    transitionDelay: g + 'Delay'
  },
  k = d.prototype = Object.create(a.prototype);
  k.constructor = d,
  k._create = function () {
    this._transn = {
      ingProperties: {
      },
      clean: {
      },
      onEnd: {
      }
    },
    this.css({
      position: 'absolute'
    })
  },
  k.handleEvent = function (a) {
    var b = 'on' + a.type;
    this[b] && this[b](a)
  },
  k.getSize = function () {
    this.size = b(this.element)
  },
  k.css = function (a) {
    var b = this.element.style;
    for (var c in a) {
      var d = j[c] || c;
      b[d] = a[c]
    }
  },
  k.getPosition = function () {
    var a = getComputedStyle(this.element),
    b = this.layout._getOption('originLeft'),
    c = this.layout._getOption('originTop'),
    d = a[b ? 'left' : 'right'],
    e = a[c ? 'top' : 'bottom'],
    f = this.layout.size,
    g = - 1 != d.indexOf('%') ? parseFloat(d) / 100 * f.width : parseInt(d, 10),
    h = - 1 != e.indexOf('%') ? parseFloat(e) / 100 * f.height : parseInt(e, 10);
    g = isNaN(g) ? 0 : g,
    h = isNaN(h) ? 0 : h,
    g -= b ? f.paddingLeft : f.paddingRight,
    h -= c ? f.paddingTop : f.paddingBottom,
    this.position.x = g,
    this.position.y = h
  },
  k.layoutPosition = function () {
    var a = this.layout.size,
    b = {
    },
    c = this.layout._getOption('originLeft'),
    d = this.layout._getOption('originTop'),
    e = c ? 'paddingLeft' : 'paddingRight',
    f = c ? 'left' : 'right',
    g = c ? 'right' : 'left',
    h = this.position.x + a[e];
    b[f] = this.getXValue(h),
    b[g] = '';
    var i = d ? 'paddingTop' : 'paddingBottom',
    j = d ? 'top' : 'bottom',
    k = d ? 'bottom' : 'top',
    l = this.position.y + a[i];
    b[j] = this.getYValue(l),
    b[k] = '',
    this.css(b),
    this.emitEvent('layout', [
      this
    ])
  },
  k.getXValue = function (a) {
    var b = this.layout._getOption('horizontal');
    return this.layout.options.percentPosition && !b ? a / this.layout.size.width * 100 + '%' : a + 'px'
  },
  k.getYValue = function (a) {
    var b = this.layout._getOption('horizontal');
    return this.layout.options.percentPosition && b ? a / this.layout.size.height * 100 + '%' : a + 'px'
  },
  k._transitionTo = function (a, b) {
    this.getPosition();
    var c = this.position.x,
    d = this.position.y,
    e = parseInt(a, 10),
    f = parseInt(b, 10),
    g = e === this.position.x && f === this.position.y;
    if (this.setPosition(a, b), g && !this.isTransitioning) return void this.layoutPosition();
    var h = a - c,
    i = b - d,
    j = {
    };
    j.transform = this.getTranslate(h, i),
    this.transition({
      to: j,
      onTransitionEnd: {
        transform: this.layoutPosition
      },
      isCleaning: !0
    })
  },
  k.getTranslate = function (a, b) {
    var c = this.layout._getOption('originLeft'),
    d = this.layout._getOption('originTop');
    return a = c ? a : - a,
    b = d ? b : - b,
    'translate3d(' + a + 'px, ' + b + 'px, 0)'
  },
  k.goTo = function (a, b) {
    this.setPosition(a, b),
    this.layoutPosition()
  },
  k.moveTo = k._transitionTo,
  k.setPosition = function (a, b) {
    this.position.x = parseInt(a, 10),
    this.position.y = parseInt(b, 10)
  },
  k._nonTransition = function (a) {
    this.css(a.to),
    a.isCleaning && this._removeStyles(a.to);
    for (var b in a.onTransitionEnd) a.onTransitionEnd[b].call(this)
  },
  k.transition = function (a) {
    if (!parseFloat(this.layout.options.transitionDuration)) return void this._nonTransition(a);
    var b = this._transn;
    for (var c in a.onTransitionEnd) b.onEnd[c] = a.onTransitionEnd[c];
    for (c in a.to) b.ingProperties[c] = !0,
    a.isCleaning && (b.clean[c] = !0);
    if (a.from) {
      this.css(a.from);
      var d = this.element.offsetHeight;
      d = null
    }
    this.enableTransition(a.to),
    this.css(a.to),
    this.isTransitioning = !0
  };
  var l = 'opacity,' + e(h);
  k.enableTransition = function () {
    if (!this.isTransitioning) {
      var a = this.layout.options.transitionDuration;
      a = 'number' == typeof a ? a + 'ms' : a,
      this.css({
        transitionProperty: l,
        transitionDuration: a,
        transitionDelay: this.staggerDelay || 0
      }),
      this.element.addEventListener(i, this, !1)
    }
  },
  k.onwebkitTransitionEnd = function (a) {
    this.ontransitionend(a)
  },
  k.onotransitionend = function (a) {
    this.ontransitionend(a)
  };
  var m = {
    '-webkit-transform': 'transform'
  };
  k.ontransitionend = function (a) {
    if (a.target === this.element) {
      var b = this._transn,
      d = m[a.propertyName] || a.propertyName;
      if (delete b.ingProperties[d], c(b.ingProperties) && this.disableTransition(), d in b.clean && (this.element.style[a.propertyName] = '', delete b.clean[d]), d in b.onEnd) {
        var e = b.onEnd[d];
        e.call(this),
        delete b.onEnd[d]
      }
      this.emitEvent('transitionEnd', [
        this
      ])
    }
  },
  k.disableTransition = function () {
    this.removeTransitionStyles(),
    this.element.removeEventListener(i, this, !1),
    this.isTransitioning = !1
  },
  k._removeStyles = function (a) {
    var b = {
    };
    for (var c in a) b[c] = '';
    this.css(b)
  };
  var n = {
    transitionProperty: '',
    transitionDuration: '',
    transitionDelay: ''
  };
  return k.removeTransitionStyles = function () {
    this.css(n)
  },
  k.stagger = function (a) {
    a = isNaN(a) ? 0 : a,
    this.staggerDelay = a + 'ms'
  },
  k.removeElem = function () {
    this.element.parentNode.removeChild(this.element),
    this.css({
      display: ''
    }),
    this.emitEvent('remove', [
      this
    ])
  },
  k.remove = function () {
    return g && parseFloat(this.layout.options.transitionDuration) ? (this.once('transitionEnd', function () {
      this.removeElem()
    }), void this.hide())  : void this.removeElem()
  },
  k.reveal = function () {
    delete this.isHidden,
    this.css({
      display: ''
    });
    var a = this.layout.options,
    b = {
    },
    c = this.getHideRevealTransitionEndProperty('visibleStyle');
    b[c] = this.onRevealTransitionEnd,
    this.transition({
      from: a.hiddenStyle,
      to: a.visibleStyle,
      isCleaning: !0,
      onTransitionEnd: b
    })
  },
  k.onRevealTransitionEnd = function () {
    this.isHidden || this.emitEvent('reveal')
  },
  k.getHideRevealTransitionEndProperty = function (a) {
    var b = this.layout.options[a];
    if (b.opacity) return 'opacity';
    for (var c in b) return c
  },
  k.hide = function () {
    this.isHidden = !0,
    this.css({
      display: ''
    });
    var a = this.layout.options,
    b = {
    },
    c = this.getHideRevealTransitionEndProperty('hiddenStyle');
    b[c] = this.onHideTransitionEnd,
    this.transition({
      from: a.visibleStyle,
      to: a.hiddenStyle,
      isCleaning: !0,
      onTransitionEnd: b
    })
  },
  k.onHideTransitionEnd = function () {
    this.isHidden && (this.css({
      display: 'none'
    }), this.emitEvent('hide'))
  },
  k.destroy = function () {
    this.css({
      position: '',
      left: '',
      right: '',
      top: '',
      bottom: '',
      transition: '',
      transform: ''
    })
  },
  d
}),
function (a, b) {
  'use strict';
  'function' == typeof define && define.amd ? define('outlayer/outlayer', [
    'ev-emitter/ev-emitter',
    'get-size/get-size',
    'fizzy-ui-utils/utils',
    './item'
  ], function (c, d, e, f) {
    return b(a, c, d, e, f)
  })  : 'object' == typeof module && module.exports ? module.exports = b(a, require('ev-emitter'), require('get-size'), require('fizzy-ui-utils'), require('./item'))  : a.Outlayer = b(a, a.EvEmitter, a.getSize, a.fizzyUIUtils, a.Outlayer.Item)
}(window, function (a, b, c, d, e) {
  'use strict';
  function f(a, b) {
    var c = d.getQueryElement(a);
    if (!c) return void (i && i.error('Bad element for ' + this.constructor.namespace + ': ' + (c || a)));
    this.element = c,
    j && (this.$element = j(this.element)),
    this.options = d.extend({
    }, this.constructor.defaults),
    this.option(b);
    var e = ++l;
    this.element.outlayerGUID = e,
    m[e] = this,
    this._create();
    var f = this._getOption('initLayout');
    f && this.layout()
  }
  function g(a) {
    function b() {
      a.apply(this, arguments)
    }
    return b.prototype = Object.create(a.prototype),
    b.prototype.constructor = b,
    b
  }
  function h(a) {
    if ('number' == typeof a) return a;
    var b = a.match(/(^\d*\.?\d*)(\w*)/),
    c = b && b[1],
    d = b && b[2];
    if (!c.length) return 0;
    c = parseFloat(c);
    var e = o[d] || 1;
    return c * e
  }
  var i = a.console,
  j = a.jQuery,
  k = function () {
  },
  l = 0,
  m = {
  };
  f.namespace = 'outlayer',
  f.Item = e,
  f.defaults = {
    containerStyle: {
      position: 'relative'
    },
    initLayout: !0,
    originLeft: !0,
    originTop: !0,
    resize: !0,
    resizeContainer: !0,
    transitionDuration: '0.4s',
    hiddenStyle: {
      opacity: 0,
      transform: 'scale(0.001)'
    },
    visibleStyle: {
      opacity: 1,
      transform: 'scale(1)'
    }
  };
  var n = f.prototype;
  d.extend(n, b.prototype),
  n.option = function (a) {
    d.extend(this.options, a)
  },
  n._getOption = function (a) {
    var b = this.constructor.compatOptions[a];
    return b && void 0 !== this.options[b] ? this.options[b] : this.options[a]
  },
  f.compatOptions = {
    initLayout: 'isInitLayout',
    horizontal: 'isHorizontal',
    layoutInstant: 'isLayoutInstant',
    originLeft: 'isOriginLeft',
    originTop: 'isOriginTop',
    resize: 'isResizeBound',
    resizeContainer: 'isResizingContainer'
  },
  n._create = function () {
    this.reloadItems(),
    this.stamps = [
    ],
    this.stamp(this.options.stamp),
    d.extend(this.element.style, this.options.containerStyle);
    var a = this._getOption('resize');
    a && this.bindResize()
  },
  n.reloadItems = function () {
    this.items = this._itemize(this.element.children)
  },
  n._itemize = function (a) {
    for (var b = this._filterFindItemElements(a), c = this.constructor.Item, d = [
    ], e = 0; e < b.length; e++) {
      var f = b[e],
      g = new c(f, this);
      d.push(g)
    }
    return d
  },
  n._filterFindItemElements = function (a) {
    return d.filterFindElements(a, this.options.itemSelector)
  },
  n.getItemElements = function () {
    return this.items.map(function (a) {
      return a.element
    })
  },
  n.layout = function () {
    this._resetLayout(),
    this._manageStamps();
    var a = this._getOption('layoutInstant'),
    b = void 0 !== a ? a : !this._isLayoutInited;
    this.layoutItems(this.items, b),
    this._isLayoutInited = !0
  },
  n._init = n.layout,
  n._resetLayout = function () {
    this.getSize()
  },
  n.getSize = function () {
    this.size = c(this.element)
  },
  n._getMeasurement = function (a, b) {
    var d,
    e = this.options[a];
    e ? ('string' == typeof e ? d = this.element.querySelector(e)  : e instanceof HTMLElement && (d = e), this[a] = d ? c(d) [b] : e)  : this[a] = 0
  },
  n.layoutItems = function (a, b) {
    a = this._getItemsForLayout(a),
    this._layoutItems(a, b),
    this._postLayout()
  },
  n._getItemsForLayout = function (a) {
    return a.filter(function (a) {
      return !a.isIgnored
    })
  },
  n._layoutItems = function (a, b) {
    if (this._emitCompleteOnItems('layout', a), a && a.length) {
      var c = [
      ];
      a.forEach(function (a) {
        var d = this._getItemLayoutPosition(a);
        d.item = a,
        d.isInstant = b || a.isLayoutInstant,
        c.push(d)
      }, this),
      this._processLayoutQueue(c)
    }
  },
  n._getItemLayoutPosition = function () {
    return {
      x: 0,
      y: 0
    }
  },
  n._processLayoutQueue = function (a) {
    this.updateStagger(),
    a.forEach(function (a, b) {
      this._positionItem(a.item, a.x, a.y, a.isInstant, b)
    }, this)
  },
  n.updateStagger = function () {
    var a = this.options.stagger;
    return null === a || void 0 === a ? void (this.stagger = 0)  : (this.stagger = h(a), this.stagger)
  },
  n._positionItem = function (a, b, c, d, e) {
    d ? a.goTo(b, c)  : (a.stagger(e * this.stagger), a.moveTo(b, c))
  },
  n._postLayout = function () {
    this.resizeContainer()
  },
  n.resizeContainer = function () {
    var a = this._getOption('resizeContainer');
    if (a) {
      var b = this._getContainerSize();
      b && (this._setContainerMeasure(b.width, !0), this._setContainerMeasure(b.height, !1))
    }
  },
  n._getContainerSize = k,
  n._setContainerMeasure = function (a, b) {
    if (void 0 !== a) {
      var c = this.size;
      c.isBorderBox && (a += b ? c.paddingLeft + c.paddingRight + c.borderLeftWidth + c.borderRightWidth : c.paddingBottom + c.paddingTop + c.borderTopWidth + c.borderBottomWidth),
      a = Math.max(a, 0),
      this.element.style[b ? 'width' : 'height'] = a + 'px'
    }
  },
  n._emitCompleteOnItems = function (a, b) {
    function c() {
      e.dispatchEvent(a + 'Complete', null, [
        b
      ])
    }
    function d() {
      g++,
      g == f && c()
    }
    var e = this,
    f = b.length;
    if (!b || !f) return void c();
    var g = 0;
    b.forEach(function (b) {
      b.once(a, d)
    })
  },
  n.dispatchEvent = function (a, b, c) {
    var d = b ? [
      b
    ].concat(c)  : c;
    if (this.emitEvent(a, d), j) if (this.$element = this.$element || j(this.element), b) {
      var e = j.Event(b);
      e.type = a,
      this.$element.trigger(e, c)
    } else this.$element.trigger(a, c)
  },
  n.ignore = function (a) {
    var b = this.getItem(a);
    b && (b.isIgnored = !0)
  },
  n.unignore = function (a) {
    var b = this.getItem(a);
    b && delete b.isIgnored
  },
  n.stamp = function (a) {
    a = this._find(a),
    a && (this.stamps = this.stamps.concat(a), a.forEach(this.ignore, this))
  },
  n.unstamp = function (a) {
    a = this._find(a),
    a && a.forEach(function (a) {
      d.removeFrom(this.stamps, a),
      this.unignore(a)
    }, this)
  },
  n._find = function (a) {
    return a ? ('string' == typeof a && (a = this.element.querySelectorAll(a)), a = d.makeArray(a))  : void 0
  },
  n._manageStamps = function () {
    this.stamps && this.stamps.length && (this._getBoundingRect(), this.stamps.forEach(this._manageStamp, this))
  },
  n._getBoundingRect = function () {
    var a = this.element.getBoundingClientRect(),
    b = this.size;
    this._boundingRect = {
      left: a.left + b.paddingLeft + b.borderLeftWidth,
      top: a.top + b.paddingTop + b.borderTopWidth,
      right: a.right - (b.paddingRight + b.borderRightWidth),
      bottom: a.bottom - (b.paddingBottom + b.borderBottomWidth)
    }
  },
  n._manageStamp = k,
  n._getElementOffset = function (a) {
    var b = a.getBoundingClientRect(),
    d = this._boundingRect,
    e = c(a),
    f = {
      left: b.left - d.left - e.marginLeft,
      top: b.top - d.top - e.marginTop,
      right: d.right - b.right - e.marginRight,
      bottom: d.bottom - b.bottom - e.marginBottom
    };
    return f
  },
  n.handleEvent = d.handleEvent,
  n.bindResize = function () {
    a.addEventListener('resize', this),
    this.isResizeBound = !0
  },
  n.unbindResize = function () {
    a.removeEventListener('resize', this),
    this.isResizeBound = !1
  },
  n.onresize = function () {
    this.resize()
  },
  d.debounceMethod(f, 'onresize', 100),
  n.resize = function () {
    this.isResizeBound && this.needsResizeLayout() && this.layout()
  },
  n.needsResizeLayout = function () {
    var a = c(this.element),
    b = this.size && a;
    return b && a.innerWidth !== this.size.innerWidth
  },
  n.addItems = function (a) {
    var b = this._itemize(a);
    return b.length && (this.items = this.items.concat(b)),
    b
  },
  n.appended = function (a) {
    var b = this.addItems(a);
    b.length && (this.layoutItems(b, !0), this.reveal(b))
  },
  n.prepended = function (a) {
    var b = this._itemize(a);
    if (b.length) {
      var c = this.items.slice(0);
      this.items = b.concat(c),
      this._resetLayout(),
      this._manageStamps(),
      this.layoutItems(b, !0),
      this.reveal(b),
      this.layoutItems(c)
    }
  },
  n.reveal = function (a) {
    if (this._emitCompleteOnItems('reveal', a), a && a.length) {
      var b = this.updateStagger();
      a.forEach(function (a, c) {
        a.stagger(c * b),
        a.reveal()
      })
    }
  },
  n.hide = function (a) {
    if (this._emitCompleteOnItems('hide', a), a && a.length) {
      var b = this.updateStagger();
      a.forEach(function (a, c) {
        a.stagger(c * b),
        a.hide()
      })
    }
  },
  n.revealItemElements = function (a) {
    var b = this.getItems(a);
    this.reveal(b)
  },
  n.hideItemElements = function (a) {
    var b = this.getItems(a);
    this.hide(b)
  },
  n.getItem = function (a) {
    for (var b = 0; b < this.items.length; b++) {
      var c = this.items[b];
      if (c.element == a) return c
    }
  },
  n.getItems = function (a) {
    a = d.makeArray(a);
    var b = [
    ];
    return a.forEach(function (a) {
      var c = this.getItem(a);
      c && b.push(c)
    }, this),
    b
  },
  n.remove = function (a) {
    var b = this.getItems(a);
    this._emitCompleteOnItems('remove', b),
    b && b.length && b.forEach(function (a) {
      a.remove(),
      d.removeFrom(this.items, a)
    }, this)
  },
  n.destroy = function () {
    var a = this.element.style;
    a.height = '',
    a.position = '',
    a.width = '',
    this.items.forEach(function (a) {
      a.destroy()
    }),
    this.unbindResize();
    var b = this.element.outlayerGUID;
    delete m[b],
    delete this.element.outlayerGUID,
    j && j.removeData(this.element, this.constructor.namespace)
  },
  f.data = function (a) {
    a = d.getQueryElement(a);
    var b = a && a.outlayerGUID;
    return b && m[b]
  },
  f.create = function (a, b) {
    var c = g(f);
    return c.defaults = d.extend({
    }, f.defaults),
    d.extend(c.defaults, b),
    c.compatOptions = d.extend({
    }, f.compatOptions),
    c.namespace = a,
    c.data = f.data,
    c.Item = g(e),
    d.htmlInit(c, a),
    j && j.bridget && j.bridget(a, c),
    c
  };
  var o = {
    ms: 1,
    s: 1000
  };
  return f.Item = e,
  f
}),
function (a, b) {
  'function' == typeof define && define.amd ? define(['outlayer/outlayer',
  'get-size/get-size'], b)  : 'object' == typeof module && module.exports ? module.exports = b(require('outlayer'), require('get-size'))  : a.Masonry = b(a.Outlayer, a.getSize)
}(window, function (a, b) {
  var c = a.create('masonry');
  return c.compatOptions.fitWidth = 'isFitWidth',
  c.prototype._resetLayout = function () {
    this.getSize(),
    this._getMeasurement('columnWidth', 'outerWidth'),
    this._getMeasurement('gutter', 'outerWidth'),
    this.measureColumns(),
    this.colYs = [
    ];
    for (var a = 0; a < this.cols; a++) this.colYs.push(0);
    this.maxY = 0
  },
  c.prototype.measureColumns = function () {
    if (this.getContainerWidth(), !this.columnWidth) {
      var a = this.items[0],
      c = a && a.element;
      this.columnWidth = c && b(c).outerWidth || this.containerWidth
    }
    var d = this.columnWidth += this.gutter,
    e = this.containerWidth + this.gutter,
    f = e / d,
    g = d - e % d,
    h = g && 1 > g ? 'round' : 'floor';
    f = Math[h](f),
    this.cols = Math.max(f, 1)
  },
  c.prototype.getContainerWidth = function () {
    var a = this._getOption('fitWidth'),
    c = a ? this.element.parentNode : this.element,
    d = b(c);
    this.containerWidth = d && d.innerWidth
  },
  c.prototype._getItemLayoutPosition = function (a) {
    a.getSize();
    var b = a.size.outerWidth % this.columnWidth,
    c = b && 1 > b ? 'round' : 'ceil',
    d = Math[c](a.size.outerWidth / this.columnWidth);
    d = Math.min(d, this.cols);
    for (var e = this._getColGroup(d), f = Math.min.apply(Math, e), g = e.indexOf(f), h = {
      x: this.columnWidth * g,
      y: f
    }, i = f + a.size.outerHeight, j = this.cols + 1 - e.length, k = 0; j > k; k++) this.colYs[g + k] = i;
    return h
  },
  c.prototype._getColGroup = function (a) {
    if (2 > a) return this.colYs;
    for (var b = [
    ], c = this.cols + 1 - a, d = 0; c > d; d++) {
      var e = this.colYs.slice(d, d + a);
      b[d] = Math.max.apply(Math, e)
    }
    return b
  },
  c.prototype._manageStamp = function (a) {
    var c = b(a),
    d = this._getElementOffset(a),
    e = this._getOption('originLeft'),
    f = e ? d.left : d.right,
    g = f + c.outerWidth,
    h = Math.floor(f / this.columnWidth);
    h = Math.max(0, h);
    var i = Math.floor(g / this.columnWidth);
    i -= g % this.columnWidth ? 0 : 1,
    i = Math.min(this.cols - 1, i);
    for (var j = this._getOption('originTop'), k = (j ? d.top : d.bottom) + c.outerHeight, l = h; i >= l; l++) this.colYs[l] = Math.max(k, this.colYs[l])
  },
  c.prototype._getContainerSize = function () {
    this.maxY = Math.max.apply(Math, this.colYs);
    var a = {
      height: this.maxY
    };
    return this._getOption('fitWidth') && (a.width = this._getContainerFitWidth()),
    a
  },
  c.prototype._getContainerFitWidth = function () {
    for (var a = 0, b = this.cols; --b && 0 === this.colYs[b]; ) a++;
    return (this.cols - a) * this.columnWidth - this.gutter
  },
  c.prototype.needsResizeLayout = function () {
    var a = this.containerWidth;
    return this.getContainerWidth(),
    a != this.containerWidth
  },
  c
}),
$(document).ready(function () {
  $('.masonry').each(function () {
    var a = $(this);
    a.removeClass('masonry-loading'),
    a.children().addClass('masonry-block'),
    a.append('<div class=\'masonry-sizer\' />'),
    a.imagesLoaded(function () {
      a.masonry({
        itemSelector: '.masonry-block',
        columnWidth: '.masonry-sizer',
        gutter: 20,
        percentPosition: !0
      })
    })
  })
}),
window.REmodalPattern_GLOBALS = {
  NAMESPACE: 'modalPattern',
  DEFAULTS: {
    hashTracking: !1
  }
},
!function (a, b) {
  'function' == typeof define && define.amd ? define(['jquery'], function (c) {
    return b(a, c)
  })  : 'object' == typeof exports ? b(a, require('jquery'))  : b(a, a.jQuery || a.Zepto)
}(this, function (a, b) {
  'use strict';
  function c(a) {
    if (w && 'none' === a.css('animation-name') && 'none' === a.css('-webkit-animation-name') && 'none' === a.css('-moz-animation-name') && 'none' === a.css('-o-animation-name') && 'none' === a.css('-ms-animation-name')) return 0;
    var b,
    c,
    d,
    e,
    f = a.css('animation-duration') || a.css('-webkit-animation-duration') || a.css('-moz-animation-duration') || a.css('-o-animation-duration') || a.css('-ms-animation-duration') || '0s',
    g = a.css('animation-delay') || a.css('-webkit-animation-delay') || a.css('-moz-animation-delay') || a.css('-o-animation-delay') || a.css('-ms-animation-delay') || '0s',
    h = a.css('animation-iteration-count') || a.css('-webkit-animation-iteration-count') || a.css('-moz-animation-iteration-count') || a.css('-o-animation-iteration-count') || a.css('-ms-animation-iteration-count') || '1';
    for (f = f.split(', '), g = g.split(', '), h = h.split(', '), e = 0, c = f.length, b = Number.NEGATIVE_INFINITY; e < c; e++) d = parseFloat(f[e]) * parseInt(h[e], 10) + parseFloat(g[e]),
    d > b && (b = d);
    return b
  }
  function d() {
    if (b(document).height() <= b(window).height()) return 0;
    var a,
    c,
    d = document.createElement('div'),
    e = document.createElement('div');
    return d.style.visibility = 'hidden',
    d.style.width = '100px',
    document.body.appendChild(d),
    a = d.offsetWidth,
    d.style.overflow = 'scroll',
    e.style.width = '100%',
    d.appendChild(e),
    c = e.offsetWidth,
    d.parentNode.removeChild(d),
    a - c
  }
  function e() {
    if (!x) {
      var a,
      c,
      e = b('html'),
      f = k('is-locked');
      e.hasClass(f) || (c = b(document.body), a = parseInt(c.css('padding-right'), 10) + d(), c.css('padding-right', a + 'px'), e.addClass(f))
    }
  }
  function f() {
    if (!x) {
      var a,
      c,
      e = b('html'),
      f = k('is-locked');
      e.hasClass(f) && (c = b(document.body), a = parseInt(c.css('padding-right'), 10) - d(), c.css('padding-right', a + 'px'), e.removeClass(f))
    }
  }
  function g(a, b, c, d) {
    var e = k('is', b),
    f = [
      k('is', u.CLOSING),
      k('is', u.OPENING),
      k('is', u.CLOSED),
      k('is', u.OPENED)
    ].join(' ');
    a.$bg.removeClass(f).addClass(e),
    a.$overlay.removeClass(f).addClass(e),
    a.$wrapper.removeClass(f).addClass(e),
    a.$modalPattern.removeClass(f).addClass(e),
    a.state = b,
    !c && a.$modalPattern.trigger({
      type: b,
      reason: d
    }, [
      {
        reason: d
      }
    ])
  }
  function h(a, d, e) {
    var f = 0,
    g = function (a) {
      a.target === this && f++
    },
    h = function (a) {
      a.target === this && 0 === --f && (b.each(['$bg',
      '$overlay',
      '$wrapper',
      '$modalPattern'], function (a, b) {
        e[b].off(r + ' ' + s)
      }), d())
    };
    b.each(['$bg',
    '$overlay',
    '$wrapper',
    '$modalPattern'], function (a, b) {
      e[b].on(r, g).on(s, h)
    }),
    a(),
    0 === c(e.$bg) && 0 === c(e.$overlay) && 0 === c(e.$wrapper) && 0 === c(e.$modalPattern) && (b.each(['$bg',
    '$overlay',
    '$wrapper',
    '$modalPattern'], function (a, b) {
      e[b].off(r + ' ' + s)
    }), d())
  }
  function i(a) {
    a.state !== u.CLOSED && (b.each(['$bg',
    '$overlay',
    '$wrapper',
    '$modalPattern'], function (b, c) {
      a[c].off(r + ' ' + s)
    }), a.$bg.removeClass(a.settings.modifier), a.$overlay.removeClass(a.settings.modifier).hide(), a.$wrapper.hide(), f(), g(a, u.CLOSED, !0))
  }
  function j(a) {
    var b,
    c,
    d,
    e,
    f = {
    };
    for (a = a.replace(/\s*:\s*/g, ':').replace(/\s*,\s*/g, ','), b = a.split(','), e = 0, c = b.length; e < c; e++) b[e] = b[e].split(':'),
    d = b[e][1],
    ('string' == typeof d || d instanceof String) && (d = 'true' === d || 'false' !== d && d),
    ('string' == typeof d || d instanceof String) && (d = isNaN(d) ? d : + d),
    f[b[e][0]] = d;
    return f
  }
  function k() {
    for (var a = q, b = 0; b < arguments.length; ++b) a += '-' + arguments[b];
    return a
  }
  function l() {
    var a,
    c,
    d = location.hash.replace('#', '');
    if (d) {
      try {
        c = b('[data-modalPattern-id="' + d + '"]')
      } catch (a) {
      }
      c && c.length && (a = b[p].lookup[c.data(p)], a && a.settings.hashTracking && a.open())
    } else n && n.state === u.OPENED && n.settings.hashTracking && n.close()
  }
  function m(a, c) {
    var d = b(document.body),
    e = d,
    f = this;
    f.settings = b.extend({
    }, t, c),
    f.index = b[p].lookup.push(f) - 1,
    f.state = u.CLOSED,
    f.$overlay = b('.' + k('overlay')),
    null !== f.settings.appendTo && f.settings.appendTo.length && (e = b(f.settings.appendTo)),
    f.$overlay.length || (f.$overlay = b('<div>').addClass(k('overlay') + ' ' + k('is', u.CLOSED)).hide(), e.append(f.$overlay)),
    f.$bg = b('.' + k('bg')).addClass(k('is', u.CLOSED)),
    f.$modalPattern = a.addClass(q + ' ' + k('is-initialized') + ' ' + f.settings.modifier + ' ' + k('is', u.CLOSED)).attr('tabindex', '-1'),
    f.$wrapper = b('<div>').addClass(k('wrapper') + ' ' + f.settings.modifier + ' ' + k('is', u.CLOSED)).hide().append(f.$modalPattern),
    e.append(f.$wrapper),
    f.$wrapper.on('click.' + q, '[data-modalPattern-action="close"]', function (a) {
      a.preventDefault(),
      f.close()
    }),
    f.$wrapper.on('click.' + q, '[data-modalPattern-action="cancel"]', function (a) {
      a.preventDefault(),
      f.$modalPattern.trigger(v.CANCELLATION),
      f.settings.closeOnCancel && f.close(v.CANCELLATION)
    }),
    f.$wrapper.on('click.' + q, '[data-modalPattern-action="confirm"]', function (a) {
      a.preventDefault(),
      f.$modalPattern.trigger(v.CONFIRMATION),
      f.settings.closeOnConfirm && f.close(v.CONFIRMATION)
    }),
    f.$wrapper.on('click.' + q, function (a) {
      var c = b(a.target);
      c.hasClass(k('wrapper')) && f.settings.closeOnOutsideClick && f.close()
    })
  }
  var n,
  o,
  p = 'modalPattern',
  q = a.REmodalPattern_GLOBALS && a.REmodalPattern_GLOBALS.NAMESPACE || p,
  r = b.map(['animationstart',
  'webkitAnimationStart',
  'MSAnimationStart',
  'oAnimationStart'], function (a) {
    return a + '.' + q
  }).join(' '),
  s = b.map(['animationend',
  'webkitAnimationEnd',
  'MSAnimationEnd',
  'oAnimationEnd'], function (a) {
    return a + '.' + q
  }).join(' '),
  t = b.extend({
    hashTracking: !0,
    closeOnConfirm: !0,
    closeOnCancel: !0,
    closeOnEscape: !0,
    closeOnOutsideClick: !0,
    modifier: '',
    appendTo: null
  }, a.REmodalPattern_GLOBALS && a.REmodalPattern_GLOBALS.DEFAULTS),
  u = {
    CLOSING: 'closing',
    CLOSED: 'closed',
    OPENING: 'opening',
    OPENED: 'opened'
  },
  v = {
    CONFIRMATION: 'confirmation',
    CANCELLATION: 'cancellation'
  },
  w = function () {
    var a = document.createElement('div').style;
    return void 0 !== a.animationName || void 0 !== a.WebkitAnimationName || void 0 !== a.MozAnimationName || void 0 !== a.msAnimationName || void 0 !== a.OAnimationName
  }(),
  x = /iPad|iPhone|iPod/.test(navigator.platform);
  m.prototype.open = function () {
    var a,
    c = this;
    c.state !== u.OPENING && c.state !== u.CLOSING && (a = c.$modalPattern.attr('data-modalPattern-id'), a && c.settings.hashTracking && (o = b(window).scrollTop(), location.hash = a), n && n !== c && i(n), n = c, e(), c.$bg.addClass(c.settings.modifier), c.$overlay.addClass(c.settings.modifier).show(), c.$wrapper.show().scrollTop(0), c.$modalPattern.focus(), h(function () {
      g(c, u.OPENING)
    }, function () {
      g(c, u.OPENED)
    }, c))
  },
  m.prototype.close = function (a) {
    var c = this;
    c.state !== u.OPENING && c.state !== u.CLOSING && c.state !== u.CLOSED && (c.settings.hashTracking && c.$modalPattern.attr('data-modalPattern-id') === location.hash.substr(1) && (location.hash = '', b(window).scrollTop(o)), h(function () {
      g(c, u.CLOSING, !1, a)
    }, function () {
      c.$bg.removeClass(c.settings.modifier),
      c.$overlay.removeClass(c.settings.modifier).hide(),
      c.$wrapper.hide(),
      f(),
      g(c, u.CLOSED, !1, a)
    }, c))
  },
  m.prototype.getState = function () {
    return this.state
  },
  m.prototype.destroy = function () {
    var a,
    c = b[p].lookup;
    i(this),
    this.$wrapper.remove(),
    delete c[this.index],
    a = b.grep(c, function (a) {
      return !!a
    }).length,
    0 === a && (this.$overlay.remove(), this.$bg.removeClass(k('is', u.CLOSING) + ' ' + k('is', u.OPENING) + ' ' + k('is', u.CLOSED) + ' ' + k('is', u.OPENED)))
  },
  b[p] = {
    lookup: [
    ]
  },
  b.fn[p] = function (a) {
    var c,
    d;
    return this.each(function (e, f) {
      d = b(f),
      null == d.data(p) ? (c = new m(d, a), d.data(p, c.index), c.settings.hashTracking && d.attr('data-modalPattern-id') === location.hash.substr(1) && c.open())  : c = b[p].lookup[d.data(p)]
    }),
    c
  },
  b(document).ready(function () {
    b(document).on('click', '[data-modalPattern-target]', function (a) {
      a.preventDefault();
      var c = a.currentTarget,
      d = c.getAttribute('data-modalPattern-target'),
      e = b('[data-modalPattern-id="' + d + '"]');
      b[p].lookup[e.data(p)].open()
    }),
    b(document).find('.' + q).each(function (a, c) {
      var d = b(c),
      e = d.data('modalPattern-options');
      e ? ('string' == typeof e || e instanceof String) && (e = j(e))  : e = {
      },
      d[p](e)
    }),
    b(document).on('keydown.' + q, function (a) {
      n && n.settings.closeOnEscape && n.state === u.OPENED && 27 === a.keyCode && n.close()
    }),
    b(window).on('hashchange.' + q, l)
  })
}),
$(document).ready(function () {
  function a(a, b) {
    var c;
    for (c in a) a.hasOwnProperty(c) && b.find('[data-from=\'' + c + '\']').each(function () {
      var b = $(this);
      b.is('select') ? (b.trigger('reset'), b.val(a[c]), b.trigger('change'))  : b.val(a[c])
    })
  }
  $('[data-modalPattern-id]').modalPattern(),
  $(document).on('submit', '[data-modalPattern-form-target]', function (b) {
    b.preventDefault();
    var c = $(this),
    d = {
    },
    e = c.data('modalPattern-form-target'),
    f = $('[data-modalPattern-id=' + e + ']');
    c.find('input,select').each(function () {
      var a = $(this),
      b = a.attr('id');
      b && (d[b] = a.val())
    }),
    f.length > 0 && (a(d, f), f.modalPattern({
    }).open())
  })
}),
$(document).ready(function () {
  $('.notice').on('click', '.close-button', function (a) {
    a.preventDefault();
    var b = $(this),
    c = b.parent();
    c.slideUp(500, function () {
      c.remove()
    })
  })
}),
function (a) {
  'use strict';
  var b = a('.number-stepper');
  b.each(function () {
    var b = a(this),
    c = b.children('input'),
    d = b.children('.plus'),
    e = b.children('.minus'),
    f = b.data('min'),
    g = b.data('max'),
    h = b.data('step');
    h = h ? parseInt(h)  : 1,
    f && (f = parseInt(f)),
    g && (g = parseInt(g)),
    d.on('click', function () {
      var a = parseInt(c.val());
      (!g || g && a + h <= g) && c.val(a + h)
    }),
    e.on('click', function () {
      var a = parseInt(c.val());
      (!f || f && a - h >= f) && c.val(a - h)
    })
  })
}(jQuery),
function (a) {
  'use strict';
  a(document).ready( function () {
    function b() {
      clearTimeout(g)
    }
    function c() {
      p.removeClass('active')
    }
    function d() {
      j || (h.addClass('responsive-menu-open'), l.addClass('open'), j = !0)
    }
    function e() {
      h.removeClass('responsive-menu-open'),
      l.removeClass('open'),
      j = !1
    }
    function f() {
      v.width();
      q.is(':hidden') ? 'desktop' === i && (p.children().appendTo(m), q.children().appendTo(n), r.children().appendTo(o), n.find('.dropdown').each(function () {
        var b = a(this),
        c = !1,
        d = a('<button class=\'dropdown-toggler\'>+</button>');
        d.on('click', function () {
          c = !c,
          c ? d.text('-')  : d.text('+'),
          b.stop().slideToggle()
        }).insertBefore(b)
      }), i = 'tablet')  : 'tablet' === i && (n.find('.dropdown-toggler').remove(), n.find('.dropdown').removeAttr('style'), m.children().appendTo(p), n.children().appendTo(q), o.children().appendTo(r), i = 'desktop', e())
    }
    var g,
    h = (a('#body-wrapper'), a('#inner-body-wrapper')),
    i = 'desktop',
    j = !1,
    k = a('.primary-navigation'),
    l = a('.responsive-slideout'),
    m = (a('.responsive-slideout-content'), l.find('.responsive-search')),
    n = l.find('.responsive-menu'),
    o = l.find('.responsive-account-menu'),
    p = k.find('.search-bar'),
    q = k.find('.menu'),
    r = k.find('.account'),
    s = k.find('.clear-button'),
    t = a('#search-field'),
    u = p.find('.search-button'),
    v = a(window);
    s.on('click', function (a) {
      a.preventDefault(),
      s.hide(),
      t.typeahead ? t.typeahead('val', '').typeahead('close')  : t.val(''),
      t.focus()
    }),
    t.on('change keyup', function () {
      '' != t.val() ? s.show()  : s.hide()
    }),
    t.on('focus', b),
    t.on('blur', function () {
      g = setTimeout(c, 300)
    }),
    u.on('focus', b),
    u.on('blur', function () {
      g = setTimeout(c, 300)
    }),
    a('#search-box-toggler').on('click', function () {
      p.hasClass('active') ? p.removeClass('active')  : (p.addClass('active'), t.focus())
    }),
    a('.responsive-menu-toggler').on('click', function () {
      'tablet' === i && (j ? e()  : d())
    }),
    v.on('resize', f),
    f()
  })
}(jQuery),
function (a, b, c, d) {
  'use strict';
  function e(a, b, c, d) {
    a = (a + '').replace(/[^0-9+\-Ee.]/g, '');
    var e = isFinite( + a) ? + a : 0,
    f = isFinite( + b) ? Math.abs(b)  : 0,
    g = 'undefined' == typeof d ? ',' : d,
    h = 'undefined' == typeof c ? '.' : c,
    i = '',
    j = function (a, b) {
      var c = Math.pow(10, b);
      return '' + (Math.round(a * c) / c).toFixed(b)
    };
    return i = (f ? j(e, f)  : '' + Math.round(e)).split('.'),
    i[0].length > 3 && (i[0] = i[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, g)),
    (i[1] || '').length < f && (i[1] = i[1] || '', i[1] += new Array(f - i[1].length + 1).join('0')),
    i.join(h)
  }
  function f(a, b, c) {
    return a + e(b, c)
  }
  function g(b, c) {
    this.currentValue = 0,
    this.currentBracket = null,
    this.element = b,
    this.$element = a(b),
    this.settings = a.extend(!0, {
    }, k, c),
    this._defaults = k,
    this._name = j,
    this.settings.elements.progressDescription && (this.$progressDescription = a('<div class=\'progress-description\'></div>').appendTo(this.$element), this.$element.addClass('has-progress-description')),
    this.settings.elements.progressGoal && (this.$progressGoal = a('<div class=\'progress-goal\'></div>').appendTo(this.$element), this.$remainder = a('<span class=\'progress-remainder\'></span>').appendTo(this.$progressGoal), this.$element.addClass('has-progress-goal')),
    this.settings.elements.progressCurrent && (this.$progressCurrent = a('<div class=\'progress-current\'></div>').appendTo(this.$element), this.$element.addClass('has-progress-current')),
    this.$progressFillWrap = a('<div class=\'progress-fill-wrap bg-light-grey\'></div>').appendTo(this.$element),
    this.$progressFill = a('<div class=\'progress-fill ' + (this.settings.styles.progressFillSize ? ' ' + this.settings.styles.progressFillSize : '') + '\'></div>').appendTo(this.$progressFillWrap),
    this.settings.elements.progressFrom && (this.$progressFrom = a('<div class=\'progress-from\'></div>').appendTo(this.$element), this.$element.addClass('has-progress-from')),
    this.settings.elements.progressTo && (this.$progressTo = a('<div class=\'progress-to\'></div>').appendTo(this.$element), this.$element.addClass('has-progress-to')),
    this.settings.styles && (this.settings.styles.barFillColor && this.$progressFill.css('backgroundColor', this.settings.styles.barFillColor), this.settings.styles.barWrapColor && this.$progressFillWrap.css('backgroundColor', this.settings.styles.barWrapColor)),
    this.init()
  }
  function h(a, b) {
    return a / b * 100
  }
  function i(a, b) {
    return a / 100 * b
  }
  var j = 'progressBar',
  k = {
    brackets: [
    ],
    currency: '',
    decimalPlaces: 0,
    topTierMessage: 'Reached top tier',
    elements: {
      progressDescription: !1,
      progressGoal: !1,
      progressFrom: !1,
      progressTo: !1,
      progressCurrent: !1
    },
    styles: {
      barFillColor: '#00AB9C',
      barWrapColor: '#F6F6F6'
    }
  };
  a.extend(g.prototype, {
    init: function () {
      this.valFrom = this.$element.data('from') !== d ? this.$element.data('from')  : this.settings.from,
      this.valTo = this.$element.data('to') !== d ? this.$element.data('to')  : this.settings.to,
      this.currentValue = this.$element.data('value') !== d ? this.$element.data('value')  : this.settings.value,
      this.hasBrackets() && this.setBracket(this.findBracket(this.currentValue)),
      this.setValue(this.currentValue),
      this.hasBrackets() && this.updateRemainder()
    },
    hasBrackets: function () {
      return this.settings.brackets !== d && this.settings.brackets.length > 0
    },
    findBracket: function (a) {
      var b = null;
      return this.settings.brackets.forEach(function (c) {
        a >= c.from && (b = c)
      }),
      b
    },
    findNextBracket: function (a) {
      var b,
      c,
      d = null;
      for (b = 0; b < this.settings.brackets.length; b++) if (c = this.settings.brackets[b], a >= c.from && a <= c.to) {
        d = this.settings.brackets[b + 1];
        break
      }
      return d
    },
    setBracket: function (a, b) {
      this.$progressFrom.text(a.fromText),
      this.$progressTo.text(a.toText),
      this.settings.elements.progressDescription && this.$progressDescription.html(a.description),
      this.currentBracket = a,
      b !== d && b()
    },
    updateRemainder: function () {
      var a,
      b,
      c,
      d;
      this.hasBrackets() && this.settings.elements.progressGoal && (a = this.findBracket(this.currentValue), b = this.findNextBracket(this.currentValue), b ? (c = b.from - this.currentValue, d = b.name || 'next tier', this.$remainder.html('<span class=\'value impress\'>' + f(this.settings.currency, c, this.settings.decimalPlaces) + '</span> to ' + d))  : this.$remainder.html(this.settings.topTierMessage))
    },
    setValue: function (a) {
      var b,
      c,
      d,
      e,
      g,
      j = this,
      k = this.$progressFillWrap.width();
      if (this.hasBrackets() ? (b = this.findBracket(this.currentValue), b.to ? (c = b.to - b.from, d = a - b.from, e = h(d, c), e > 100 && (e = 100), g = this.$progressFill.width())  : (e = 100, g = 100), e > 100 ? (e -= 100, this.$progressCurrent.animate({
        left: '100%'
      }, {
        duration: 480,
        step: function (a, c) {
          var d = b.from + i(c.now, b.to - b.from);
          d = Math.round(d),
          j.$progressCurrent.text(f(this.settings.currency, d, this.settings.decimalPlaces))
        },
        complete: function () {
          j.$progressCurrent.text(f(this.settings.currency, b.to, this.settings.decimalPlaces))
        }
      }), this.$progressCurrent.animate({
        left: '0%'
      }, 0), this.$progressFill.animate({
        width: '100%'
      }, 500, function () {
        b = j.findBracket(a),
        j.setBracket(b)
      }), this.$progressFill.animate({
        width: '0%'
      }, 0))  : e < 0 && (e += 100, this.$progressCurrent.animate({
        left: '0%'
      }, {
        duration: 480,
        step: function (a, c) {
          var d = b.from + i(c.now, b.to - b.from);
          d = Math.round(d),
          j.$progressCurrent.text(d)
        },
        complete: function () {
          j.$progressCurrent.text(b.to)
        }
      }), this.$progressCurrent.animate({
        left: '100%'
      }, 0), this.$progressFill.animate({
        width: '0%'
      }, 500, function () {
        b = j.findBracket(a),
        j.setBracket(b)
      }), this.$progressFill.animate({
        width: '100%'
      }, 0)))  : (b = this.findBracket(this.currentValue), c = this.valTo - this.valFrom, d = a - this.valFrom, e = h(d, c), g = this.$progressFill.width()), this.$progressFill.animate({
        width: e + '%'
      }, 2000), this.settings.elements.progressCurrent) {
        var l = this.settings,
        m = {
          left: e + '%',
          marginLeft: '-50px'
        },
        n = 50 / k * 100;
        e < n && (m.marginLeft = '-' + Math.round(e / n * 50) + 'px'),
        e > 100 - n && (m.marginLeft = '-' + ((n - (100 - e)) / n * 50 + 50) + 'px'),
        this.$progressCurrent.animate(m, {
          duration: 2000,
          step: function (a, c) {
            if ('left' === c.prop) {
              var d,
              e,
              g;
              j.hasBrackets() ? (e = b.from, g = b.to ? b.to : j.settings.value)  : (e = j.fromVal, g = j.toVal),
              d = e + i(c.now, g - e),
              d = Math.round(d),
              j.$progressCurrent.text(f(l.currency, d, l.decimalPlaces))
            }
          },
          complete: function () {
            j.$progressCurrent.text(f(l.currency, a, l.decimalPlaces))
          }
        })
      }
      this.currentValue = a,
      this.updateRemainder()
    },
    changeValue: function (a) {
      this.setValue(this.currentValue + a)
    }
  }),
  a.fn[j] = function (b) {
    return this.each(function () {
      a.data(this, 'plugin_' + j) || a.data(this, 'plugin_' + j, new g(this, b))
    })
  },
  a('.progress-bar').each(function () {
    var b = a(this);
    b.data('from') !== d && b.data('to') !== d && b.data('value') !== d && b.progressBar()
  })
}(jQuery, window, document),
function (a) {
  'use strict';
  a(document).ready(function () {
    a('.select-box-wrapper').each(function () {
      var b = a(this),
      c = b.find('select'),
      d = b.find('.select-box');
      console.log(c),
      c.on('reset', function (b) {
        var c = a(this),
        e = (c.val(), c.children().first()),
        f = e.text();
        d.text(f),
        c.trigger('change')
      }),
      c.trigger('reset'),
      c.on('change', function (b) {
        var c = a(this),
        e = c.val(),
        f = c.children('option[value=\'' + e + '\']'),
        g = f.text();
        d.text(g)
      })
    })
  })
}(jQuery),
!function (a) {
  'use strict';
  'function' == typeof define && define.amd ? define(['jquery'], a)  : 'undefined' != typeof exports ? module.exports = a(require('jquery'))  : a(jQuery)
}(function (a) {
  'use strict';
  var b = window.Slick || {
  };
  b = function () {
    function b(b, d) {
      var e,
      f = this;
      f.defaults = {
        accessibility: !0,
        adaptiveHeight: !1,
        appendArrows: a(b),
        appendDots: a(b),
        arrows: !0,
        asNavFor: null,
        prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>',
        nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>',
        autoplay: !1,
        autoplaySpeed: 3000,
        centerMode: !1,
        centerPadding: '50px',
        cssEase: 'ease',
        customPaging: function (b, c) {
          return a('<button type="button" data-role="none" role="button" tabindex="0" />').text(c + 1)
        },
        dots: !1,
        dotsClass: 'slick-dots',
        draggable: !0,
        easing: 'linear',
        edgeFriction: 0.35,
        fade: !1,
        focusOnSelect: !1,
        infinite: !0,
        initialSlide: 0,
        lazyLoad: 'ondemand',
        mobileFirst: !1,
        pauseOnHover: !0,
        pauseOnFocus: !0,
        pauseOnDotsHover: !1,
        respondTo: 'window',
        responsive: null,
        rows: 1,
        rtl: !1,
        slide: '',
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        swipe: !0,
        swipeToSlide: !1,
        touchMove: !0,
        touchThreshold: 5,
        useCSS: !0,
        useTransform: !0,
        variableWidth: !1,
        vertical: !1,
        verticalSwiping: !1,
        waitForAnimate: !0,
        zIndex: 1000
      },
      f.initials = {
        animating: !1,
        dragging: !1,
        autoPlayTimer: null,
        currentDirection: 0,
        currentLeft: null,
        currentSlide: 0,
        direction: 1,
        $dots: null,
        listWidth: null,
        listHeight: null,
        loadIndex: 0,
        $nextArrow: null,
        $prevArrow: null,
        slideCount: null,
        slideWidth: null,
        $slideTrack: null,
        $slides: null,
        sliding: !1,
        slideOffset: 0,
        swipeLeft: null,
        $list: null,
        touchObject: {
        },
        transformsEnabled: !1,
        unslicked: !1
      },
      a.extend(f, f.initials),
      f.activeBreakpoint = null,
      f.animType = null,
      f.animProp = null,
      f.breakpoints = [
      ],
      f.breakpointSettings = [
      ],
      f.cssTransitions = !1,
      f.focussed = !1,
      f.interrupted = !1,
      f.hidden = 'hidden',
      f.paused = !0,
      f.positionProp = null,
      f.respondTo = null,
      f.rowCount = 1,
      f.shouldClick = !0,
      f.$slider = a(b),
      f.$slidesCache = null,
      f.transformType = null,
      f.transitionType = null,
      f.visibilityChange = 'visibilitychange',
      f.windowWidth = 0,
      f.windowTimer = null,
      e = a(b).data('slick') || {
      },
      f.options = a.extend({
      }, f.defaults, d, e),
      f.currentSlide = f.options.initialSlide,
      f.originalSettings = f.options,
      'undefined' != typeof document.mozHidden ? (f.hidden = 'mozHidden', f.visibilityChange = 'mozvisibilitychange')  : 'undefined' != typeof document.webkitHidden && (f.hidden = 'webkitHidden', f.visibilityChange = 'webkitvisibilitychange'),
      f.autoPlay = a.proxy(f.autoPlay, f),
      f.autoPlayClear = a.proxy(f.autoPlayClear, f),
      f.autoPlayIterator = a.proxy(f.autoPlayIterator, f),
      f.changeSlide = a.proxy(f.changeSlide, f),
      f.clickHandler = a.proxy(f.clickHandler, f),
      f.selectHandler = a.proxy(f.selectHandler, f),
      f.setPosition = a.proxy(f.setPosition, f),
      f.swipeHandler = a.proxy(f.swipeHandler, f),
      f.dragHandler = a.proxy(f.dragHandler, f),
      f.keyHandler = a.proxy(f.keyHandler, f),
      f.instanceUid = c++,
      f.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/,
      f.registerBreakpoints(),
      f.init(!0)
    }
    var c = 0;
    return b
  }(),
  b.prototype.activateADA = function () {
    var a = this;
    a.$slideTrack.find('.slick-active').attr({
      'aria-hidden': 'false'
    }).find('a, input, button, select').attr({
      tabindex: '0'
    })
  },
  b.prototype.addSlide = b.prototype.slickAdd = function (b, c, d) {
    var e = this;
    if ('boolean' == typeof c) d = c,
    c = null;
     else if (0 > c || c >= e.slideCount) return !1;
    e.unload(),
    'number' == typeof c ? 0 === c && 0 === e.$slides.length ? a(b).appendTo(e.$slideTrack)  : d ? a(b).insertBefore(e.$slides.eq(c))  : a(b).insertAfter(e.$slides.eq(c))  : d === !0 ? a(b).prependTo(e.$slideTrack)  : a(b).appendTo(e.$slideTrack),
    e.$slides = e.$slideTrack.children(this.options.slide),
    e.$slideTrack.children(this.options.slide).detach(),
    e.$slideTrack.append(e.$slides),
    e.$slides.each(function (b, c) {
      a(c).attr('data-slick-index', b)
    }),
    e.$slidesCache = e.$slides,
    e.reinit()
  },
  b.prototype.animateHeight = function () {
    var a = this;
    if (1 === a.options.slidesToShow && a.options.adaptiveHeight === !0 && a.options.vertical === !1) {
      var b = a.$slides.eq(a.currentSlide).outerHeight(!0);
      a.$list.animate({
        height: b
      }, a.options.speed)
    }
  },
  b.prototype.animateSlide = function (b, c) {
    var d = {
    },
    e = this;
    e.animateHeight(),
    e.options.rtl === !0 && e.options.vertical === !1 && (b = - b),
    e.transformsEnabled === !1 ? e.options.vertical === !1 ? e.$slideTrack.animate({
      left: b
    }, e.options.speed, e.options.easing, c)  : e.$slideTrack.animate({
      top: b
    }, e.options.speed, e.options.easing, c)  : e.cssTransitions === !1 ? (e.options.rtl === !0 && (e.currentLeft = - e.currentLeft), a({
      animStart: e.currentLeft
    }).animate({
      animStart: b
    }, {
      duration: e.options.speed,
      easing: e.options.easing,
      step: function (a) {
        a = Math.ceil(a),
        e.options.vertical === !1 ? (d[e.animType] = 'translate(' + a + 'px, 0px)', e.$slideTrack.css(d))  : (d[e.animType] = 'translate(0px,' + a + 'px)', e.$slideTrack.css(d))
      },
      complete: function () {
        c && c.call()
      }
    }))  : (e.applyTransition(), b = Math.ceil(b), e.options.vertical === !1 ? d[e.animType] = 'translate3d(' + b + 'px, 0px, 0px)' : d[e.animType] = 'translate3d(0px,' + b + 'px, 0px)', e.$slideTrack.css(d), c && setTimeout(function () {
      e.disableTransition(),
      c.call()
    }, e.options.speed))
  },
  b.prototype.getNavTarget = function () {
    var b = this,
    c = b.options.asNavFor;
    return c && null !== c && (c = a(c).not(b.$slider)),
    c
  },
  b.prototype.asNavFor = function (b) {
    var c = this,
    d = c.getNavTarget();
    null !== d && 'object' == typeof d && d.each(function () {
      var c = a(this).slick('getSlick');
      c.unslicked || c.slideHandler(b, !0)
    })
  },
  b.prototype.applyTransition = function (a) {
    var b = this,
    c = {
    };
    b.options.fade === !1 ? c[b.transitionType] = b.transformType + ' ' + b.options.speed + 'ms ' + b.options.cssEase : c[b.transitionType] = 'opacity ' + b.options.speed + 'ms ' + b.options.cssEase,
    b.options.fade === !1 ? b.$slideTrack.css(c)  : b.$slides.eq(a).css(c)
  },
  b.prototype.autoPlay = function () {
    var a = this;
    a.autoPlayClear(),
    a.slideCount > a.options.slidesToShow && (a.autoPlayTimer = setInterval(a.autoPlayIterator, a.options.autoplaySpeed))
  },
  b.prototype.autoPlayClear = function () {
    var a = this;
    a.autoPlayTimer && clearInterval(a.autoPlayTimer)
  },
  b.prototype.autoPlayIterator = function () {
    var a = this,
    b = a.currentSlide + a.options.slidesToScroll;
    a.paused || a.interrupted || a.focussed || (a.options.infinite === !1 && (1 === a.direction && a.currentSlide + 1 === a.slideCount - 1 ? a.direction = 0 : 0 === a.direction && (b = a.currentSlide - a.options.slidesToScroll, a.currentSlide - 1 === 0 && (a.direction = 1))), a.slideHandler(b))
  },
  b.prototype.buildArrows = function () {
    var b = this;
    b.options.arrows === !0 && (b.$prevArrow = a(b.options.prevArrow).addClass('slick-arrow'), b.$nextArrow = a(b.options.nextArrow).addClass('slick-arrow'), b.slideCount > b.options.slidesToShow ? (b.$prevArrow.removeClass('slick-hidden').removeAttr('aria-hidden tabindex'), b.$nextArrow.removeClass('slick-hidden').removeAttr('aria-hidden tabindex'), b.htmlExpr.test(b.options.prevArrow) && b.$prevArrow.prependTo(b.options.appendArrows), b.htmlExpr.test(b.options.nextArrow) && b.$nextArrow.appendTo(b.options.appendArrows), b.options.infinite !== !0 && b.$prevArrow.addClass('slick-disabled').attr('aria-disabled', 'true'))  : b.$prevArrow.add(b.$nextArrow).addClass('slick-hidden').attr({
      'aria-disabled': 'true',
      tabindex: '-1'
    }))
  },
  b.prototype.buildDots = function () {
    var b,
    c,
    d = this;
    if (d.options.dots === !0 && d.slideCount > d.options.slidesToShow) {
      for (d.$slider.addClass('slick-dotted'), c = a('<ul />').addClass(d.options.dotsClass), b = 0; b <= d.getDotCount(); b += 1) c.append(a('<li />').append(d.options.customPaging.call(this, d, b)));
      d.$dots = c.appendTo(d.options.appendDots),
      d.$dots.find('li').first().addClass('slick-active').attr('aria-hidden', 'false')
    }
  },
  b.prototype.buildOut = function () {
    var b = this;
    b.$slides = b.$slider.children(b.options.slide + ':not(.slick-cloned)').addClass('slick-slide'),
    b.slideCount = b.$slides.length,
    b.$slides.each(function (b, c) {
      a(c).attr('data-slick-index', b).data('originalStyling', a(c).attr('style') || '')
    }),
    b.$slider.addClass('slick-slider'),
    b.$slideTrack = 0 === b.slideCount ? a('<div class="slick-track"/>').appendTo(b.$slider)  : b.$slides.wrapAll('<div class="slick-track"/>').parent(),
    b.$list = b.$slideTrack.wrap('<div aria-live="polite" class="slick-list"/>').parent(),
    b.$slideTrack.css('opacity', 0),
    (b.options.centerMode === !0 || b.options.swipeToSlide === !0) && (b.options.slidesToScroll = 1),
    a('img[data-lazy]', b.$slider).not('[src]').addClass('slick-loading'),
    b.setupInfinite(),
    b.buildArrows(),
    b.buildDots(),
    b.updateDots(),
    b.setSlideClasses('number' == typeof b.currentSlide ? b.currentSlide : 0),
    b.options.draggable === !0 && b.$list.addClass('draggable')
  },
  b.prototype.buildRows = function () {
    var a,
    b,
    c,
    d,
    e,
    f,
    g,
    h = this;
    if (d = document.createDocumentFragment(), f = h.$slider.children(), h.options.rows > 1) {
      for (g = h.options.slidesPerRow * h.options.rows, e = Math.ceil(f.length / g), a = 0; e > a; a++) {
        var i = document.createElement('div');
        for (b = 0; b < h.options.rows; b++) {
          var j = document.createElement('div');
          for (c = 0; c < h.options.slidesPerRow; c++) {
            var k = a * g + (b * h.options.slidesPerRow + c);
            f.get(k) && j.appendChild(f.get(k))
          }
          i.appendChild(j)
        }
        d.appendChild(i)
      }
      h.$slider.empty().append(d),
      h.$slider.children().children().children().css({
        width: 100 / h.options.slidesPerRow + '%',
        display: 'inline-block'
      })
    }
  },
  b.prototype.checkResponsive = function (b, c) {
    var d,
    e,
    f,
    g = this,
    h = !1,
    i = g.$slider.width(),
    j = window.innerWidth || a(window).width();
    if ('window' === g.respondTo ? f = j : 'slider' === g.respondTo ? f = i : 'min' === g.respondTo && (f = Math.min(j, i)), g.options.responsive && g.options.responsive.length && null !== g.options.responsive) {
      e = null;
      for (d in g.breakpoints) g.breakpoints.hasOwnProperty(d) && (g.originalSettings.mobileFirst === !1 ? f < g.breakpoints[d] && (e = g.breakpoints[d])  : f > g.breakpoints[d] && (e = g.breakpoints[d]));
      null !== e ? null !== g.activeBreakpoint ? (e !== g.activeBreakpoint || c) && (g.activeBreakpoint = e, 'unslick' === g.breakpointSettings[e] ? g.unslick(e)  : (g.options = a.extend({
      }, g.originalSettings, g.breakpointSettings[e]), b === !0 && (g.currentSlide = g.options.initialSlide), g.refresh(b)), h = e)  : (g.activeBreakpoint = e, 'unslick' === g.breakpointSettings[e] ? g.unslick(e)  : (g.options = a.extend({
      }, g.originalSettings, g.breakpointSettings[e]), b === !0 && (g.currentSlide = g.options.initialSlide), g.refresh(b)), h = e)  : null !== g.activeBreakpoint && (g.activeBreakpoint = null, g.options = g.originalSettings, b === !0 && (g.currentSlide = g.options.initialSlide), g.refresh(b), h = e),
      b || h === !1 || g.$slider.trigger('breakpoint', [
        g,
        h
      ])
    }
  },
  b.prototype.changeSlide = function (b, c) {
    var d,
    e,
    f,
    g = this,
    h = a(b.currentTarget);
    switch (h.is('a') && b.preventDefault(), h.is('li') || (h = h.closest('li')), f = g.slideCount % g.options.slidesToScroll !== 0, d = f ? 0 : (g.slideCount - g.currentSlide) % g.options.slidesToScroll, b.data.message) {
      case 'previous':
        e = 0 === d ? g.options.slidesToScroll : g.options.slidesToShow - d,
        g.slideCount > g.options.slidesToShow && g.slideHandler(g.currentSlide - e, !1, c);
        break;
      case 'next':
        e = 0 === d ? g.options.slidesToScroll : d,
        g.slideCount > g.options.slidesToShow && g.slideHandler(g.currentSlide + e, !1, c);
        break;
      case 'index':
        var i = 0 === b.data.index ? 0 : b.data.index || h.index() * g.options.slidesToScroll;
        g.slideHandler(g.checkNavigable(i), !1, c),
        h.children().trigger('focus');
        break;
      default:
        return
    }
  },
  b.prototype.checkNavigable = function (a) {
    var b,
    c,
    d = this;
    if (b = d.getNavigableIndexes(), c = 0, a > b[b.length - 1]) a = b[b.length - 1];
     else for (var e in b) {
      if (a < b[e]) {
        a = c;
        break
      }
      c = b[e]
    }
    return a
  },
  b.prototype.cleanUpEvents = function () {
    var b = this;
    b.options.dots && null !== b.$dots && a('li', b.$dots).off('click.slick', b.changeSlide).off('mouseenter.slick', a.proxy(b.interrupt, b, !0)).off('mouseleave.slick', a.proxy(b.interrupt, b, !1)),
    b.$slider.off('focus.slick blur.slick'),
    b.options.arrows === !0 && b.slideCount > b.options.slidesToShow && (b.$prevArrow && b.$prevArrow.off('click.slick', b.changeSlide), b.$nextArrow && b.$nextArrow.off('click.slick', b.changeSlide)),
    b.$list.off('touchstart.slick mousedown.slick', b.swipeHandler),
    b.$list.off('touchmove.slick mousemove.slick', b.swipeHandler),
    b.$list.off('touchend.slick mouseup.slick', b.swipeHandler),
    b.$list.off('touchcancel.slick mouseleave.slick', b.swipeHandler),
    b.$list.off('click.slick', b.clickHandler),
    a(document).off(b.visibilityChange, b.visibility),
    b.cleanUpSlideEvents(),
    b.options.accessibility === !0 && b.$list.off('keydown.slick', b.keyHandler),
    b.options.focusOnSelect === !0 && a(b.$slideTrack).children().off('click.slick', b.selectHandler),
    a(window).off('orientationchange.slick.slick-' + b.instanceUid, b.orientationChange),
    a(window).off('resize.slick.slick-' + b.instanceUid, b.resize),
    a('[draggable!=true]', b.$slideTrack).off('dragstart', b.preventDefault),
    a(window).off('load.slick.slick-' + b.instanceUid, b.setPosition),
    a(document).off('ready.slick.slick-' + b.instanceUid, b.setPosition)
  },
  b.prototype.cleanUpSlideEvents = function () {
    var b = this;
    b.$list.off('mouseenter.slick', a.proxy(b.interrupt, b, !0)),
    b.$list.off('mouseleave.slick', a.proxy(b.interrupt, b, !1))
  },
  b.prototype.cleanUpRows = function () {
    var a,
    b = this;
    b.options.rows > 1 && (a = b.$slides.children().children(), a.removeAttr('style'), b.$slider.empty().append(a))
  },
  b.prototype.clickHandler = function (a) {
    var b = this;
    b.shouldClick === !1 && (a.stopImmediatePropagation(), a.stopPropagation(), a.preventDefault())
  },
  b.prototype.destroy = function (b) {
    var c = this;
    c.autoPlayClear(),
    c.touchObject = {
    },
    c.cleanUpEvents(),
    a('.slick-cloned', c.$slider).detach(),
    c.$dots && c.$dots.remove(),
    c.$prevArrow && c.$prevArrow.length && (c.$prevArrow.removeClass('slick-disabled slick-arrow slick-hidden').removeAttr('aria-hidden aria-disabled tabindex').css('display', ''), c.htmlExpr.test(c.options.prevArrow) && c.$prevArrow.remove()),
    c.$nextArrow && c.$nextArrow.length && (c.$nextArrow.removeClass('slick-disabled slick-arrow slick-hidden').removeAttr('aria-hidden aria-disabled tabindex').css('display', ''), c.htmlExpr.test(c.options.nextArrow) && c.$nextArrow.remove()),
    c.$slides && (c.$slides.removeClass('slick-slide slick-active slick-center slick-visible slick-current').removeAttr('aria-hidden').removeAttr('data-slick-index').each(function () {
      a(this).attr('style', a(this).data('originalStyling'))
    }), c.$slideTrack.children(this.options.slide).detach(), c.$slideTrack.detach(), c.$list.detach(), c.$slider.append(c.$slides)),
    c.cleanUpRows(),
    c.$slider.removeClass('slick-slider'),
    c.$slider.removeClass('slick-initialized'),
    c.$slider.removeClass('slick-dotted'),
    c.unslicked = !0,
    b || c.$slider.trigger('destroy', [
      c
    ])
  },
  b.prototype.disableTransition = function (a) {
    var b = this,
    c = {
    };
    c[b.transitionType] = '',
    b.options.fade === !1 ? b.$slideTrack.css(c)  : b.$slides.eq(a).css(c)
  },
  b.prototype.fadeSlide = function (a, b) {
    var c = this;
    c.cssTransitions === !1 ? (c.$slides.eq(a).css({
      zIndex: c.options.zIndex
    }), c.$slides.eq(a).animate({
      opacity: 1
    }, c.options.speed, c.options.easing, b))  : (c.applyTransition(a), c.$slides.eq(a).css({
      opacity: 1,
      zIndex: c.options.zIndex
    }), b && setTimeout(function () {
      c.disableTransition(a),
      b.call()
    }, c.options.speed))
  },
  b.prototype.fadeSlideOut = function (a) {
    var b = this;
    b.cssTransitions === !1 ? b.$slides.eq(a).animate({
      opacity: 0,
      zIndex: b.options.zIndex - 2
    }, b.options.speed, b.options.easing)  : (b.applyTransition(a), b.$slides.eq(a).css({
      opacity: 0,
      zIndex: b.options.zIndex - 2
    }))
  },
  b.prototype.filterSlides = b.prototype.slickFilter = function (a) {
    var b = this;
    null !== a && (b.$slidesCache = b.$slides, b.unload(), b.$slideTrack.children(this.options.slide).detach(), b.$slidesCache.filter(a).appendTo(b.$slideTrack), b.reinit())
  },
  b.prototype.focusHandler = function () {
    var b = this;
    b.$slider.off('focus.slick blur.slick').on('focus.slick blur.slick', '*:not(.slick-arrow)', function (c) {
      c.stopImmediatePropagation();
      var d = a(this);
      setTimeout(function () {
        b.options.pauseOnFocus && (b.focussed = d.is(':focus'), b.autoPlay())
      }, 0)
    })
  },
  b.prototype.getCurrent = b.prototype.slickCurrentSlide = function () {
    var a = this;
    return a.currentSlide
  },
  b.prototype.getDotCount = function () {
    var a = this,
    b = 0,
    c = 0,
    d = 0;
    if (a.options.infinite === !0) for (; b < a.slideCount; ) ++d,
    b = c + a.options.slidesToScroll,
    c += a.options.slidesToScroll <= a.options.slidesToShow ? a.options.slidesToScroll : a.options.slidesToShow;
     else if (a.options.centerMode === !0) d = a.slideCount;
     else if (a.options.asNavFor) for (; b < a.slideCount; ) ++d,
    b = c + a.options.slidesToScroll,
    c += a.options.slidesToScroll <= a.options.slidesToShow ? a.options.slidesToScroll : a.options.slidesToShow;
     else d = 1 + Math.ceil((a.slideCount - a.options.slidesToShow) / a.options.slidesToScroll);
    return d - 1
  },
  b.prototype.getLeft = function (a) {
    var b,
    c,
    d,
    e = this,
    f = 0;
    return e.slideOffset = 0,
    c = e.$slides.first().outerHeight(!0),
    e.options.infinite === !0 ? (e.slideCount > e.options.slidesToShow && (e.slideOffset = e.slideWidth * e.options.slidesToShow * - 1, f = c * e.options.slidesToShow * - 1), e.slideCount % e.options.slidesToScroll !== 0 && a + e.options.slidesToScroll > e.slideCount && e.slideCount > e.options.slidesToShow && (a > e.slideCount ? (e.slideOffset = (e.options.slidesToShow - (a - e.slideCount)) * e.slideWidth * - 1, f = (e.options.slidesToShow - (a - e.slideCount)) * c * - 1)  : (e.slideOffset = e.slideCount % e.options.slidesToScroll * e.slideWidth * - 1, f = e.slideCount % e.options.slidesToScroll * c * - 1)))  : a + e.options.slidesToShow > e.slideCount && (e.slideOffset = (a + e.options.slidesToShow - e.slideCount) * e.slideWidth, f = (a + e.options.slidesToShow - e.slideCount) * c),
    e.slideCount <= e.options.slidesToShow && (e.slideOffset = 0, f = 0),
    e.options.centerMode === !0 && e.options.infinite === !0 ? e.slideOffset += e.slideWidth * Math.floor(e.options.slidesToShow / 2) - e.slideWidth : e.options.centerMode === !0 && (e.slideOffset = 0, e.slideOffset += e.slideWidth * Math.floor(e.options.slidesToShow / 2)),
    b = e.options.vertical === !1 ? a * e.slideWidth * - 1 + e.slideOffset : a * c * - 1 + f,
    e.options.variableWidth === !0 && (d = e.slideCount <= e.options.slidesToShow || e.options.infinite === !1 ? e.$slideTrack.children('.slick-slide').eq(a)  : e.$slideTrack.children('.slick-slide').eq(a + e.options.slidesToShow), b = e.options.rtl === !0 ? d[0] ? - 1 * (e.$slideTrack.width() - d[0].offsetLeft - d.width())  : 0 : d[0] ? - 1 * d[0].offsetLeft : 0, e.options.centerMode === !0 && (d = e.slideCount <= e.options.slidesToShow || e.options.infinite === !1 ? e.$slideTrack.children('.slick-slide').eq(a)  : e.$slideTrack.children('.slick-slide').eq(a + e.options.slidesToShow + 1), b = e.options.rtl === !0 ? d[0] ? - 1 * (e.$slideTrack.width() - d[0].offsetLeft - d.width())  : 0 : d[0] ? - 1 * d[0].offsetLeft : 0, b += (e.$list.width() - d.outerWidth()) / 2)),
    b
  },
  b.prototype.getOption = b.prototype.slickGetOption = function (a) {
    var b = this;
    return b.options[a]
  },
  b.prototype.getNavigableIndexes = function () {
    var a,
    b = this,
    c = 0,
    d = 0,
    e = [
    ];
    for (b.options.infinite === !1 ? a = b.slideCount : (c = - 1 * b.options.slidesToScroll, d = - 1 * b.options.slidesToScroll, a = 2 * b.slideCount); a > c; ) e.push(c),
    c = d + b.options.slidesToScroll,
    d += b.options.slidesToScroll <= b.options.slidesToShow ? b.options.slidesToScroll : b.options.slidesToShow;
    return e
  },
  b.prototype.getSlick = function () {
    return this
  },
  b.prototype.getSlideCount = function () {
    var b,
    c,
    d,
    e = this;
    return d = e.options.centerMode === !0 ? e.slideWidth * Math.floor(e.options.slidesToShow / 2)  : 0,
    e.options.swipeToSlide === !0 ? (e.$slideTrack.find('.slick-slide').each(function (b, f) {
      return f.offsetLeft - d + a(f).outerWidth() / 2 > - 1 * e.swipeLeft ? (c = f, !1)  : void 0
    }), b = Math.abs(a(c).attr('data-slick-index') - e.currentSlide) || 1)  : e.options.slidesToScroll
  },
  b.prototype.goTo = b.prototype.slickGoTo = function (a, b) {
    var c = this;
    c.changeSlide({
      data: {
        message: 'index',
        index: parseInt(a)
      }
    }, b)
  },
  b.prototype.init = function (b) {
    var c = this;
    a(c.$slider).hasClass('slick-initialized') || (a(c.$slider).addClass('slick-initialized'), c.buildRows(), c.buildOut(), c.setProps(), c.startLoad(), c.loadSlider(), c.initializeEvents(), c.updateArrows(), c.updateDots(), c.checkResponsive(!0), c.focusHandler()),
    b && c.$slider.trigger('init', [
      c
    ]),
    c.options.accessibility === !0 && c.initADA(),
    c.options.autoplay && (c.paused = !1, c.autoPlay())
  },
  b.prototype.initADA = function () {
    var b = this;
    b.$slides.add(b.$slideTrack.find('.slick-cloned')).attr({
      'aria-hidden': 'true',
      tabindex: '-1'
    }).find('a, input, button, select').attr({
      tabindex: '-1'
    }),
    b.$slideTrack.attr('role', 'listbox'),
    b.$slides.not(b.$slideTrack.find('.slick-cloned')).each(function (c) {
      a(this).attr({
        role: 'option',
        'aria-describedby': 'slick-slide' + b.instanceUid + c
      })
    }),
    null !== b.$dots && b.$dots.attr('role', 'tablist').find('li').each(function (c) {
      a(this).attr({
        role: 'presentation',
        'aria-selected': 'false',
        'aria-controls': 'navigation' + b.instanceUid + c,
        id: 'slick-slide' + b.instanceUid + c
      })
    }).first().attr('aria-selected', 'true').end().find('button').attr('role', 'button').end().closest('div').attr('role', 'toolbar'),
    b.activateADA()
  },
  b.prototype.initArrowEvents = function () {
    var a = this;
    a.options.arrows === !0 && a.slideCount > a.options.slidesToShow && (a.$prevArrow.off('click.slick').on('click.slick', {
      message: 'previous'
    }, a.changeSlide), a.$nextArrow.off('click.slick').on('click.slick', {
      message: 'next'
    }, a.changeSlide))
  },
  b.prototype.initDotEvents = function () {
    var b = this;
    b.options.dots === !0 && b.slideCount > b.options.slidesToShow && a('li', b.$dots).on('click.slick', {
      message: 'index'
    }, b.changeSlide),
    b.options.dots === !0 && b.options.pauseOnDotsHover === !0 && a('li', b.$dots).on('mouseenter.slick', a.proxy(b.interrupt, b, !0)).on('mouseleave.slick', a.proxy(b.interrupt, b, !1))
  },
  b.prototype.initSlideEvents = function () {
    var b = this;
    b.options.pauseOnHover && (b.$list.on('mouseenter.slick', a.proxy(b.interrupt, b, !0)), b.$list.on('mouseleave.slick', a.proxy(b.interrupt, b, !1)))
  },
  b.prototype.initializeEvents = function () {
    var b = this;
    b.initArrowEvents(),
    b.initDotEvents(),
    b.initSlideEvents(),
    b.$list.on('touchstart.slick mousedown.slick', {
      action: 'start'
    }, b.swipeHandler),
    b.$list.on('touchmove.slick mousemove.slick', {
      action: 'move'
    }, b.swipeHandler),
    b.$list.on('touchend.slick mouseup.slick', {
      action: 'end'
    }, b.swipeHandler),
    b.$list.on('touchcancel.slick mouseleave.slick', {
      action: 'end'
    }, b.swipeHandler),
    b.$list.on('click.slick', b.clickHandler),
    a(document).on(b.visibilityChange, a.proxy(b.visibility, b)),
    b.options.accessibility === !0 && b.$list.on('keydown.slick', b.keyHandler),
    b.options.focusOnSelect === !0 && a(b.$slideTrack).children().on('click.slick', b.selectHandler),
    a(window).on('orientationchange.slick.slick-' + b.instanceUid, a.proxy(b.orientationChange, b)),
    a(window).on('resize.slick.slick-' + b.instanceUid, a.proxy(b.resize, b)),
    a('[draggable!=true]', b.$slideTrack).on('dragstart', b.preventDefault),
    a(window).on('load.slick.slick-' + b.instanceUid, b.setPosition),
    a(document).on('ready.slick.slick-' + b.instanceUid, b.setPosition)
  },
  b.prototype.initUI = function () {
    var a = this;
    a.options.arrows === !0 && a.slideCount > a.options.slidesToShow && (a.$prevArrow.show(), a.$nextArrow.show()),
    a.options.dots === !0 && a.slideCount > a.options.slidesToShow && a.$dots.show()
  },
  b.prototype.keyHandler = function (a) {
    var b = this;
    a.target.tagName.match('TEXTAREA|INPUT|SELECT') || (37 === a.keyCode && b.options.accessibility === !0 ? b.changeSlide({
      data: {
        message: b.options.rtl === !0 ? 'next' : 'previous'
      }
    })  : 39 === a.keyCode && b.options.accessibility === !0 && b.changeSlide({
      data: {
        message: b.options.rtl === !0 ? 'previous' : 'next'
      }
    }))
  },
  b.prototype.lazyLoad = function () {
    function b(b) {
      a('img[data-lazy]', b).each(function () {
        var b = a(this),
        c = a(this).attr('data-lazy'),
        d = document.createElement('img');
        d.onload = function () {
          b.animate({
            opacity: 0
          }, 100, function () {
            b.attr('src', c).animate({
              opacity: 1
            }, 200, function () {
              b.removeAttr('data-lazy').removeClass('slick-loading')
            }),
            g.$slider.trigger('lazyLoaded', [
              g,
              b,
              c
            ])
          })
        },
        d.onerror = function () {
          b.removeAttr('data-lazy').removeClass('slick-loading').addClass('slick-lazyload-error'),
          g.$slider.trigger('lazyLoadError', [
            g,
            b,
            c
          ])
        },
        d.src = c
      })
    }
    var c,
    d,
    e,
    f,
    g = this;
    g.options.centerMode === !0 ? g.options.infinite === !0 ? (e = g.currentSlide + (g.options.slidesToShow / 2 + 1), f = e + g.options.slidesToShow + 2)  : (e = Math.max(0, g.currentSlide - (g.options.slidesToShow / 2 + 1)), f = 2 + (g.options.slidesToShow / 2 + 1) + g.currentSlide)  : (e = g.options.infinite ? g.options.slidesToShow + g.currentSlide : g.currentSlide, f = Math.ceil(e + g.options.slidesToShow), g.options.fade === !0 && (e > 0 && e--, f <= g.slideCount && f++)),
    c = g.$slider.find('.slick-slide').slice(e, f),
    b(c),
    g.slideCount <= g.options.slidesToShow ? (d = g.$slider.find('.slick-slide'), b(d))  : g.currentSlide >= g.slideCount - g.options.slidesToShow ? (d = g.$slider.find('.slick-cloned').slice(0, g.options.slidesToShow), b(d))  : 0 === g.currentSlide && (d = g.$slider.find('.slick-cloned').slice( - 1 * g.options.slidesToShow), b(d))
  },
  b.prototype.loadSlider = function () {
    var a = this;
    a.setPosition(),
    a.$slideTrack.css({
      opacity: 1
    }),
    a.$slider.removeClass('slick-loading'),
    a.initUI(),
    'progressive' === a.options.lazyLoad && a.progressiveLazyLoad()
  },
  b.prototype.next = b.prototype.slickNext = function () {
    var a = this;
    a.changeSlide({
      data: {
        message: 'next'
      }
    })
  },
  b.prototype.orientationChange = function () {
    var a = this;
    a.checkResponsive(),
    a.setPosition()
  },
  b.prototype.pause = b.prototype.slickPause = function () {
    var a = this;
    a.autoPlayClear(),
    a.paused = !0
  },
  b.prototype.play = b.prototype.slickPlay = function () {
    var a = this;
    a.autoPlay(),
    a.options.autoplay = !0,
    a.paused = !1,
    a.focussed = !1,
    a.interrupted = !1
  },
  b.prototype.postSlide = function (a) {
    var b = this;
    b.unslicked || (b.$slider.trigger('afterChange', [
      b,
      a
    ]), b.animating = !1, b.setPosition(), b.swipeLeft = null, b.options.autoplay && b.autoPlay(), b.options.accessibility === !0 && b.initADA())
  },
  b.prototype.prev = b.prototype.slickPrev = function () {
    var a = this;
    a.changeSlide({
      data: {
        message: 'previous'
      }
    })
  },
  b.prototype.preventDefault = function (a) {
    a.preventDefault()
  },
  b.prototype.progressiveLazyLoad = function (b) {
    b = b || 1;
    var c,
    d,
    e,
    f = this,
    g = a('img[data-lazy]', f.$slider);
    g.length ? (c = g.first(), d = c.attr('data-lazy'), e = document.createElement('img'), e.onload = function () {
      c.attr('src', d).removeAttr('data-lazy').removeClass('slick-loading'),
      f.options.adaptiveHeight === !0 && f.setPosition(),
      f.$slider.trigger('lazyLoaded', [
        f,
        c,
        d
      ]),
      f.progressiveLazyLoad()
    }, e.onerror = function () {
      3 > b ? setTimeout(function () {
        f.progressiveLazyLoad(b + 1)
      }, 500)  : (c.removeAttr('data-lazy').removeClass('slick-loading').addClass('slick-lazyload-error'), f.$slider.trigger('lazyLoadError', [
        f,
        c,
        d
      ]), f.progressiveLazyLoad())
    }, e.src = d)  : f.$slider.trigger('allImagesLoaded', [
      f
    ])
  },
  b.prototype.refresh = function (b) {
    var c,
    d,
    e = this;
    d = e.slideCount - e.options.slidesToShow,
    !e.options.infinite && e.currentSlide > d && (e.currentSlide = d),
    e.slideCount <= e.options.slidesToShow && (e.currentSlide = 0),
    c = e.currentSlide,
    e.destroy(!0),
    a.extend(e, e.initials, {
      currentSlide: c
    }),
    e.init(),
    b || e.changeSlide({
      data: {
        message: 'index',
        index: c
      }
    }, !1)
  },
  b.prototype.registerBreakpoints = function () {
    var b,
    c,
    d,
    e = this,
    f = e.options.responsive || null;
    if ('array' === a.type(f) && f.length) {
      e.respondTo = e.options.respondTo || 'window';
      for (b in f) if (d = e.breakpoints.length - 1, c = f[b].breakpoint, f.hasOwnProperty(b)) {
        for (; d >= 0; ) e.breakpoints[d] && e.breakpoints[d] === c && e.breakpoints.splice(d, 1),
        d--;
        e.breakpoints.push(c),
        e.breakpointSettings[c] = f[b].settings
      }
      e.breakpoints.sort(function (a, b) {
        return e.options.mobileFirst ? a - b : b - a
      })
    }
  },
  b.prototype.reinit = function () {
    var b = this;
    b.$slides = b.$slideTrack.children(b.options.slide).addClass('slick-slide'),
    b.slideCount = b.$slides.length,
    b.currentSlide >= b.slideCount && 0 !== b.currentSlide && (b.currentSlide = b.currentSlide - b.options.slidesToScroll),
    b.slideCount <= b.options.slidesToShow && (b.currentSlide = 0),
    b.registerBreakpoints(),
    b.setProps(),
    b.setupInfinite(),
    b.buildArrows(),
    b.updateArrows(),
    b.initArrowEvents(),
    b.buildDots(),
    b.updateDots(),
    b.initDotEvents(),
    b.cleanUpSlideEvents(),
    b.initSlideEvents(),
    b.checkResponsive(!1, !0),
    b.options.focusOnSelect === !0 && a(b.$slideTrack).children().on('click.slick', b.selectHandler),
    b.setSlideClasses('number' == typeof b.currentSlide ? b.currentSlide : 0),
    b.setPosition(),
    b.focusHandler(),
    b.paused = !b.options.autoplay,
    b.autoPlay(),
    b.$slider.trigger('reInit', [
      b
    ])
  },
  b.prototype.resize = function () {
    var b = this;
    a(window).width() !== b.windowWidth && (clearTimeout(b.windowDelay), b.windowDelay = window.setTimeout(function () {
      b.windowWidth = a(window).width(),
      b.checkResponsive(),
      b.unslicked || b.setPosition()
    }, 50))
  },
  b.prototype.removeSlide = b.prototype.slickRemove = function (a, b, c) {
    var d = this;
    return 'boolean' == typeof a ? (b = a, a = b === !0 ? 0 : d.slideCount - 1)  : a = b === !0 ? --a : a,
    !(d.slideCount < 1 || 0 > a || a > d.slideCount - 1) && (d.unload(), c === !0 ? d.$slideTrack.children().remove()  : d.$slideTrack.children(this.options.slide).eq(a).remove(), d.$slides = d.$slideTrack.children(this.options.slide), d.$slideTrack.children(this.options.slide).detach(), d.$slideTrack.append(d.$slides), d.$slidesCache = d.$slides, void d.reinit())
  },
  b.prototype.setCSS = function (a) {
    var b,
    c,
    d = this,
    e = {
    };
    d.options.rtl === !0 && (a = - a),
    b = 'left' == d.positionProp ? Math.ceil(a) + 'px' : '0px',
    c = 'top' == d.positionProp ? Math.ceil(a) + 'px' : '0px',
    e[d.positionProp] = a,
    d.transformsEnabled === !1 ? d.$slideTrack.css(e)  : (e = {
    }, d.cssTransitions === !1 ? (e[d.animType] = 'translate(' + b + ', ' + c + ')', d.$slideTrack.css(e))  : (e[d.animType] = 'translate3d(' + b + ', ' + c + ', 0px)', d.$slideTrack.css(e)))
  },
  b.prototype.setDimensions = function () {
    var a = this;
    a.options.vertical === !1 ? a.options.centerMode === !0 && a.$list.css({
      padding: '0px ' + a.options.centerPadding
    })  : (a.$list.height(a.$slides.first().outerHeight(!0) * a.options.slidesToShow), a.options.centerMode === !0 && a.$list.css({
      padding: a.options.centerPadding + ' 0px'
    })),
    a.listWidth = a.$list.width(),
    a.listHeight = a.$list.height(),
    a.options.vertical === !1 && a.options.variableWidth === !1 ? (a.slideWidth = Math.ceil(a.listWidth / a.options.slidesToShow), a.$slideTrack.width(Math.ceil(a.slideWidth * a.$slideTrack.children('.slick-slide').length)))  : a.options.variableWidth === !0 ? a.$slideTrack.width(5000 * a.slideCount)  : (a.slideWidth = Math.ceil(a.listWidth), a.$slideTrack.height(Math.ceil(a.$slides.first().outerHeight(!0) * a.$slideTrack.children('.slick-slide').length)));
    var b = a.$slides.first().outerWidth(!0) - a.$slides.first().width();
    a.options.variableWidth === !1 && a.$slideTrack.children('.slick-slide').width(a.slideWidth - b)
  },
  b.prototype.setFade = function () {
    var b,
    c = this;
    c.$slides.each(function (d, e) {
      b = c.slideWidth * d * - 1,
      c.options.rtl === !0 ? a(e).css({
        position: 'relative',
        right: b,
        top: 0,
        zIndex: c.options.zIndex - 2,
        opacity: 0
      })  : a(e).css({
        position: 'relative',
        left: b,
        top: 0,
        zIndex: c.options.zIndex - 2,
        opacity: 0
      })
    }),
    c.$slides.eq(c.currentSlide).css({
      zIndex: c.options.zIndex - 1,
      opacity: 1
    })
  },
  b.prototype.setHeight = function () {
    var a = this;
    if (1 === a.options.slidesToShow && a.options.adaptiveHeight === !0 && a.options.vertical === !1) {
      var b = a.$slides.eq(a.currentSlide).outerHeight(!0);
      a.$list.css('height', b)
    }
  },
  b.prototype.setOption = b.prototype.slickSetOption = function () {
    var b,
    c,
    d,
    e,
    f,
    g = this,
    h = !1;
    if ('object' === a.type(arguments[0]) ? (d = arguments[0], h = arguments[1], f = 'multiple')  : 'string' === a.type(arguments[0]) && (d = arguments[0], e = arguments[1], h = arguments[2], 'responsive' === arguments[0] && 'array' === a.type(arguments[1]) ? f = 'responsive' : 'undefined' != typeof arguments[1] && (f = 'single')), 'single' === f) g.options[d] = e;
     else if ('multiple' === f) a.each(d, function (a, b) {
      g.options[a] = b
    });
     else if ('responsive' === f) for (c in e) if ('array' !== a.type(g.options.responsive)) g.options.responsive = [
      e[c]
    ];
     else {
      for (b = g.options.responsive.length - 1; b >= 0; ) g.options.responsive[b].breakpoint === e[c].breakpoint && g.options.responsive.splice(b, 1),
      b--;
      g.options.responsive.push(e[c])
    }
    h && (g.unload(), g.reinit())
  },
  b.prototype.setPosition = function () {
    var a = this;
    a.setDimensions(),
    a.setHeight(),
    a.options.fade === !1 ? a.setCSS(a.getLeft(a.currentSlide))  : a.setFade(),
    a.$slider.trigger('setPosition', [
      a
    ])
  },
  b.prototype.setProps = function () {
    var a = this,
    b = document.body.style;
    a.positionProp = a.options.vertical === !0 ? 'top' : 'left',
    'top' === a.positionProp ? a.$slider.addClass('slick-vertical')  : a.$slider.removeClass('slick-vertical'),
    (void 0 !== b.WebkitTransition || void 0 !== b.MozTransition || void 0 !== b.msTransition) && a.options.useCSS === !0 && (a.cssTransitions = !0),
    a.options.fade && ('number' == typeof a.options.zIndex ? a.options.zIndex < 3 && (a.options.zIndex = 3)  : a.options.zIndex = a.defaults.zIndex),
    void 0 !== b.OTransform && (a.animType = 'OTransform', a.transformType = '-o-transform', a.transitionType = 'OTransition', void 0 === b.perspectiveProperty && void 0 === b.webkitPerspective && (a.animType = !1)),
    void 0 !== b.MozTransform && (a.animType = 'MozTransform', a.transformType = '-moz-transform', a.transitionType = 'MozTransition', void 0 === b.perspectiveProperty && void 0 === b.MozPerspective && (a.animType = !1)),
    void 0 !== b.webkitTransform && (a.animType = 'webkitTransform', a.transformType = '-webkit-transform', a.transitionType = 'webkitTransition', void 0 === b.perspectiveProperty && void 0 === b.webkitPerspective && (a.animType = !1)),
    void 0 !== b.msTransform && (a.animType = 'msTransform', a.transformType = '-ms-transform', a.transitionType = 'msTransition', void 0 === b.msTransform && (a.animType = !1)),
    void 0 !== b.transform && a.animType !== !1 && (a.animType = 'transform', a.transformType = 'transform', a.transitionType = 'transition'),
    a.transformsEnabled = a.options.useTransform && null !== a.animType && a.animType !== !1
  },
  b.prototype.setSlideClasses = function (a) {
    var b,
    c,
    d,
    e,
    f = this;
    c = f.$slider.find('.slick-slide').removeClass('slick-active slick-center slick-current').attr('aria-hidden', 'true'),
    f.$slides.eq(a).addClass('slick-current'),
    f.options.centerMode === !0 ? (b = Math.floor(f.options.slidesToShow / 2), f.options.infinite === !0 && (a >= b && a <= f.slideCount - 1 - b ? f.$slides.slice(a - b, a + b + 1).addClass('slick-active').attr('aria-hidden', 'false')  : (d = f.options.slidesToShow + a, c.slice(d - b + 1, d + b + 2).addClass('slick-active').attr('aria-hidden', 'false')), 0 === a ? c.eq(c.length - 1 - f.options.slidesToShow).addClass('slick-center')  : a === f.slideCount - 1 && c.eq(f.options.slidesToShow).addClass('slick-center')), f.$slides.eq(a).addClass('slick-center'))  : a >= 0 && a <= f.slideCount - f.options.slidesToShow ? f.$slides.slice(a, a + f.options.slidesToShow).addClass('slick-active').attr('aria-hidden', 'false')  : c.length <= f.options.slidesToShow ? c.addClass('slick-active').attr('aria-hidden', 'false')  : (e = f.slideCount % f.options.slidesToShow, d = f.options.infinite === !0 ? f.options.slidesToShow + a : a, f.options.slidesToShow == f.options.slidesToScroll && f.slideCount - a < f.options.slidesToShow ? c.slice(d - (f.options.slidesToShow - e), d + e).addClass('slick-active').attr('aria-hidden', 'false')  : c.slice(d, d + f.options.slidesToShow).addClass('slick-active').attr('aria-hidden', 'false')),
    'ondemand' === f.options.lazyLoad && f.lazyLoad()
  },
  b.prototype.setupInfinite = function () {
    var b,
    c,
    d,
    e = this;
    if (e.options.fade === !0 && (e.options.centerMode = !1), e.options.infinite === !0 && e.options.fade === !1 && (c = null, e.slideCount > e.options.slidesToShow)) {
      for (d = e.options.centerMode === !0 ? e.options.slidesToShow + 1 : e.options.slidesToShow, b = e.slideCount; b > e.slideCount - d; b -= 1) c = b - 1,
      a(e.$slides[c]).clone(!0).attr('id', '').attr('data-slick-index', c - e.slideCount).prependTo(e.$slideTrack).addClass('slick-cloned');
      for (b = 0; d > b; b += 1) c = b,
      a(e.$slides[c]).clone(!0).attr('id', '').attr('data-slick-index', c + e.slideCount).appendTo(e.$slideTrack).addClass('slick-cloned');
      e.$slideTrack.find('.slick-cloned').find('[id]').each(function () {
        a(this).attr('id', '')
      })
    }
  },
  b.prototype.interrupt = function (a) {
    var b = this;
    a || b.autoPlay(),
    b.interrupted = a
  },
  b.prototype.selectHandler = function (b) {
    var c = this,
    d = a(b.target).is('.slick-slide') ? a(b.target)  : a(b.target).parents('.slick-slide'),
    e = parseInt(d.attr('data-slick-index'));
    return e || (e = 0),
    c.slideCount <= c.options.slidesToShow ? (c.setSlideClasses(e), void c.asNavFor(e))  : void c.slideHandler(e)
  },
  b.prototype.slideHandler = function (a, b, c) {
    var d,
    e,
    f,
    g,
    h,
    i = null,
    j = this;
    return b = b || !1,
    j.animating === !0 && j.options.waitForAnimate === !0 || j.options.fade === !0 && j.currentSlide === a || j.slideCount <= j.options.slidesToShow ? void 0 : (b === !1 && j.asNavFor(a), d = a, i = j.getLeft(d), g = j.getLeft(j.currentSlide), j.currentLeft = null === j.swipeLeft ? g : j.swipeLeft, j.options.infinite === !1 && j.options.centerMode === !1 && (0 > a || a > j.getDotCount() * j.options.slidesToScroll) ? void (j.options.fade === !1 && (d = j.currentSlide, c !== !0 ? j.animateSlide(g, function () {
      j.postSlide(d)
    })  : j.postSlide(d)))  : j.options.infinite === !1 && j.options.centerMode === !0 && (0 > a || a > j.slideCount - j.options.slidesToScroll) ? void (j.options.fade === !1 && (d = j.currentSlide, c !== !0 ? j.animateSlide(g, function () {
      j.postSlide(d)
    })  : j.postSlide(d)))  : (j.options.autoplay && clearInterval(j.autoPlayTimer), e = 0 > d ? j.slideCount % j.options.slidesToScroll !== 0 ? j.slideCount - j.slideCount % j.options.slidesToScroll : j.slideCount + d : d >= j.slideCount ? j.slideCount % j.options.slidesToScroll !== 0 ? 0 : d - j.slideCount : d, j.animating = !0, j.$slider.trigger('beforeChange', [
      j,
      j.currentSlide,
      e
    ]), f = j.currentSlide, j.currentSlide = e, j.setSlideClasses(j.currentSlide), j.options.asNavFor && (h = j.getNavTarget(), h = h.slick('getSlick'), h.slideCount <= h.options.slidesToShow && h.setSlideClasses(j.currentSlide)), j.updateDots(), j.updateArrows(), j.options.fade === !0 ? (c !== !0 ? (j.fadeSlideOut(f), j.fadeSlide(e, function () {
      j.postSlide(e)
    }))  : j.postSlide(e), void j.animateHeight())  : void (c !== !0 ? j.animateSlide(i, function () {
      j.postSlide(e)
    })  : j.postSlide(e))))
  },
  b.prototype.startLoad = function () {
    var a = this;
    a.options.arrows === !0 && a.slideCount > a.options.slidesToShow && (a.$prevArrow.hide(), a.$nextArrow.hide()),
    a.options.dots === !0 && a.slideCount > a.options.slidesToShow && a.$dots.hide(),
    a.$slider.addClass('slick-loading')
  },
  b.prototype.swipeDirection = function () {
    var a,
    b,
    c,
    d,
    e = this;
    return a = e.touchObject.startX - e.touchObject.curX,
    b = e.touchObject.startY - e.touchObject.curY,
    c = Math.atan2(b, a),
    d = Math.round(180 * c / Math.PI),
    0 > d && (d = 360 - Math.abs(d)),
    45 >= d && d >= 0 ? e.options.rtl === !1 ? 'left' : 'right' : 360 >= d && d >= 315 ? e.options.rtl === !1 ? 'left' : 'right' : d >= 135 && 225 >= d ? e.options.rtl === !1 ? 'right' : 'left' : e.options.verticalSwiping === !0 ? d >= 35 && 135 >= d ? 'down' : 'up' : 'vertical'
  },
  b.prototype.swipeEnd = function (a) {
    var b,
    c,
    d = this;
    if (d.dragging = !1, d.interrupted = !1, d.shouldClick = !(d.touchObject.swipeLength > 10), void 0 === d.touchObject.curX) return !1;
    if (d.touchObject.edgeHit === !0 && d.$slider.trigger('edge', [
      d,
      d.swipeDirection()
    ]), d.touchObject.swipeLength >= d.touchObject.minSwipe) {
      switch (c = d.swipeDirection()) {
        case 'left':
        case 'down':
          b = d.options.swipeToSlide ? d.checkNavigable(d.currentSlide + d.getSlideCount())  : d.currentSlide + d.getSlideCount(),
          d.currentDirection = 0;
          break;
        case 'right':
        case 'up':
          b = d.options.swipeToSlide ? d.checkNavigable(d.currentSlide - d.getSlideCount())  : d.currentSlide - d.getSlideCount(),
          d.currentDirection = 1
      }
      'vertical' != c && (d.slideHandler(b), d.touchObject = {
      }, d.$slider.trigger('swipe', [
        d,
        c
      ]))
    } else d.touchObject.startX !== d.touchObject.curX && (d.slideHandler(d.currentSlide), d.touchObject = {
    })
  },
  b.prototype.swipeHandler = function (a) {
    var b = this;
    if (!(b.options.swipe === !1 || 'ontouchend' in document && b.options.swipe === !1 || b.options.draggable === !1 && - 1 !== a.type.indexOf('mouse'))) switch (b.touchObject.fingerCount = a.originalEvent && void 0 !== a.originalEvent.touches ? a.originalEvent.touches.length : 1, b.touchObject.minSwipe = b.listWidth / b.options.touchThreshold, b.options.verticalSwiping === !0 && (b.touchObject.minSwipe = b.listHeight / b.options.touchThreshold), a.data.action) {
      case 'start':
        b.swipeStart(a);
        break;
      case 'move':
        b.swipeMove(a);
        break;
      case 'end':
        b.swipeEnd(a)
    }
  },
  b.prototype.swipeMove = function (a) {
    var b,
    c,
    d,
    e,
    f,
    g = this;
    return f = void 0 !== a.originalEvent ? a.originalEvent.touches : null,
    !(!g.dragging || f && 1 !== f.length) && (b = g.getLeft(g.currentSlide), g.touchObject.curX = void 0 !== f ? f[0].pageX : a.clientX, g.touchObject.curY = void 0 !== f ? f[0].pageY : a.clientY, g.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(g.touchObject.curX - g.touchObject.startX, 2))), g.options.verticalSwiping === !0 && (g.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(g.touchObject.curY - g.touchObject.startY, 2)))), c = g.swipeDirection(), 'vertical' !== c ? (void 0 !== a.originalEvent && g.touchObject.swipeLength > 4 && a.preventDefault(), e = (g.options.rtl === !1 ? 1 : - 1) * (g.touchObject.curX > g.touchObject.startX ? 1 : - 1), g.options.verticalSwiping === !0 && (e = g.touchObject.curY > g.touchObject.startY ? 1 : - 1), d = g.touchObject.swipeLength, g.touchObject.edgeHit = !1, g.options.infinite === !1 && (0 === g.currentSlide && 'right' === c || g.currentSlide >= g.getDotCount() && 'left' === c) && (d = g.touchObject.swipeLength * g.options.edgeFriction, g.touchObject.edgeHit = !0), g.options.vertical === !1 ? g.swipeLeft = b + d * e : g.swipeLeft = b + d * (g.$list.height() / g.listWidth) * e, g.options.verticalSwiping === !0 && (g.swipeLeft = b + d * e), g.options.fade !== !0 && g.options.touchMove !== !1 && (g.animating === !0 ? (g.swipeLeft = null, !1)  : void g.setCSS(g.swipeLeft)))  : void 0)
  },
  b.prototype.swipeStart = function (a) {
    var b,
    c = this;
    return c.interrupted = !0,
    1 !== c.touchObject.fingerCount || c.slideCount <= c.options.slidesToShow ? (c.touchObject = {
    }, !1)  : (void 0 !== a.originalEvent && void 0 !== a.originalEvent.touches && (b = a.originalEvent.touches[0]), c.touchObject.startX = c.touchObject.curX = void 0 !== b ? b.pageX : a.clientX, c.touchObject.startY = c.touchObject.curY = void 0 !== b ? b.pageY : a.clientY, void (c.dragging = !0))
  },
  b.prototype.unfilterSlides = b.prototype.slickUnfilter = function () {
    var a = this;
    null !== a.$slidesCache && (a.unload(), a.$slideTrack.children(this.options.slide).detach(), a.$slidesCache.appendTo(a.$slideTrack), a.reinit())
  },
  b.prototype.unload = function () {
    var b = this;
    a('.slick-cloned', b.$slider).remove(),
    b.$dots && b.$dots.remove(),
    b.$prevArrow && b.htmlExpr.test(b.options.prevArrow) && b.$prevArrow.remove(),
    b.$nextArrow && b.htmlExpr.test(b.options.nextArrow) && b.$nextArrow.remove(),
    b.$slides.removeClass('slick-slide slick-active slick-visible slick-current').attr('aria-hidden', 'true').css('width', '')
  },
  b.prototype.unslick = function (a) {
    var b = this;
    b.$slider.trigger('unslick', [
      b,
      a
    ]),
    b.destroy()
  },
  b.prototype.updateArrows = function () {
    var a,
    b = this;
    a = Math.floor(b.options.slidesToShow / 2),
    b.options.arrows === !0 && b.slideCount > b.options.slidesToShow && !b.options.infinite && (b.$prevArrow.removeClass('slick-disabled').attr('aria-disabled', 'false'), b.$nextArrow.removeClass('slick-disabled').attr('aria-disabled', 'false'), 0 === b.currentSlide ? (b.$prevArrow.addClass('slick-disabled').attr('aria-disabled', 'true'), b.$nextArrow.removeClass('slick-disabled').attr('aria-disabled', 'false'))  : b.currentSlide >= b.slideCount - b.options.slidesToShow && b.options.centerMode === !1 ? (b.$nextArrow.addClass('slick-disabled').attr('aria-disabled', 'true'), b.$prevArrow.removeClass('slick-disabled').attr('aria-disabled', 'false'))  : b.currentSlide >= b.slideCount - 1 && b.options.centerMode === !0 && (b.$nextArrow.addClass('slick-disabled').attr('aria-disabled', 'true'), b.$prevArrow.removeClass('slick-disabled').attr('aria-disabled', 'false')))
  },
  b.prototype.updateDots = function () {
    var a = this;
    null !== a.$dots && (a.$dots.find('li').removeClass('slick-active').attr('aria-hidden', 'true'), a.$dots.find('li').eq(Math.floor(a.currentSlide / a.options.slidesToScroll)).addClass('slick-active').attr('aria-hidden', 'false'))
  },
  b.prototype.visibility = function () {
    var a = this;
    a.options.autoplay && (document[a.hidden] ? a.interrupted = !0 : a.interrupted = !1)
  },
  a.fn.slick = function () {
    var a,
    c,
    d = this,
    e = arguments[0],
    f = Array.prototype.slice.call(arguments, 1),
    g = d.length;
    for (a = 0; g > a; a++) if ('object' == typeof e || 'undefined' == typeof e ? d[a].slick = new b(d[a], e)  : c = d[a].slick[e].apply(d[a].slick, f), 'undefined' != typeof c) return c;
    return d
  }
}),
$(document).ready(function () {
  $('.block-slider').not('.-disabled').each(function () {
    var a = $(this),
    b = $('<div class=\'block-slider-nav-inner\'></div>'),
    c = $('<div class=\'block-slider-nav\'></div>');
    c.append(b),
    a.find('.blocks').append(c),
    a.find('.inner-blocks').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: !0,
      appendArrows: b,
      appendDots: b,
      dots: !0,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    })
  })
}),
$(document).ready(function () {
  function a(a, c) {
    function d(a) {
      'mobile' == a ? h.each(function () {
        var a = $(this),
        b = a.data('num');
        console.log(b),
        a.detach().appendTo('#tabset-' + c + '-li-' + b)
      })  : 'desktop' == a && h.each(function () {
        var a = $(this);
        a.detach().appendTo(g)
      })
    }
    function e() {
      var a = b.width();
      a <= 600 ? 'mobile' !== j && (d('mobile'), j = 'mobile')  : 'desktop' !== j && (d('desktop'), j = 'desktop')
    }
    var f = a.children('.tab-selector'),
    g = a.children('.tab-content'),
    h = g.children(),
    i = f.children(),
    j = 'desktop';
    a.addClass('no-interaction'),
    h.each(function () {
      var a = $(this),
      b = a.index();
      a.data('num', b).attr('id', 'tabset-' + c + '-tab-' + b)
    }),
    i.each(function () {
      var a = $(this),
      b = a.index();
      a.data('num', b).attr('id', 'tabset-' + c + '-li-' + b)
    }),
    f.find('a').each(function () {
      var c = $(this),
      d = c.parent(),
      e = d.index();
      c.on('click', function (c) {
        c.preventDefault(),
        a.hasClass('no-interaction') && a.removeClass('no-interaction'),
        i.removeClass('active'),
        d.addClass('active'),
        h.hide(),
        h.eq(e).show(),
        'mobile' === j && b.scrollTop(d.offset().top)
      })
    }),
    f.children().first().addClass('active'),
    h.not(':first-child').hide(),
    b.on('resize', e),
    e()
  }
  var b = $(window),
  c = 0;
  $('.tab-wrap').each(function () {
    a($(this), c),
    c += 1
  })
}),
!function (a, b) {
  'function' == typeof define && define.amd ? define(['jquery'], function (a) {
    return b(a)
  })  : 'object' == typeof exports ? module.exports = b(require('jquery'))  : b(jQuery)
}(this, function (a) {
  function b(a) {
    this.$container,
    this.constraints = null,
    this.__$tooltip,
    this.__init(a)
  }
  function c(b, c) {
    var d = !0;
    return a.each(b, function (a, e) {
      return void 0 === c[a] || b[a] !== c[a] ? (d = !1, !1)  : void 0
    }),
    d
  }
  function d(b) {
    var c = b.attr('id'),
    d = c ? h.window.document.getElementById(c)  : null;
    return d ? d === b[0] : a.contains(h.window.document.body, b[0])
  }
  function e() {
    if (!g) return !1;
    var a = g.document.body || g.document.documentElement,
    b = a.style,
    c = 'transition',
    d = [
      'Moz',
      'Webkit',
      'Khtml',
      'O',
      'ms'
    ];
    if ('string' == typeof b[c]) return !0;
    c = c.charAt(0).toUpperCase() + c.substr(1);
    for (var e = 0; e < d.length; e++) if ('string' == typeof b[d[e] + c]) return !0;
    return !1
  }
  var f = {
    animation: 'fade',
    animationDuration: 350,
    content: null,
    contentAsHTML: !1,
    contentCloning: !1,
    debug: !0,
    delay: 300,
    delayTouch: [
      300,
      500
    ],
    functionInit: null,
    functionBefore: null,
    functionReady: null,
    functionAfter: null,
    functionFormat: null,
    IEmin: 6,
    interactive: !1,
    multiple: !1,
    parent: 'body',
    plugins: [
      'sideTip'
    ],
    repositionOnScroll: !1,
    restoration: 'none',
    selfDestruction: !0,
    theme: [
    ],
    timer: 0,
    trackerInterval: 500,
    trackOrigin: !1,
    trackTooltip: !1,
    trigger: 'hover',
    triggerClose: {
      click: !1,
      mouseleave: !1,
      originClick: !1,
      scroll: !1,
      tap: !1,
      touchleave: !1
    },
    triggerOpen: {
      click: !1,
      mouseenter: !1,
      tap: !1,
      touchstart: !1
    },
    updateAnimation: 'rotate',
    zIndex: 9999999
  },
  g = 'undefined' != typeof window ? window : null,
  h = {
    hasTouchCapability: !(!g || !('ontouchstart' in g || g.DocumentTouch && g.document instanceof g.DocumentTouch || g.navigator.maxTouchPoints)),
    hasTransitions: e(),
    IE: !1,
    semVer: '4.1.4',
    window: g
  },
  i = function () {
    this.__$emitterPrivate = a({
    }),
    this.__$emitterPublic = a({
    }),
    this.__instancesLatestArr = [
    ],
    this.__plugins = {
    },
    this._env = h
  };
  i.prototype = {
    __bridge: function (b, c, d) {
      if (!c[d]) {
        var e = function () {
        };
        e.prototype = b;
        var g = new e;
        g.__init && g.__init(c),
        a.each(b, function (a, b) {
          0 != a.indexOf('__') && (c[a] ? f.debug && console.log('The ' + a + ' method of the ' + d + ' plugin conflicts with another plugin or native methods')  : (c[a] = function () {
            return g[a].apply(g, Array.prototype.slice.apply(arguments))
          }, c[a].bridged = g))
        }),
        c[d] = g
      }
      return this
    },
    __setWindow: function (a) {
      return h.window = a,
      this
    },
    _getRuler: function (a) {
      return new b(a)
    },
    _off: function () {
      return this.__$emitterPrivate.off.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)),
      this
    },
    _on: function () {
      return this.__$emitterPrivate.on.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)),
      this
    },
    _one: function () {
      return this.__$emitterPrivate.one.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)),
      this
    },
    _plugin: function (b) {
      var c = this;
      if ('string' == typeof b) {
        var d = b,
        e = null;
        return d.indexOf('.') > 0 ? e = c.__plugins[d] : a.each(c.__plugins, function (a, b) {
          return b.name.substring(b.name.length - d.length - 1) == '.' + d ? (e = b, !1)  : void 0
        }),
        e
      }
      if (b.name.indexOf('.') < 0) throw new Error('Plugins must be namespaced');
      return c.__plugins[b.name] = b,
      b.core && c.__bridge(b.core, c, b.name),
      this
    },
    _trigger: function () {
      var a = Array.prototype.slice.apply(arguments);
      return 'string' == typeof a[0] && (a[0] = {
        type: a[0]
      }),
      this.__$emitterPrivate.trigger.apply(this.__$emitterPrivate, a),
      this.__$emitterPublic.trigger.apply(this.__$emitterPublic, a),
      this
    },
    instances: function (b) {
      var c = [
      ],
      d = b || '.tooltipstered';
      return a(d).each(function () {
        var b = a(this),
        d = b.data('tooltipster-ns');
        d && a.each(d, function (a, d) {
          c.push(b.data(d))
        })
      }),
      c
    },
    instancesLatest: function () {
      return this.__instancesLatestArr
    },
    off: function () {
      return this.__$emitterPublic.off.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    },
    on: function () {
      return this.__$emitterPublic.on.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    },
    one: function () {
      return this.__$emitterPublic.one.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    },
    origins: function (b) {
      var c = b ? b + ' ' : '';
      return a(c + '.tooltipstered').toArray()
    },
    setDefaults: function (b) {
      return a.extend(f, b),
      this
    },
    triggerHandler: function () {
      return this.__$emitterPublic.triggerHandler.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    }
  },
  a.tooltipster = new i,
  a.Tooltipster = function (b, c) {
    this.__callbacks = {
      close: [
      ],
      open: [
      ]
    },
    this.__closingTime,
    this.__Content,
    this.__contentBcr,
    this.__destroyed = !1,
    this.__destroying = !1,
    this.__$emitterPrivate = a({
    }),
    this.__$emitterPublic = a({
    }),
    this.__enabled = !0,
    this.__garbageCollector,
    this.__Geometry,
    this.__lastPosition,
    this.__namespace = 'tooltipster-' + Math.round(1000000 * Math.random()),
    this.__options,
    this.__$originParents,
    this.__pointerIsOverOrigin = !1,
    this.__previousThemes = [
    ],
    this.__state = 'closed',
    this.__timeouts = {
      close: [
      ],
      open: null
    },
    this.__touchEvents = [
    ],
    this.__tracker = null,
    this._$origin,
    this._$tooltip,
    this.__init(b, c)
  },
  a.Tooltipster.prototype = {
    __init: function (b, c) {
      var d = this;
      if (d._$origin = a(b), d.__options = a.extend(!0, {
      }, f, c), d.__optionsFormat(), !h.IE || h.IE >= d.__options.IEmin) {
        var e = null;
        if (void 0 === d._$origin.data('tooltipster-initialTitle') && (e = d._$origin.attr('title'), void 0 === e && (e = null), d._$origin.data('tooltipster-initialTitle', e)), null !== d.__options.content) d.__contentSet(d.__options.content);
         else {
          var g,
          i = d._$origin.attr('data-tooltip-content');
          i && (g = a(i)),
          g && g[0] ? d.__contentSet(g.first())  : d.__contentSet(e)
        }
        d._$origin.removeAttr('title').addClass('tooltipstered'),
        d.__prepareOrigin(),
        d.__prepareGC(),
        a.each(d.__options.plugins, function (a, b) {
          d._plug(b)
        }),
        h.hasTouchCapability && a('body').on('touchmove.' + d.__namespace + '-triggerOpen', function (a) {
          d._touchRecordEvent(a)
        }),
        d._on('created', function () {
          d.__prepareTooltip()
        })._on('repositioned', function (a) {
          d.__lastPosition = a.position
        })
      } else d.__options.disabled = !0
    },
    __contentInsert: function () {
      var a = this,
      b = a._$tooltip.find('.tooltipster-content'),
      c = a.__Content,
      d = function (a) {
        c = a
      };
      return a._trigger({
        type: 'format',
        content: a.__Content,
        format: d
      }),
      a.__options.functionFormat && (c = a.__options.functionFormat.call(a, a, {
        origin: a._$origin[0]
      }, a.__Content)),
      'string' != typeof c || a.__options.contentAsHTML ? b.empty().append(c)  : b.text(c),
      a
    },
    __contentSet: function (b) {
      return b instanceof a && this.__options.contentCloning && (b = b.clone(!0)),
      this.__Content = b,
      this._trigger({
        type: 'updated',
        content: b
      }),
      this
    },
    __destroyError: function () {
      throw new Error('This tooltip has been destroyed and cannot execute your method call.')
    },
    __geometry: function () {
      var b = this,
      c = b._$origin,
      d = b._$origin.is('area');
      if (d) {
        var e = b._$origin.parent().attr('name');
        c = a('img[usemap="#' + e + '"]')
      }
      var f = c[0].getBoundingClientRect(),
      g = a(h.window.document),
      i = a(h.window),
      j = c,
      k = {
        available: {
          document: null,
          window: null
        },
        document: {
          size: {
            height: g.height(),
            width: g.width()
          }
        },
        window: {
          scroll: {
            left: h.window.scrollX || h.window.document.documentElement.scrollLeft,
            top: h.window.scrollY || h.window.document.documentElement.scrollTop
          },
          size: {
            height: i.height(),
            width: i.width()
          }
        },
        origin: {
          fixedLineage: !1,
          offset: {
          },
          size: {
            height: f.bottom - f.top,
            width: f.right - f.left
          },
          usemapImage: d ? c[0] : null,
          windowOffset: {
            bottom: f.bottom,
            left: f.left,
            right: f.right,
            top: f.top
          }
        }
      };
      if (d) {
        var l = b._$origin.attr('shape'),
        m = b._$origin.attr('coords');
        if (m && (m = m.split(','), a.map(m, function (a, b) {
          m[b] = parseInt(a)
        })), 'default' != l) switch (l) {
          case 'circle':
            var n = m[0],
            o = m[1],
            p = m[2],
            q = o - p,
            r = n - p;
            k.origin.size.height = 2 * p,
            k.origin.size.width = k.origin.size.height,
            k.origin.windowOffset.left += r,
            k.origin.windowOffset.top += q;
            break;
          case 'rect':
            var s = m[0],
            t = m[1],
            u = m[2],
            v = m[3];
            k.origin.size.height = v - t,
            k.origin.size.width = u - s,
            k.origin.windowOffset.left += s,
            k.origin.windowOffset.top += t;
            break;
          case 'poly':
            for (var w = 0, x = 0, y = 0, z = 0, A = 'even', B = 0; B < m.length; B++) {
              var C = m[B];
              'even' == A ? (C > y && (y = C, 0 === B && (w = y)), w > C && (w = C), A = 'odd')  : (C > z && (z = C, 1 == B && (x = z)), x > C && (x = C), A = 'even')
            }
            k.origin.size.height = z - x,
            k.origin.size.width = y - w,
            k.origin.windowOffset.left += w,
            k.origin.windowOffset.top += x
        }
      }
      var D = function (a) {
        k.origin.size.height = a.height,
        k.origin.windowOffset.left = a.left,
        k.origin.windowOffset.top = a.top,
        k.origin.size.width = a.width
      };
      for (b._trigger({
        type: 'geometry',
        edit: D,
        geometry: {
          height: k.origin.size.height,
          left: k.origin.windowOffset.left,
          top: k.origin.windowOffset.top,
          width: k.origin.size.width
        }
      }), k.origin.windowOffset.right = k.origin.windowOffset.left + k.origin.size.width, k.origin.windowOffset.bottom = k.origin.windowOffset.top + k.origin.size.height, k.origin.offset.left = k.origin.windowOffset.left + k.window.scroll.left, k.origin.offset.top = k.origin.windowOffset.top + k.window.scroll.top, k.origin.offset.bottom = k.origin.offset.top + k.origin.size.height, k.origin.offset.right = k.origin.offset.left + k.origin.size.width, k.available.document = {
        bottom: {
          height: k.document.size.height - k.origin.offset.bottom,
          width: k.document.size.width
        },
        left: {
          height: k.document.size.height,
          width: k.origin.offset.left
        },
        right: {
          height: k.document.size.height,
          width: k.document.size.width - k.origin.offset.right
        },
        top: {
          height: k.origin.offset.top,
          width: k.document.size.width
        }
      }, k.available.window = {
        bottom: {
          height: Math.max(k.window.size.height - Math.max(k.origin.windowOffset.bottom, 0), 0),
          width: k.window.size.width
        },
        left: {
          height: k.window.size.height,
          width: Math.max(k.origin.windowOffset.left, 0)
        },
        right: {
          height: k.window.size.height,
          width: Math.max(k.window.size.width - Math.max(k.origin.windowOffset.right, 0), 0)
        },
        top: {
          height: Math.max(k.origin.windowOffset.top, 0),
          width: k.window.size.width
        }
      }; 'html' != j[0].tagName.toLowerCase(); ) {
        if ('fixed' == j.css('position')) {
          k.origin.fixedLineage = !0;
          break
        }
        j = j.parent()
      }
      return k
    },
    __optionsFormat: function () {
      return 'number' == typeof this.__options.animationDuration && (this.__options.animationDuration = [
        this.__options.animationDuration,
        this.__options.animationDuration
      ]),
      'number' == typeof this.__options.delay && (this.__options.delay = [
        this.__options.delay,
        this.__options.delay
      ]),
      'number' == typeof this.__options.delayTouch && (this.__options.delayTouch = [
        this.__options.delayTouch,
        this.__options.delayTouch
      ]),
      'string' == typeof this.__options.theme && (this.__options.theme = [
        this.__options.theme
      ]),
      'string' == typeof this.__options.parent && (this.__options.parent = a(this.__options.parent)),
      'hover' == this.__options.trigger ? (this.__options.triggerOpen = {
        mouseenter: !0,
        touchstart: !0
      }, this.__options.triggerClose = {
        mouseleave: !0,
        originClick: !0,
        touchleave: !0
      })  : 'click' == this.__options.trigger && (this.__options.triggerOpen = {
        click: !0,
        tap: !0
      }, this.__options.triggerClose = {
        click: !0,
        tap: !0
      }),
      this._trigger('options'),
      this
    },
    __prepareGC: function () {
      var b = this;
      return b.__options.selfDestruction ? b.__garbageCollector = setInterval(function () {
        var c = (new Date).getTime();
        b.__touchEvents = a.grep(b.__touchEvents, function (a, b) {
          return c - a.time > 60000
        }),
        d(b._$origin) || b.destroy()
      }, 20000)  : clearInterval(b.__garbageCollector),
      b
    },
    __prepareOrigin: function () {
      var a = this;
      if (a._$origin.off('.' + a.__namespace + '-triggerOpen'), h.hasTouchCapability && a._$origin.on('touchstart.' + a.__namespace + '-triggerOpen touchend.' + a.__namespace + '-triggerOpen touchcancel.' + a.__namespace + '-triggerOpen', function (b) {
        a._touchRecordEvent(b)
      }), a.__options.triggerOpen.click || a.__options.triggerOpen.tap && h.hasTouchCapability) {
        var b = '';
        a.__options.triggerOpen.click && (b += 'click.' + a.__namespace + '-triggerOpen '),
        a.__options.triggerOpen.tap && h.hasTouchCapability && (b += 'touchend.' + a.__namespace + '-triggerOpen'),
        a._$origin.on(b, function (b) {
          a._touchIsMeaningfulEvent(b) && a._open(b)
        })
      }
      if (a.__options.triggerOpen.mouseenter || a.__options.triggerOpen.touchstart && h.hasTouchCapability) {
        var b = '';
        a.__options.triggerOpen.mouseenter && (b += 'mouseenter.' + a.__namespace + '-triggerOpen '),
        a.__options.triggerOpen.touchstart && h.hasTouchCapability && (b += 'touchstart.' + a.__namespace + '-triggerOpen'),
        a._$origin.on(b, function (b) {
          !a._touchIsTouchEvent(b) && a._touchIsEmulatedEvent(b) || (a.__pointerIsOverOrigin = !0, a._openShortly(b))
        })
      }
      if (a.__options.triggerClose.mouseleave || a.__options.triggerClose.touchleave && h.hasTouchCapability) {
        var b = '';
        a.__options.triggerClose.mouseleave && (b += 'mouseleave.' + a.__namespace + '-triggerOpen '),
        a.__options.triggerClose.touchleave && h.hasTouchCapability && (b += 'touchend.' + a.__namespace + '-triggerOpen touchcancel.' + a.__namespace + '-triggerOpen'),
        a._$origin.on(b, function (b) {
          a._touchIsMeaningfulEvent(b) && (a.__pointerIsOverOrigin = !1)
        })
      }
      return a
    },
    __prepareTooltip: function () {
      var b = this,
      c = b.__options.interactive ? 'auto' : '';
      return b._$tooltip.attr('id', b.__namespace).css({
        'pointer-events': c,
        zIndex: b.__options.zIndex
      }),
      a.each(b.__previousThemes, function (a, c) {
        b._$tooltip.removeClass(c);
      }),
      a.each(b.__options.theme, function (a, c) {
        b._$tooltip.addClass(c)
      }),
      b.__previousThemes = a.merge([], b.__options.theme),
      b
    },
    __scrollHandler: function (b) {
      var c = this;
      if (c.__options.triggerClose.scroll) c._close(b);
       else {
        if (b.target === h.window.document) c.__Geometry.origin.fixedLineage || c.__options.repositionOnScroll && c.reposition(b);
         else {
          var d = c.__geometry(),
          e = !1;
          if ('fixed' != c._$origin.css('position') && c.__$originParents.each(function (b, c) {
            var f = a(c),
            g = f.css('overflow-x'),
            h = f.css('overflow-y');
            if ('visible' != g || 'visible' != h) {
              var i = c.getBoundingClientRect();
              if ('visible' != g && (d.origin.windowOffset.left < i.left || d.origin.windowOffset.right > i.right)) return e = !0,
              !1;
              if ('visible' != h && (d.origin.windowOffset.top < i.top || d.origin.windowOffset.bottom > i.bottom)) return e = !0,
              !1
            }
            return 'fixed' != f.css('position') && void 0
          }), e) c._$tooltip.css('visibility', 'hidden');
           else if (c._$tooltip.css('visibility', 'visible'), c.__options.repositionOnScroll) c.reposition(b);
           else {
            var f = d.origin.offset.left - c.__Geometry.origin.offset.left,
            g = d.origin.offset.top - c.__Geometry.origin.offset.top;
            c._$tooltip.css({
              left: c.__lastPosition.coord.left + f,
              top: c.__lastPosition.coord.top + g
            })
          }
        }
        c._trigger({
          type: 'scroll',
          event: b
        })
      }
      return c
    },
    __stateSet: function (a) {
      return this.__state = a,
      this._trigger({
        type: 'state',
        state: a
      }),
      this
    },
    __timeoutsClear: function () {
      return clearTimeout(this.__timeouts.open),
      this.__timeouts.open = null,
      a.each(this.__timeouts.close, function (a, b) {
        clearTimeout(b)
      }),
      this.__timeouts.close = [
      ],
      this
    },
    __trackerStart: function () {
      var a = this,
      b = a._$tooltip.find('.tooltipster-content');
      return a.__options.trackTooltip && (a.__contentBcr = b[0].getBoundingClientRect()),
      a.__tracker = setInterval(function () {
        if (d(a._$origin) && d(a._$tooltip)) {
          if (a.__options.trackOrigin) {
            var e = a.__geometry(),
            f = !1;
            c(e.origin.size, a.__Geometry.origin.size) && (a.__Geometry.origin.fixedLineage ? c(e.origin.windowOffset, a.__Geometry.origin.windowOffset) && (f = !0)  : c(e.origin.offset, a.__Geometry.origin.offset) && (f = !0)),
            f || (a.__options.triggerClose.mouseleave ? a._close()  : a.reposition())
          }
          if (a.__options.trackTooltip) {
            var g = b[0].getBoundingClientRect();
            g.height === a.__contentBcr.height && g.width === a.__contentBcr.width || (a.reposition(), a.__contentBcr = g)
          }
        } else a._close()
      }, a.__options.trackerInterval),
      a
    },
    _close: function (b, c) {
      var d = this,
      e = !0;
      if (d._trigger({
        type: 'close',
        event: b,
        stop: function () {
          e = !1
        }
      }), e || d.__destroying) {
        c && d.__callbacks.close.push(c),
        d.__callbacks.open = [
        ],
        d.__timeoutsClear();
        var f = function () {
          a.each(d.__callbacks.close, function (a, c) {
            c.call(d, d, {
              event: b,
              origin: d._$origin[0]
            })
          }),
          d.__callbacks.close = [
          ]
        };
        if ('closed' != d.__state) {
          var g = !0,
          i = new Date,
          j = i.getTime(),
          k = j + d.__options.animationDuration[1];
          if ('disappearing' == d.__state && k > d.__closingTime && (g = !1), g) {
            d.__closingTime = k,
            'disappearing' != d.__state && d.__stateSet('disappearing');
            var l = function () {
              clearInterval(d.__tracker),
              d._trigger({
                type: 'closing',
                event: b
              }),
              d._$tooltip.off('.' + d.__namespace + '-triggerClose').removeClass('tooltipster-dying'),
              a(h.window).off('.' + d.__namespace + '-triggerClose'),
              d.__$originParents.each(function (b, c) {
                a(c).off('scroll.' + d.__namespace + '-triggerClose')
              }),
              d.__$originParents = null,
              a('body').off('.' + d.__namespace + '-triggerClose'),
              d._$origin.off('.' + d.__namespace + '-triggerClose'),
              d._off('dismissable'),
              d.__stateSet('closed'),
              d._trigger({
                type: 'after',
                event: b
              }),
              d.__options.functionAfter && d.__options.functionAfter.call(d, d, {
                event: b,
                origin: d._$origin[0]
              }),
              f()
            };
            h.hasTransitions ? (d._$tooltip.css({
              '-moz-animation-duration': d.__options.animationDuration[1] + 'ms',
              '-ms-animation-duration': d.__options.animationDuration[1] + 'ms',
              '-o-animation-duration': d.__options.animationDuration[1] + 'ms',
              '-webkit-animation-duration': d.__options.animationDuration[1] + 'ms',
              'animation-duration': d.__options.animationDuration[1] + 'ms',
              'transition-duration': d.__options.animationDuration[1] + 'ms'
            }), d._$tooltip.clearQueue().removeClass('tooltipster-show').addClass('tooltipster-dying'), d.__options.animationDuration[1] > 0 && d._$tooltip.delay(d.__options.animationDuration[1]), d._$tooltip.queue(l))  : d._$tooltip.stop().fadeOut(d.__options.animationDuration[1], l)
          }
        } else f()
      }
      return d
    },
    _off: function () {
      return this.__$emitterPrivate.off.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)),
      this
    },
    _on: function () {
      return this.__$emitterPrivate.on.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)),
      this
    },
    _one: function () {
      return this.__$emitterPrivate.one.apply(this.__$emitterPrivate, Array.prototype.slice.apply(arguments)),
      this
    },
    _open: function (b, c) {
      var e = this;
      if (!e.__destroying && d(e._$origin) && e.__enabled) {
        var f = !0;
        if ('closed' == e.__state && (e._trigger({
          type: 'before',
          event: b,
          stop: function () {
            f = !1
          }
        }), f && e.__options.functionBefore && (f = e.__options.functionBefore.call(e, e, {
          event: b,
          origin: e._$origin[0]
        }))), f !== !1 && null !== e.__Content) {
          c && e.__callbacks.open.push(c),
          e.__callbacks.close = [
          ],
          e.__timeoutsClear();
          var g,
          i = function () {
            'stable' != e.__state && e.__stateSet('stable'),
            a.each(e.__callbacks.open, function (a, b) {
              b.call(e, e, {
                origin: e._$origin[0],
                tooltip: e._$tooltip[0]
              })
            }),
            e.__callbacks.open = [
            ]
          };
          if ('closed' !== e.__state) g = 0,
          'disappearing' === e.__state ? (e.__stateSet('appearing'), h.hasTransitions ? (e._$tooltip.clearQueue().removeClass('tooltipster-dying').addClass('tooltipster-show'), e.__options.animationDuration[0] > 0 && e._$tooltip.delay(e.__options.animationDuration[0]), e._$tooltip.queue(i))  : e._$tooltip.stop().fadeIn(i))  : 'stable' == e.__state && i();
           else {
            if (e.__stateSet('appearing'), g = e.__options.animationDuration[0], e.__contentInsert(), e.reposition(b, !0), h.hasTransitions ? (e._$tooltip.addClass('tooltipster-' + e.__options.animation).addClass('tooltipster-initial').css({
              '-moz-animation-duration': e.__options.animationDuration[0] + 'ms',
              '-ms-animation-duration': e.__options.animationDuration[0] + 'ms',
              '-o-animation-duration': e.__options.animationDuration[0] + 'ms',
              '-webkit-animation-duration': e.__options.animationDuration[0] + 'ms',
              'animation-duration': e.__options.animationDuration[0] + 'ms',
              'transition-duration': e.__options.animationDuration[0] + 'ms'
            }), setTimeout(function () {
              'closed' != e.__state && (e._$tooltip.addClass('tooltipster-show').removeClass('tooltipster-initial'), e.__options.animationDuration[0] > 0 && e._$tooltip.delay(e.__options.animationDuration[0]), e._$tooltip.queue(i))
            }, 0))  : e._$tooltip.css('display', 'none').fadeIn(e.__options.animationDuration[0], i), e.__trackerStart(), a(h.window).on('resize.' + e.__namespace + '-triggerClose', function (a) {
              e.reposition(a)
            }).on('scroll.' + e.__namespace + '-triggerClose', function (a) {
              e.__scrollHandler(a)
            }), e.__$originParents = e._$origin.parents(), e.__$originParents.each(function (b, c) {
              a(c).on('scroll.' + e.__namespace + '-triggerClose', function (a) {
                e.__scrollHandler(a)
              })
            }), e.__options.triggerClose.mouseleave || e.__options.triggerClose.touchleave && h.hasTouchCapability) {
              e._on('dismissable', function (a) {
                a.dismissable ? a.delay ? (m = setTimeout(function () {
                  e._close(a.event)
                }, a.delay), e.__timeouts.close.push(m))  : e._close(a)  : clearTimeout(m)
              });
              var j = e._$origin,
              k = '',
              l = '',
              m = null;
              e.__options.interactive && (j = j.add(e._$tooltip)),
              e.__options.triggerClose.mouseleave && (k += 'mouseenter.' + e.__namespace + '-triggerClose ', l += 'mouseleave.' + e.__namespace + '-triggerClose '),
              e.__options.triggerClose.touchleave && h.hasTouchCapability && (k += 'touchstart.' + e.__namespace + '-triggerClose', l += 'touchend.' + e.__namespace + '-triggerClose touchcancel.' + e.__namespace + '-triggerClose'),
              j.on(l, function (a) {
                if (e._touchIsTouchEvent(a) || !e._touchIsEmulatedEvent(a)) {
                  var b = 'mouseleave' == a.type ? e.__options.delay : e.__options.delayTouch;
                  e._trigger({
                    delay: b[1],
                    dismissable: !0,
                    event: a,
                    type: 'dismissable'
                  })
                }
              }).on(k, function (a) {
                !e._touchIsTouchEvent(a) && e._touchIsEmulatedEvent(a) || e._trigger({
                  dismissable: !1,
                  event: a,
                  type: 'dismissable'
                })
              })
            }
            e.__options.triggerClose.originClick && e._$origin.on('click.' + e.__namespace + '-triggerClose', function (a) {
              e._touchIsTouchEvent(a) || e._touchIsEmulatedEvent(a) || e._close(a)
            }),
            (e.__options.triggerClose.click || e.__options.triggerClose.tap && h.hasTouchCapability) && setTimeout(function () {
              if ('closed' != e.__state) {
                var b = '';
                e.__options.triggerClose.click && (b += 'click.' + e.__namespace + '-triggerClose '),
                e.__options.triggerClose.tap && h.hasTouchCapability && (b += 'touchend.' + e.__namespace + '-triggerClose'),
                a('body').on(b, function (b) {
                  e._touchIsMeaningfulEvent(b) && (e._touchRecordEvent(b), e.__options.interactive && a.contains(e._$tooltip[0], b.target) || e._close(b))
                }),
                e.__options.triggerClose.tap && h.hasTouchCapability && a('body').on('touchstart.' + e.__namespace + '-triggerClose', function (a) {
                  e._touchRecordEvent(a)
                })
              }
            }, 0),
            e._trigger('ready'),
            e.__options.functionReady && e.__options.functionReady.call(e, e, {
              origin: e._$origin[0],
              tooltip: e._$tooltip[0]
            })
          }
          if (e.__options.timer > 0) {
            var m = setTimeout(function () {
              e._close()
            }, e.__options.timer + g);
            e.__timeouts.close.push(m)
          }
        }
      }
      return e
    },
    _openShortly: function (a) {
      var b = this,
      c = !0;
      if ('stable' != b.__state && 'appearing' != b.__state && !b.__timeouts.open && (b._trigger({
        type: 'start',
        event: a,
        stop: function () {
          c = !1
        }
      }), c)) {
        var d = 0 == a.type.indexOf('touch') ? b.__options.delayTouch : b.__options.delay;
        d[0] ? b.__timeouts.open = setTimeout(function () {
          b.__timeouts.open = null,
          b.__pointerIsOverOrigin && b._touchIsMeaningfulEvent(a) ? (b._trigger('startend'), b._open(a))  : b._trigger('startcancel')
        }, d[0])  : (b._trigger('startend'), b._open(a))
      }
      return b
    },
    _optionsExtract: function (b, c) {
      var d = this,
      e = a.extend(!0, {
      }, c),
      f = d.__options[b];
      return f || (f = {
      }, a.each(c, function (a, b) {
        var c = d.__options[a];
        void 0 !== c && (f[a] = c)
      })),
      a.each(e, function (b, c) {
        void 0 !== f[b] && ('object' != typeof c || c instanceof Array || null == c || 'object' != typeof f[b] || f[b] instanceof Array || null == f[b] ? e[b] = f[b] : a.extend(e[b], f[b]))
      }),
      e
    },
    _plug: function (b) {
      var c = a.tooltipster._plugin(b);
      if (!c) throw new Error('The "' + b + '" plugin is not defined');
      return c.instance && a.tooltipster.__bridge(c.instance, this, c.name),
      this
    },
    _touchIsEmulatedEvent: function (a) {
      for (var b = !1, c = (new Date).getTime(), d = this.__touchEvents.length - 1; d >= 0; d--) {
        var e = this.__touchEvents[d];
        if (!(c - e.time < 500)) break;
        e.target === a.target && (b = !0)
      }
      return b
    },
    _touchIsMeaningfulEvent: function (a) {
      return this._touchIsTouchEvent(a) && !this._touchSwiped(a.target) || !this._touchIsTouchEvent(a) && !this._touchIsEmulatedEvent(a)
    },
    _touchIsTouchEvent: function (a) {
      return 0 == a.type.indexOf('touch')
    },
    _touchRecordEvent: function (a) {
      return this._touchIsTouchEvent(a) && (a.time = (new Date).getTime(), this.__touchEvents.push(a)),
      this
    },
    _touchSwiped: function (a) {
      for (var b = !1, c = this.__touchEvents.length - 1; c >= 0; c--) {
        var d = this.__touchEvents[c];
        if ('touchmove' == d.type) {
          b = !0;
          break
        }
        if ('touchstart' == d.type && a === d.target) break
      }
      return b
    },
    _trigger: function () {
      var b = Array.prototype.slice.apply(arguments);
      return 'string' == typeof b[0] && (b[0] = {
        type: b[0]
      }),
      b[0].instance = this,
      b[0].origin = this._$origin ? this._$origin[0] : null,
      b[0].tooltip = this._$tooltip ? this._$tooltip[0] : null,
      this.__$emitterPrivate.trigger.apply(this.__$emitterPrivate, b),
      a.tooltipster._trigger.apply(a.tooltipster, b),
      this.__$emitterPublic.trigger.apply(this.__$emitterPublic, b),
      this
    },
    _unplug: function (b) {
      var c = this;
      if (c[b]) {
        var d = a.tooltipster._plugin(b);
        d.instance && a.each(d.instance, function (a, d) {
          c[a] && c[a].bridged === c[b] && delete c[a]
        }),
        c[b].__destroy && c[b].__destroy(),
        delete c[b]
      }
      return c
    },
    close: function (a) {
      return this.__destroyed ? this.__destroyError()  : this._close(null, a),
      this
    },
    content: function (a) {
      var b = this;
      if (void 0 === a) return b.__Content;
      if (b.__destroyed) b.__destroyError();
       else if (b.__contentSet(a), null !== b.__Content) {
        if ('closed' !== b.__state && (b.__contentInsert(), b.reposition(), b.__options.updateAnimation)) if (h.hasTransitions) {
          var c = b.__options.updateAnimation;
          b._$tooltip.addClass('tooltipster-update-' + c),
          setTimeout(function () {
            'closed' != b.__state && b._$tooltip.removeClass('tooltipster-update-' + c)
          }, 1000)
        } else b._$tooltip.fadeTo(200, 0.5, function () {
          'closed' != b.__state && b._$tooltip.fadeTo(200, 1)
        })
      } else b._close();
      return b
    },
    destroy: function () {
      var b = this;
      return b.__destroyed ? b.__destroyError()  : b.__destroying || (b.__destroying = !0, b._close(null, function () {
        b._trigger('destroy'),
        b.__destroying = !1,
        b.__destroyed = !0,
        b._$origin.removeData(b.__namespace).off('.' + b.__namespace + '-triggerOpen'),
        a('body').off('.' + b.__namespace + '-triggerOpen');
        var c = b._$origin.data('tooltipster-ns');
        if (c) if (1 === c.length) {
          var d = null;
          'previous' == b.__options.restoration ? d = b._$origin.data('tooltipster-initialTitle')  : 'current' == b.__options.restoration && (d = 'string' == typeof b.__Content ? b.__Content : a('<div></div>').append(b.__Content).html()),
          d && b._$origin.attr('title', d),
          b._$origin.removeClass('tooltipstered'),
          b._$origin.removeData('tooltipster-ns').removeData('tooltipster-initialTitle')
        } else c = a.grep(c, function (a, c) {
          return a !== b.__namespace
        }),
        b._$origin.data('tooltipster-ns', c);
        b._trigger('destroyed'),
        b._off(),
        b.off(),
        b.__Content = null,
        b.__$emitterPrivate = null,
        b.__$emitterPublic = null,
        b.__options.parent = null,
        b._$origin = null,
        b._$tooltip = null,
        a.tooltipster.__instancesLatestArr = a.grep(a.tooltipster.__instancesLatestArr, function (a, c) {
          return b !== a
        }),
        clearInterval(b.__garbageCollector)
      })),
      b
    },
    disable: function () {
      return this.__destroyed ? (this.__destroyError(), this)  : (this._close(), this.__enabled = !1, this)
    },
    elementOrigin: function () {
      return this.__destroyed ? void this.__destroyError()  : this._$origin[0]
    },
    elementTooltip: function () {
      return this._$tooltip ? this._$tooltip[0] : null
    },
    enable: function () {
      return this.__enabled = !0,
      this
    },
    hide: function (a) {
      return this.close(a)
    },
    instance: function () {
      return this
    },
    off: function () {
      return this.__destroyed || this.__$emitterPublic.off.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    },
    on: function () {
      return this.__destroyed ? this.__destroyError()  : this.__$emitterPublic.on.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    },
    one: function () {
      return this.__destroyed ? this.__destroyError()  : this.__$emitterPublic.one.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    },
    open: function (a) {
      return this.__destroyed || this.__destroying ? this.__destroyError()  : this._open(null, a),
      this
    },
    option: function (b, c) {
      return void 0 === c ? this.__options[b] : (this.__destroyed ? this.__destroyError()  : (this.__options[b] = c, this.__optionsFormat(), a.inArray(b, [
        'trigger',
        'triggerClose',
        'triggerOpen'
      ]) >= 0 && this.__prepareOrigin(), 'selfDestruction' === b && this.__prepareGC()), this)
    },
    reposition: function (a, b) {
      var c = this;
      return c.__destroyed ? c.__destroyError()  : (d(c._$tooltip) || b) && (b || c._$tooltip.detach(), c.__Geometry = c.__geometry(), c._trigger({
        type: 'reposition',
        event: a,
        helper: {
          geo: c.__Geometry
        }
      })),
      c
    },
    show: function (a) {
      return this.open(a)
    },
    status: function () {
      return {
        destroyed: this.__destroyed,
        destroying: this.__destroying,
        enabled: this.__enabled,
        open: 'closed' !== this.__state,
        state: this.__state
      }
    },
    triggerHandler: function () {
      return this.__destroyed ? this.__destroyError()  : this.__$emitterPublic.triggerHandler.apply(this.__$emitterPublic, Array.prototype.slice.apply(arguments)),
      this
    }
  },
  a.fn.tooltipster = function () {
    var b = Array.prototype.slice.apply(arguments),
    c = 'You are using a single HTML element as content for several tooltips. You probably want to set the contentCloning option to TRUE.';
    if (0 === this.length) return this;
    if ('string' == typeof b[0]) {
      var d = '#*$~&';
      return this.each(function () {
        var e = a(this).data('tooltipster-ns'),
        f = e ? a(this).data(e[0])  : null;
        if (!f) throw new Error('You called Tooltipster\'s "' + b[0] + '" method on an uninitialized element');
        if ('function' != typeof f[b[0]]) throw new Error('Unknown method "' + b[0] + '"');
        this.length > 1 && 'content' == b[0] && (b[1] instanceof a || 'object' == typeof b[1] && null != b[1] && b[1].tagName) && !f.__options.contentCloning && f.__options.debug && console.log(c);
        var g = f[b[0]](b[1], b[2]);
        return g !== f || 'instance' === b[0] ? (d = g, !1)  : void 0
      }),
      '#*$~&' !== d ? d : this
    }
    a.tooltipster.__instancesLatestArr = [
    ];
    var e = b[0] && void 0 !== b[0].multiple,
    g = e && b[0].multiple || !e && f.multiple,
    h = b[0] && void 0 !== b[0].content,
    i = h && b[0].content || !h && f.content,
    j = b[0] && void 0 !== b[0].contentCloning,
    k = j && b[0].contentCloning || !j && f.contentCloning,
    l = b[0] && void 0 !== b[0].debug,
    m = l && b[0].debug || !l && f.debug;
    return this.length > 1 && (i instanceof a || 'object' == typeof i && null != i && i.tagName) && !k && m && console.log(c),
    this.each(function () {
      var c = !1,
      d = a(this),
      e = d.data('tooltipster-ns'),
      f = null;
      e ? g ? c = !0 : m && (console.log('Tooltipster: one or more tooltips are already attached to the element below. Ignoring.'), console.log(this))  : c = !0,
      c && (f = new a.Tooltipster(this, b[0]), e || (e = [
      ]), e.push(f.__namespace), d.data('tooltipster-ns', e), d.data(f.__namespace, f), f.__options.functionInit && f.__options.functionInit.call(f, f, {
        origin: this
      }), f._trigger('init')),
      a.tooltipster.__instancesLatestArr.push(f)
    }),
    this
  },
  b.prototype = {
    __init: function (b) {
      this.__$tooltip = b,
      this.__$tooltip.css({
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        top: 0
      }).find('.tooltipster-content').css('overflow', 'auto'),
      this.$container = a('<div class="tooltipster-ruler"></div>').append(this.__$tooltip).appendTo('body')
    },
    __forceRedraw: function () {
      var a = this.__$tooltip.parent();
      this.__$tooltip.detach(),
      this.__$tooltip.appendTo(a)
    },
    constrain: function (a, b) {
      return this.constraints = {
        width: a,
        height: b
      },
      this.__$tooltip.css({
        display: 'block',
        height: '',
        overflow: 'auto',
        width: a
      }),
      this
    },
    destroy: function () {
      this.__$tooltip.detach().find('.tooltipster-content').css({
        display: '',
        overflow: ''
      }),
      this.$container.remove()
    },
    free: function () {
      return this.constraints = null,
      this.__$tooltip.css({
        display: '',
        height: '',
        overflow: 'visible',
        width: ''
      }),
      this
    },
    measure: function () {
      this.__forceRedraw();
      var a = this.__$tooltip[0].getBoundingClientRect(),
      b = {
        size: {
          height: a.height || a.bottom,
          width: a.width || a.right
        }
      };
      if (this.constraints) {
        var c = this.__$tooltip.find('.tooltipster-content'),
        d = this.__$tooltip.outerHeight(),
        e = c[0].getBoundingClientRect(),
        f = {
          height: d <= this.constraints.height,
          width: a.width <= this.constraints.width && e.width >= c[0].scrollWidth - 1
        };
        b.fits = f.height && f.width
      }
      return h.IE && h.IE <= 11 && (b.size.width = Math.ceil(b.size.width) + 1),
      b
    }
  };
  var j = navigator.userAgent.toLowerCase();
  - 1 != j.indexOf('msie') ? h.IE = parseInt(j.split('msie') [1])  : - 1 !== j.toLowerCase().indexOf('trident') && - 1 !== j.indexOf(' rv:11') ? h.IE = 11 : - 1 != j.toLowerCase().indexOf('edge/') && (h.IE = parseInt(j.toLowerCase().split('edge/') [1]));
  var k = 'tooltipster.sideTip';
  return a.tooltipster._plugin({
    name: k,
    instance: {
      __defaults: function () {
        return {
          arrow: !0,
          distance: 6,
          functionPosition: null,
          maxWidth: null,
          minIntersection: 16,
          minWidth: 0,
          position: null,
          side: 'top',
          viewportAware: !0
        }
      },
      __init: function (a) {
        var b = this;
        b.__instance = a,
        b.__namespace = 'tooltipster-sideTip-' + Math.round(1000000 * Math.random()),
        b.__previousState = 'closed',
        b.__options,
        b.__optionsFormat(),
        b.__instance._on('state.' + b.__namespace, function (a) {
          'closed' == a.state ? b.__close()  : 'appearing' == a.state && 'closed' == b.__previousState && b.__create(),
          b.__previousState = a.state
        }),
        b.__instance._on('options.' + b.__namespace, function () {
          b.__optionsFormat()
        }),
        b.__instance._on('reposition.' + b.__namespace, function (a) {
          b.__reposition(a.event, a.helper)
        })
      },
      __close: function () {
        this.__instance.content() instanceof a && this.__instance.content().detach(),
        this.__instance._$tooltip.remove(),
        this.__instance._$tooltip = null
      },
      __create: function () {
        var b = a('<div class="tooltipster-base tooltipster-sidetip"><div class="tooltipster-box"><div class="tooltipster-content"></div></div><div class="tooltipster-arrow"><div class="tooltipster-arrow-uncropped"><div class="tooltipster-arrow-border"></div><div class="tooltipster-arrow-background"></div></div></div></div>');
        this.__options.arrow || b.find('.tooltipster-box').css('margin', 0).end().find('.tooltipster-arrow').hide(),
        this.__options.minWidth && b.css('min-width', this.__options.minWidth + 'px'),
        this.__options.maxWidth && b.css('max-width', this.__options.maxWidth + 'px'),
        this.__instance._$tooltip = b,
        this.__instance._trigger('created')
      },
      __destroy: function () {
        this.__instance._off('.' + self.__namespace)
      },
      __optionsFormat: function () {
        var b = this;
        if (b.__options = b.__instance._optionsExtract(k, b.__defaults()), b.__options.position && (b.__options.side = b.__options.position), 'object' != typeof b.__options.distance && (b.__options.distance = [
          b.__options.distance
        ]), b.__options.distance.length < 4 && (void 0 === b.__options.distance[1] && (b.__options.distance[1] = b.__options.distance[0]), void 0 === b.__options.distance[2] && (b.__options.distance[2] = b.__options.distance[0]), void 0 === b.__options.distance[3] && (b.__options.distance[3] = b.__options.distance[1]), b.__options.distance = {
          top: b.__options.distance[0],
          right: b.__options.distance[1],
          bottom: b.__options.distance[2],
          left: b.__options.distance[3]
        }), 'string' == typeof b.__options.side) {
          var c = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right'
          };
          b.__options.side = [
            b.__options.side,
            c[b.__options.side]
          ],
          'left' == b.__options.side[0] || 'right' == b.__options.side[0] ? b.__options.side.push('top', 'bottom')  : b.__options.side.push('right', 'left')
        }
        6 === a.tooltipster._env.IE && b.__options.arrow !== !0 && (b.__options.arrow = !1)
      },
      __reposition: function (b, c) {
        var d,
        e = this,
        f = e.__targetFind(c),
        g = [
        ];
        e.__instance._$tooltip.detach();
        var h = e.__instance._$tooltip.clone(),
        i = a.tooltipster._getRuler(h),
        j = !1;
        switch (a.each(['window',
          'document'], function (d, k) {
            var l = null;
            if (e.__instance._trigger({
              container: k,
              helper: c,
              satisfied: j,
              takeTest: function (a) {
                l = a
              },
              results: g,
              type: 'positionTest'
            }), 1 == l || 0 != l && 0 == j && ('window' != k || e.__options.viewportAware)) for (var d = 0; d < e.__options.side.length; d++) {
              var m = {
                horizontal: 0,
                vertical: 0
              },
              n = e.__options.side[d];
              'top' == n || 'bottom' == n ? m.vertical = e.__options.distance[n] : m.horizontal = e.__options.distance[n],
              e.__sideChange(h, n),
              a.each(['natural',
              'constrained'], function (a, d) {
                if (l = null, e.__instance._trigger({
                  container: k,
                  event: b,
                  helper: c,
                  mode: d,
                  results: g,
                  satisfied: j,
                  side: n,
                  takeTest: function (a) {
                    l = a
                  },
                  type: 'positionTest'
                }), 1 == l || 0 != l && 0 == j) {
                  var h = {
                    container: k,
                    distance: m,
                    fits: null,
                    mode: d,
                    outerSize: null,
                    side: n,
                    size: null,
                    target: f[n],
                    whole: null
                  },
                  o = 'natural' == d ? i.free()  : i.constrain(c.geo.available[k][n].width - m.horizontal, c.geo.available[k][n].height - m.vertical),
                  p = o.measure();
                  if (h.size = p.size, h.outerSize = {
                    height: p.size.height + m.vertical,
                    width: p.size.width + m.horizontal
                  }, 'natural' == d ? c.geo.available[k][n].width >= h.outerSize.width && c.geo.available[k][n].height >= h.outerSize.height ? h.fits = !0 : h.fits = !1 : h.fits = p.fits, 'window' == k && (h.fits ? 'top' == n || 'bottom' == n ? h.whole = c.geo.origin.windowOffset.right >= e.__options.minIntersection && c.geo.window.size.width - c.geo.origin.windowOffset.left >= e.__options.minIntersection : h.whole = c.geo.origin.windowOffset.bottom >= e.__options.minIntersection && c.geo.window.size.height - c.geo.origin.windowOffset.top >= e.__options.minIntersection : h.whole = !1), g.push(h), h.whole) j = !0;
                   else if ('natural' == h.mode && (h.fits || h.size.width <= c.geo.available[k][n].width)) return !1
                }
              })
            }
          }), e.__instance._trigger({
            edit: function (a) {
              g = a
            },
            event: b,
            helper: c,
            results: g,
            type: 'positionTested'
          }), g.sort(function (a, b) {
            if (a.whole && !b.whole) return - 1;
            if (!a.whole && b.whole) return 1;
            if (a.whole && b.whole) {
              var c = e.__options.side.indexOf(a.side),
              d = e.__options.side.indexOf(b.side);
              return d > c ? - 1 : c > d ? 1 : 'natural' == a.mode ? - 1 : 1
            }
            if (a.fits && !b.fits) return - 1;
            if (!a.fits && b.fits) return 1;
            if (a.fits && b.fits) {
              var c = e.__options.side.indexOf(a.side),
              d = e.__options.side.indexOf(b.side);
              return d > c ? - 1 : c > d ? 1 : 'natural' == a.mode ? - 1 : 1
            }
            return 'document' == a.container && 'bottom' == a.side && 'natural' == a.mode ? - 1 : 1
          }), d = g[0], d.coord = {
          }, d.side) {
          case 'left':
          case 'right':
            d.coord.top = Math.floor(d.target - d.size.height / 2);
            break;
          case 'bottom':
          case 'top':
            d.coord.left = Math.floor(d.target - d.size.width / 2)
        }
        switch (d.side) {
          case 'left':
            d.coord.left = c.geo.origin.windowOffset.left - d.outerSize.width;
            break;
          case 'right':
            d.coord.left = c.geo.origin.windowOffset.right + d.distance.horizontal;
            break;
          case 'top':
            d.coord.top = c.geo.origin.windowOffset.top - d.outerSize.height;
            break;
          case 'bottom':
            d.coord.top = c.geo.origin.windowOffset.bottom + d.distance.vertical
        }
        'window' == d.container ? 'top' == d.side || 'bottom' == d.side ? d.coord.left < 0 ? c.geo.origin.windowOffset.right - this.__options.minIntersection >= 0 ? d.coord.left = 0 : d.coord.left = c.geo.origin.windowOffset.right - this.__options.minIntersection - 1 : d.coord.left > c.geo.window.size.width - d.size.width && (c.geo.origin.windowOffset.left + this.__options.minIntersection <= c.geo.window.size.width ? d.coord.left = c.geo.window.size.width - d.size.width : d.coord.left = c.geo.origin.windowOffset.left + this.__options.minIntersection + 1 - d.size.width)  : d.coord.top < 0 ? c.geo.origin.windowOffset.bottom - this.__options.minIntersection >= 0 ? d.coord.top = 0 : d.coord.top = c.geo.origin.windowOffset.bottom - this.__options.minIntersection - 1 : d.coord.top > c.geo.window.size.height - d.size.height && (c.geo.origin.windowOffset.top + this.__options.minIntersection <= c.geo.window.size.height ? d.coord.top = c.geo.window.size.height - d.size.height : d.coord.top = c.geo.origin.windowOffset.top + this.__options.minIntersection + 1 - d.size.height)  : (d.coord.left > c.geo.window.size.width - d.size.width && (d.coord.left = c.geo.window.size.width - d.size.width), d.coord.left < 0 && (d.coord.left = 0)),
        e.__sideChange(h, d.side),
        c.tooltipClone = h[0],
        c.tooltipParent = e.__instance.option('parent').parent[0],
        c.mode = d.mode,
        c.whole = d.whole,
        c.origin = e.__instance._$origin[0],
        c.tooltip = e.__instance._$tooltip[0],
        delete d.container,
        delete d.fits,
        delete d.mode,
        delete d.outerSize,
        delete d.whole,
        d.distance = d.distance.horizontal || d.distance.vertical;
        var k = a.extend(!0, {
        }, d);
        if (e.__instance._trigger({
          edit: function (a) {
            d = a
          },
          event: b,
          helper: c,
          position: k,
          type: 'position'
        }), e.__options.functionPosition) {
          var l = e.__options.functionPosition.call(e, e.__instance, c, k);
          l && (d = l)
      }
      i.destroy();
      var m,
      n;
      'top' == d.side || 'bottom' == d.side ? (m = {
        prop: 'left',
        val: d.target - d.coord.left
      }, n = d.size.width - this.__options.minIntersection)  : (m = {
        prop: 'top',
        val: d.target - d.coord.top
      }, n = d.size.height - this.__options.minIntersection),
      m.val < this.__options.minIntersection ? m.val = this.__options.minIntersection : m.val > n && (m.val = n);
      var o;
      o = c.geo.origin.fixedLineage ? c.geo.origin.windowOffset : {
        left: c.geo.origin.windowOffset.left + c.geo.window.scroll.left,
        top: c.geo.origin.windowOffset.top + c.geo.window.scroll.top
    },
    d.coord = {
      left: o.left + (d.coord.left - c.geo.origin.windowOffset.left),
      top: o.top + (d.coord.top - c.geo.origin.windowOffset.top)
  },
  e.__sideChange(e.__instance._$tooltip, d.side),
  c.geo.origin.fixedLineage ? e.__instance._$tooltip.css('position', 'fixed')  : e.__instance._$tooltip.css('position', ''),
  e.__instance._$tooltip.css({
    left: d.coord.left,
    top: d.coord.top,
    height: d.size.height,
    width: d.size.width
  }).find('.tooltipster-arrow').css({
    left: '',
    top: ''
  }).css(m.prop, m.val),
  e.__instance._$tooltip.appendTo(e.__instance.option('parent')),
  e.__instance._trigger({
    type: 'repositioned',
    event: b,
    position: d
  })
},
__sideChange: function (a, b) {
a.removeClass('tooltipster-bottom').removeClass('tooltipster-left').removeClass('tooltipster-right').removeClass('tooltipster-top').addClass('tooltipster-' + b)
},
__targetFind: function (a) {
var b = {
},
c = this.__instance._$origin[0].getClientRects();
if (c.length > 1) {
var d = this.__instance._$origin.css('opacity');
1 == d && (this.__instance._$origin.css('opacity', 0.99), c = this.__instance._$origin[0].getClientRects(), this.__instance._$origin.css('opacity', 1))
}
if (c.length < 2) b.top = Math.floor(a.geo.origin.windowOffset.left + a.geo.origin.size.width / 2),
b.bottom = b.top,
b.left = Math.floor(a.geo.origin.windowOffset.top + a.geo.origin.size.height / 2),
b.right = b.left;
 else {
var e = c[0];
b.top = Math.floor(e.left + (e.right - e.left) / 2),
e = c.length > 2 ? c[Math.ceil(c.length / 2) - 1] : c[0],
b.right = Math.floor(e.top + (e.bottom - e.top) / 2),
e = c[c.length - 1],
b.bottom = Math.floor(e.left + (e.right - e.left) / 2),
e = c.length > 2 ? c[Math.ceil((c.length + 1) / 2) - 1] : c[c.length - 1],
b.left = Math.floor(e.top + (e.bottom - e.top) / 2)
}
return b
}
}
}),
a
}),
$(document).ready(function () {
$('.tooltip-wrapper').each(function () {
var a = $(this),
b = a.find('.tooltip-toggler'),
c = a.find('.tooltip-content');
b.tooltipster({
theme: 'tooltipster-light',
content: c.html(),
functionInit: function (a, b) {
c.detach(),
c.show(),
a.content(c)
},
maxWidth: 400,
trigger: 'custom',
triggerOpen: {
mouseenter: !0
},
triggerClose: {
click: !1,
touchleave: !1,
tap: !1,
mouseleave: !0,
originClick: !1
}
}).attr('tabindex', '0').on('touchstart', function () {
b.focus()
}).on('focus', function () {
b.tooltipster('open')
}).on('blur', function () {
b.tooltipster('close')
})
})
}),
function (a, b) {
a.Bloodhound = b(jQuery)
}(this, function (a) {
var b = function () {
'use strict';
return {
isMsie: function () {
return !!/(msie|trident)/i.test(navigator.userAgent) && navigator.userAgent.match(/(msie |rv:)(\d+(.\d+)?)/i) [2]
},
isBlankString: function (a) {
return !a || /^\s*$/.test(a)
},
escapeRegExChars: function (a) {
return a.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
},
isString: function (a) {
return 'string' == typeof a
},
isNumber: function (a) {
return 'number' == typeof a
},
isArray: a.isArray,
isFunction: a.isFunction,
isObject: a.isPlainObject,
isUndefined: function (a) {
return 'undefined' == typeof a
},
isElement: function (a) {
return !(!a || 1 !== a.nodeType)
},
isJQuery: function (b) {
return b instanceof a
},
toStr: function (a) {
return b.isUndefined(a) || null === a ? '' : a + ''
},
bind: a.proxy,
each: function (b, c) {
function d(a, b) {
return c(b, a)
}
a.each(b, d)
},
map: a.map,
filter: a.grep,
every: function (b, c) {
var d = !0;
return b ? (a.each(b, function (a, e) {
if (!(d = c.call(null, e, a, b))) return !1
}), !!d)  : d
},
some: function (b, c) {
var d = !1;
return b ? (a.each(b, function (a, e) {
if (d = c.call(null, e, a, b)) return !1
}), !!d)  : d
},
mixin: a.extend,
identity: function (a) {
return a
},
clone: function (b) {
return a.extend(!0, {
}, b)
},
getIdGenerator: function () {
var a = 0;
return function () {
return a++
}
},
templatify: function (b) {
function c() {
return String(b)
}
return a.isFunction(b) ? b : c
},
defer: function (a) {
setTimeout(a, 0)
},
debounce: function (a, b, c) {
var d,
e;
return function () {
var f,
g,
h = this,
i = arguments;
return f = function () {
d = null,
c || (e = a.apply(h, i))
},
g = c && !d,
clearTimeout(d),
d = setTimeout(f, b),
g && (e = a.apply(h, i)),
e
}
},
throttle: function (a, b) {
var c,
d,
e,
f,
g,
h;
return g = 0,
h = function () {
g = new Date,
e = null,
f = a.apply(c, d)
},
function () {
var i = new Date,
j = b - (i - g);
return c = this,
d = arguments,
j <= 0 ? (clearTimeout(e), e = null, g = i, f = a.apply(c, d))  : e || (e = setTimeout(h, j)),
f
}
},
stringify: function (a) {
return b.isString(a) ? a : JSON.stringify(a)
},
noop: function () {
}
}
}(),
c = '0.11.1',
d = function () {
'use strict';
function a(a) {
return a = b.toStr(a),
a ? a.split(/\s+/)  : [
]
}
function c(a) {
return a = b.toStr(a),
a ? a.split(/\W+/)  : [
]
}
function d(a) {
return function (c) {
return c = b.isArray(c) ? c : [
].slice.call(arguments, 0),
function (d) {
var e = [
];
return b.each(c, function (c) {
e = e.concat(a(b.toStr(d[c])))
}),
e
}
}
}
return {
nonword: c,
whitespace: a,
obj: {
nonword: d(c),
whitespace: d(a)
}
}
}(),
e = function () {
'use strict';
function c(c) {
this.maxSize = b.isNumber(c) ? c : 100,
this.reset(),
this.maxSize <= 0 && (this.set = this.get = a.noop)
}
function d() {
this.head = this.tail = null
}
function e(a, b) {
this.key = a,
this.val = b,
this.prev = this.next = null
}
return b.mixin(c.prototype, {
set: function (a, b) {
var c,
d = this.list.tail;
this.size >= this.maxSize && (this.list.remove(d), delete this.hash[d.key], this.size--),
(c = this.hash[a]) ? (c.val = b, this.list.moveToFront(c))  : (c = new e(a, b), this.list.add(c), this.hash[a] = c, this.size++)
},
get: function (a) {
var b = this.hash[a];
if (b) return this.list.moveToFront(b),
b.val
},
reset: function () {
this.size = 0,
this.hash = {
},
this.list = new d
}
}),
b.mixin(d.prototype, {
add: function (a) {
this.head && (a.next = this.head, this.head.prev = a),
this.head = a,
this.tail = this.tail || a
},
remove: function (a) {
a.prev ? a.prev.next = a.next : this.head = a.next,
a.next ? a.next.prev = a.prev : this.tail = a.prev
},
moveToFront: function (a) {
this.remove(a),
this.add(a)
}
}),
c
}(),
f = function () {
'use strict';
function c(a, c) {
this.prefix = [
'__',
a,
'__'
].join(''),
this.ttlKey = '__ttl__',
this.keyMatcher = new RegExp('^' + b.escapeRegExChars(this.prefix)),
this.ls = c || h,
!this.ls && this._noop()
}
function d() {
return (new Date).getTime()
}
function e(a) {
return JSON.stringify(b.isUndefined(a) ? null : a)
}
function f(b) {
return a.parseJSON(b)
}
function g(a) {
var b,
c,
d = [
],
e = h.length;
for (b = 0; b < e; b++) (c = h.key(b)).match(a) && d.push(c.replace(a, ''));
return d
}
var h;
try {
h = window.localStorage,
h.setItem('~~~', '!'),
h.removeItem('~~~')
} catch (a) {
h = null
}
return b.mixin(c.prototype, {
_prefix: function (a) {
return this.prefix + a
},
_ttlKey: function (a) {
return this._prefix(a) + this.ttlKey
},
_noop: function () {
this.get = this.set = this.remove = this.clear = this.isExpired = b.noop
},
_safeSet: function (a, b) {
try {
this.ls.setItem(a, b)
} catch (a) {
'QuotaExceededError' === a.name && (this.clear(), this._noop())
}
},
get: function (a) {
return this.isExpired(a) && this.remove(a),
f(this.ls.getItem(this._prefix(a)))
},
set: function (a, c, f) {
return b.isNumber(f) ? this._safeSet(this._ttlKey(a), e(d() + f))  : this.ls.removeItem(this._ttlKey(a)),
this._safeSet(this._prefix(a), e(c))
},
remove: function (a) {
return this.ls.removeItem(this._ttlKey(a)),
this.ls.removeItem(this._prefix(a)),
this
},
clear: function () {
var a,
b = g(this.keyMatcher);
for (a = b.length; a--; ) this.remove(b[a]);
return this
},
isExpired: function (a) {
var c = f(this.ls.getItem(this._ttlKey(a)));
return !!(b.isNumber(c) && d() > c)
}
}),
c
}(),
g = function () {
'use strict';
function c(a) {
a = a || {
},
this.cancelled = !1,
this.lastReq = null,
this._send = a.transport,
this._get = a.limiter ? a.limiter(this._get)  : this._get,
this._cache = a.cache === !1 ? new e(0)  : h
}
var d = 0,
f = {
},
g = 6,
h = new e(10);
return c.setMaxPendingRequests = function (a) {
g = a
},
c.resetCache = function () {
h.reset()
},
b.mixin(c.prototype, {
_fingerprint: function (b) {
return b = b || {
},
b.url + b.type + a.param(b.data || {
})
},
_get: function (a, b) {
function c(a) {
b(null, a),
k._cache.set(i, a)
}
function e() {
b(!0)
}
function h() {
d--,
delete f[i],
k.onDeckRequestArgs && (k._get.apply(k, k.onDeckRequestArgs), k.onDeckRequestArgs = null)
}
var i,
j,
k = this;
i = this._fingerprint(a),
this.cancelled || i !== this.lastReq || ((j = f[i]) ? j.done(c).fail(e)  : d < g ? (d++, f[i] = this._send(a).done(c).fail(e).always(h))  : this.onDeckRequestArgs = [
].slice.call(arguments, 0))
},
get: function (c, d) {
var e,
f;
d = d || a.noop,
c = b.isString(c) ? {
url: c
}
 : c || {
},
f = this._fingerprint(c),
this.cancelled = !1,
this.lastReq = f,
(e = this._cache.get(f)) ? d(null, e)  : this._get(c, d)
},
cancel: function () {
this.cancelled = !0
}
}),
c
}(),
h = window.SearchIndex = function () {
'use strict';
function c(c) {
c = c || {
},
c.datumTokenizer && c.queryTokenizer || a.error('datumTokenizer and queryTokenizer are both required'),
this.identify = c.identify || b.stringify,
this.datumTokenizer = c.datumTokenizer,
this.queryTokenizer = c.queryTokenizer,
this.reset()
}
function d(a) {
return a = b.filter(a, function (a) {
return !!a
}),
a = b.map(a, function (a) {
return a.toLowerCase()
})
}
function e() {
var a = {
};
return a[i] = [
],
a[h] = {
},
a
}
function f(a) {
for (var b = {
}, c = [
], d = 0, e = a.length; d < e; d++) b[a[d]] || (b[a[d]] = !0, c.push(a[d]));
return c
}
function g(a, b) {
var c = 0,
d = 0,
e = [
];
a = a.sort(),
b = b.sort();
for (var f = a.length, g = b.length; c < f && d < g; ) a[c] < b[d] ? c++ : a[c] > b[d] ? d++ : (e.push(a[c]), c++, d++);
return e
}
var h = 'c',
i = 'i';
return b.mixin(c.prototype, {
bootstrap: function (a) {
this.datums = a.datums,
this.trie = a.trie
},
add: function (a) {
var c = this;
a = b.isArray(a) ? a : [
a
],
b.each(a, function (a) {
var f,
g;
c.datums[f = c.identify(a)] = a,
g = d(c.datumTokenizer(a)),
b.each(g, function (a) {
var b,
d,
g;
for (b = c.trie, d = a.split(''); g = d.shift(); ) b = b[h][g] || (b[h][g] = e()),
b[i].push(f)
})
})
},
get: function (a) {
var c = this;
return b.map(a, function (a) {
return c.datums[a]
})
},
search: function (a) {
var c,
e,
j = this;
return c = d(this.queryTokenizer(a)),
b.each(c, function (a) {
var b,
c,
d,
f;
if (e && 0 === e.length) return !1;
for (b = j.trie, c = a.split(''); b && (d = c.shift()); ) b = b[h][d];
return b && 0 === c.length ? (f = b[i].slice(0), void (e = e ? g(e, f)  : f))  : (e = [
], !1)
}),
e ? b.map(f(e), function (a) {
return j.datums[a]
})  : [
]
},
all: function () {
var a = [
];
for (var b in this.datums) a.push(this.datums[b]);
return a
},
reset: function () {
this.datums = {
},
this.trie = e()
},
serialize: function () {
return {
datums: this.datums,
trie: this.trie
}
}
}),
c
}(),
i = function () {
'use strict';
function a(a) {
this.url = a.url,
this.ttl = a.ttl,
this.cache = a.cache,
this.prepare = a.prepare,
this.transform = a.transform,
this.transport = a.transport,
this.thumbprint = a.thumbprint,
this.storage = new f(a.cacheKey)
}
var c;
return c = {
data: 'data',
protocol: 'protocol',
thumbprint: 'thumbprint'
},
b.mixin(a.prototype, {
_settings: function () {
return {
url: this.url,
type: 'GET',
dataType: 'json'
}
},
store: function (a) {
this.cache && (this.storage.set(c.data, a, this.ttl), this.storage.set(c.protocol, location.protocol, this.ttl), this.storage.set(c.thumbprint, this.thumbprint, this.ttl))
},
fromCache: function () {
var a,
b = {
};
return this.cache ? (b.data = this.storage.get(c.data), b.protocol = this.storage.get(c.protocol), b.thumbprint = this.storage.get(c.thumbprint), a = b.thumbprint !== this.thumbprint || b.protocol !== location.protocol, b.data && !a ? b.data : null)  : null
},
fromNetwork: function (a) {
function b() {
a(!0)
}
function c(b) {
a(null, e.transform(b))
}
var d,
e = this;
a && (d = this.prepare(this._settings()), this.transport(d).fail(b).done(c))
},
clear: function () {
return this.storage.clear(),
this
}
}),
a
}(),
j = function () {
'use strict';
function a(a) {
this.url = a.url,
this.prepare = a.prepare,
this.transform = a.transform,
this.transport = new g({
cache: a.cache,
limiter: a.limiter,
transport: a.transport
})
}
return b.mixin(a.prototype, {
_settings: function () {
return {
url: this.url,
type: 'GET',
dataType: 'json'
}
},
get: function (a, b) {
function c(a, c) {
b(a ? [
] : e.transform(c))
}
var d,
e = this;
if (b) return a = a || '',
d = this.prepare(a, this._settings()),
this.transport.get(d, c)
},
cancelLastRequest: function () {
this.transport.cancel()
}
}),
a
}(),
k = function () {
'use strict';
function d(d) {
var e;
return d ? (e = {
url: null,
ttl: 86400000,
cache: !0,
cacheKey: null,
thumbprint: '',
prepare: b.identity,
transform: b.identity,
transport: null
}, d = b.isString(d) ? {
url: d
}
 : d, d = b.mixin(e, d), !d.url && a.error('prefetch requires url to be set'), d.transform = d.filter || d.transform, d.cacheKey = d.cacheKey || d.url, d.thumbprint = c + d.thumbprint, d.transport = d.transport ? h(d.transport)  : a.ajax, d)  : null
}
function e(c) {
var d;
if (c) return d = {
url: null,
cache: !0,
prepare: null,
replace: null,
wildcard: null,
limiter: null,
rateLimitBy: 'debounce',
rateLimitWait: 300,
transform: b.identity,
transport: null
},
c = b.isString(c) ? {
url: c
}
 : c,
c = b.mixin(d, c),
!c.url && a.error('remote requires url to be set'),
c.transform = c.filter || c.transform,
c.prepare = f(c),
c.limiter = g(c),
c.transport = c.transport ? h(c.transport)  : a.ajax,
delete c.replace,
delete c.wildcard,
delete c.rateLimitBy,
delete c.rateLimitWait,
c
}
function f(a) {
function b(a, b) {
return b.url = f(b.url, a),
b
}
function c(a, b) {
return b.url = b.url.replace(g, encodeURIComponent(a)),
b
}
function d(a, b) {
return b
}
var e,
f,
g;
return e = a.prepare,
f = a.replace,
g = a.wildcard,
e ? e : e = f ? b : a.wildcard ? c : d
}
function g(a) {
function c(a) {
return function (c) {
return b.debounce(c, a)
}
}
function d(a) {
return function (c) {
return b.throttle(c, a)
}
}
var e,
f,
g;
return e = a.limiter,
f = a.rateLimitBy,
g = a.rateLimitWait,
e || (e = /^throttle$/i.test(f) ? d(g)  : c(g)),
e
}
function h(c) {
return function (d) {
function e(a) {
b.defer(function () {
g.resolve(a)
})
}
function f(a) {
b.defer(function () {
g.reject(a)
})
}
var g = a.Deferred();
return c(d, e, f),
g
}
}
return function (c) {
var f,
g;
return f = {
initialize: !0,
identify: b.stringify,
datumTokenizer: null,
queryTokenizer: null,
sufficient: 5,
sorter: null,
local: [
],
prefetch: null,
remote: null
},
c = b.mixin(f, c || {
}),
!c.datumTokenizer && a.error('datumTokenizer is required'),
!c.queryTokenizer && a.error('queryTokenizer is required'),
g = c.sorter,
c.sorter = g ? function (a) {
return a.sort(g)
}
 : b.identity,
c.local = b.isFunction(c.local) ? c.local()  : c.local,
c.prefetch = d(c.prefetch),
c.remote = e(c.remote),
c
}
}(),
l = function () {
'use strict';
function c(a) {
a = k(a),
this.sorter = a.sorter,
this.identify = a.identify,
this.sufficient = a.sufficient,
this.local = a.local,
this.remote = a.remote ? new j(a.remote)  : null,
this.prefetch = a.prefetch ? new i(a.prefetch)  : null,
this.index = new h({
identify: this.identify,
datumTokenizer: a.datumTokenizer,
queryTokenizer: a.queryTokenizer
}),
a.initialize !== !1 && this.initialize()
}
var e;
return e = window && window.Bloodhound,
c.noConflict = function () {
return window && (window.Bloodhound = e),
c
},
c.tokenizers = d,
b.mixin(c.prototype, {
__ttAdapter: function () {
function a(a, b, d) {
return c.search(a, b, d)
}
function b(a, b) {
return c.search(a, b)
}
var c = this;
return this.remote ? a : b
},
_loadPrefetch: function () {
function b(a, b) {
return a ? c.reject()  : (e.add(b), e.prefetch.store(e.index.serialize()), void c.resolve())
}
var c,
d,
e = this;
return c = a.Deferred(),
this.prefetch ? (d = this.prefetch.fromCache()) ? (this.index.bootstrap(d), c.resolve())  : this.prefetch.fromNetwork(b)  : c.resolve(),
c.promise()
},
_initialize: function () {
function a() {
b.add(b.local)
}
var b = this;
return this.clear(),
(this.initPromise = this._loadPrefetch()).done(a),
this.initPromise
},
initialize: function (a) {
return !this.initPromise || a ? this._initialize()  : this.initPromise
},
add: function (a) {
return this.index.add(a),
this
},
get: function (a) {
return a = b.isArray(a) ? a : [
].slice.call(arguments),
this.index.get(a)
},
search: function (a, c, d) {
function e(a) {
var c = [
];
b.each(a, function (a) {
!b.some(f, function (b) {
  return g.identify(a) === g.identify(b)
}) && c.push(a)
}),
d && d(c)
}
var f,
g = this;
return f = this.sorter(this.index.search(a)),
c(this.remote ? f.slice()  : f),
this.remote && f.length < this.sufficient ? this.remote.get(a, e)  : this.remote && this.remote.cancelLastRequest(),
this
},
all: function () {
return this.index.all()
},
clear: function () {
return this.index.reset(),
this
},
clearPrefetchCache: function () {
return this.prefetch && this.prefetch.clear(),
this
},
clearRemoteCache: function () {
return g.resetCache(),
this
},
ttAdapter: function () {
return this.__ttAdapter()
}
}),
c
}();
return l
}),
function (a, b) {
b(jQuery)
}(this, function (a) {
var b = function () {
'use strict';
return {
isMsie: function () {
return !!/(msie|trident)/i.test(navigator.userAgent) && navigator.userAgent.match(/(msie |rv:)(\d+(.\d+)?)/i) [2]
},
isBlankString: function (a) {
return !a || /^\s*$/.test(a)
},
escapeRegExChars: function (a) {
return a.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
},
isString: function (a) {
return 'string' == typeof a
},
isNumber: function (a) {
return 'number' == typeof a
},
isArray: a.isArray,
isFunction: a.isFunction,
isObject: a.isPlainObject,
isUndefined: function (a) {
return 'undefined' == typeof a
},
isElement: function (a) {
return !(!a || 1 !== a.nodeType)
},
isJQuery: function (b) {
return b instanceof a
},
toStr: function (a) {
return b.isUndefined(a) || null === a ? '' : a + ''
},
bind: a.proxy,
each: function (b, c) {
function d(a, b) {
return c(b, a)
}
a.each(b, d)
},
map: a.map,
filter: a.grep,
every: function (b, c) {
var d = !0;
return b ? (a.each(b, function (a, e) {
if (!(d = c.call(null, e, a, b))) return !1
}), !!d)  : d
},
some: function (b, c) {
var d = !1;
return b ? (a.each(b, function (a, e) {
if (d = c.call(null, e, a, b)) return !1
}), !!d)  : d
},
mixin: a.extend,
identity: function (a) {
return a
},
clone: function (b) {
return a.extend(!0, {
}, b)
},
getIdGenerator: function () {
var a = 0;
return function () {
return a++
}
},
templatify: function (b) {
function c() {
return String(b)
}
return a.isFunction(b) ? b : c
},
defer: function (a) {
setTimeout(a, 0)
},
debounce: function (a, b, c) {
var d,
e;
return function () {
var f,
g,
h = this,
i = arguments;
return f = function () {
d = null,
c || (e = a.apply(h, i))
},
g = c && !d,
clearTimeout(d),
d = setTimeout(f, b),
g && (e = a.apply(h, i)),
e
}
},
throttle: function (a, b) {
var c,
d,
e,
f,
g,
h;
return g = 0,
h = function () {
g = new Date,
e = null,
f = a.apply(c, d)
},
function () {
var i = new Date,
j = b - (i - g);
return c = this,
d = arguments,
j <= 0 ? (clearTimeout(e), e = null, g = i, f = a.apply(c, d))  : e || (e = setTimeout(h, j)),
f
}
},
stringify: function (a) {
return b.isString(a) ? a : JSON.stringify(a)
},
noop: function () {
}
}
}(),
c = function () {
'use strict';
function a(a) {
var g,
h;
return h = b.mixin({
}, f, a),
g = {
css: e(),
classes: h,
html: c(h),
selectors: d(h)
},
{
css: g.css,
html: g.html,
classes: g.classes,
selectors: g.selectors,
mixin: function (a) {
b.mixin(a, g)
}
}
}
function c(a) {
return {
wrapper: '<span class="' + a.wrapper + '"></span>',
menu: '<div class="' + a.menu + '"></div>'
}
}
function d(a) {
var c = {
};
return b.each(a, function (a, b) {
c[b] = '.' + a
}),
c
}
function e() {
var a = {
wrapper: {
position: 'relative',
display: 'inline-block'
},
hint: {
position: 'absolute',
top: '0',
left: '0',
borderColor: 'transparent',
boxShadow: 'none',
opacity: '1'
},
input: {
position: 'relative',
verticalAlign: 'top',
backgroundColor: 'transparent'
},
inputWithNoHint: {
position: 'relative',
verticalAlign: 'top'
},
menu: {
position: 'absolute',
top: '100%',
left: '0',
zIndex: '100',
display: 'none'
},
ltr: {
left: '0',
right: 'auto'
},
rtl: {
left: 'auto',
right: ' 0'
}
};
return b.isMsie() && b.mixin(a.input, {
backgroundImage: 'url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7)'
}),
a
}
var f = {
wrapper: 'twitter-typeahead',
input: 'tt-input',
hint: 'tt-hint',
menu: 'tt-menu',
dataset: 'tt-dataset',
suggestion: 'tt-suggestion',
selectable: 'tt-selectable',
empty: 'tt-empty',
open: 'tt-open',
cursor: 'tt-cursor',
highlight: 'tt-highlight'
};
return a
}(),
d = function () {
'use strict';
function c(b) {
b && b.el || a.error('EventBus initialized without el'),
this.$el = a(b.el)
}
var d,
e;
return d = 'typeahead:',
e = {
render: 'rendered',
cursorchange: 'cursorchanged',
select: 'selected',
autocomplete: 'autocompleted'
},
b.mixin(c.prototype, {
_trigger: function (b, c) {
var e;
return e = a.Event(d + b),
(c = c || [
]).unshift(e),
this.$el.trigger.apply(this.$el, c),
e
},
before: function (a) {
var b,
c;
return b = [
].slice.call(arguments, 1),
c = this._trigger('before' + a, b),
c.isDefaultPrevented()
},
trigger: function (a) {
var b;
this._trigger(a, [
].slice.call(arguments, 1)),
(b = e[a]) && this._trigger(b, [
].slice.call(arguments, 1))
}
}),
c
}(),
e = function () {
'use strict';
function a(a, b, c, d) {
var e;
if (!c) return this;
for (b = b.split(i), c = d ? h(c, d)  : c, this._callbacks = this._callbacks || {
}; e = b.shift(); ) this._callbacks[e] = this._callbacks[e] || {
sync: [
],
async: [
]
},
this._callbacks[e][a].push(c);
return this
}
function b(b, c, d) {
return a.call(this, 'async', b, c, d)
}
function c(b, c, d) {
return a.call(this, 'sync', b, c, d)
}
function d(a) {
var b;
if (!this._callbacks) return this;
for (a = a.split(i); b = a.shift(); ) delete this._callbacks[b];
return this
}
function e(a) {
var b,
c,
d,
e,
g;
if (!this._callbacks) return this;
for (a = a.split(i), d = [
].slice.call(arguments, 1); (b = a.shift()) && (c = this._callbacks[b]); ) e = f(c.sync, this, [
b
].concat(d)),
g = f(c.async, this, [
b
].concat(d)),
e() && j(g);
return this
}
function f(a, b, c) {
function d() {
for (var d, e = 0, f = a.length; !d && e < f; e += 1) d = a[e].apply(b, c) === !1;
return !d
}
return d
}
function g() {
var a;
return a = window.setImmediate ? function (a) {
setImmediate(function () {
a()
})
}
 : function (a) {
setTimeout(function () {
a()
}, 0)
}
}
function h(a, b) {
return a.bind ? a.bind(b)  : function () {
a.apply(b, [
].slice.call(arguments, 0))
}
}
var i = /\s+/,
j = g();
return {
onSync: c,
onAsync: b,
off: d,
trigger: e
}
}(),
f = function (a) {
'use strict';
function c(a, c, d) {
for (var e, f = [
], g = 0, h = a.length; g < h; g++) f.push(b.escapeRegExChars(a[g]));
return e = d ? '\\b(' + f.join('|') + ')\\b' : '(' + f.join('|') + ')',
c ? new RegExp(e)  : new RegExp(e, 'i')
}
var d = {
node: null,
pattern: null,
tagName: 'strong',
className: null,
wordsOnly: !1,
caseSensitive: !1
};
return function (e) {
function f(b) {
var c,
d,
f;
return (c = h.exec(b.data)) && (f = a.createElement(e.tagName), e.className && (f.className = e.className), d = b.splitText(c.index), d.splitText(c[0].length), f.appendChild(d.cloneNode(!0)), b.parentNode.replaceChild(f, d)),
!!c
}
function g(a, b) {
for (var c, d = 3, e = 0; e < a.childNodes.length; e++) c = a.childNodes[e],
c.nodeType === d ? e += b(c) ? 1 : 0 : g(c, b)
}
var h;
e = b.mixin({
}, d, e),
e.node && e.pattern && (e.pattern = b.isArray(e.pattern) ? e.pattern : [
e.pattern
], h = c(e.pattern, e.caseSensitive, e.wordsOnly), g(e.node, f))
}
}(window.document),
g = function () {
'use strict';
function c(c, e) {
c = c || {
},
c.input || a.error('input is missing'),
e.mixin(this),
this.$hint = a(c.hint),
this.$input = a(c.input),
this.query = this.$input.val(),
this.queryWhenFocused = this.hasFocus() ? this.query : null,
this.$overflowHelper = d(this.$input),
this._checkLanguageDirection(),
0 === this.$hint.length && (this.setHint = this.getHint = this.clearHint = this.clearHintIfInvalid = b.noop)
}
function d(b) {
return a('<pre aria-hidden="true"></pre>').css({
position: 'absolute',
visibility: 'hidden',
whiteSpace: 'pre',
fontFamily: b.css('font-family'),
fontSize: b.css('font-size'),
fontStyle: b.css('font-style'),
fontVariant: b.css('font-variant'),
fontWeight: b.css('font-weight'),
wordSpacing: b.css('word-spacing'),
letterSpacing: b.css('letter-spacing'),
textIndent: b.css('text-indent'),
textRendering: b.css('text-rendering'),
textTransform: b.css('text-transform')
}).insertAfter(b)
}
function f(a, b) {
return c.normalizeQuery(a) === c.normalizeQuery(b)
}
function g(a) {
return a.altKey || a.ctrlKey || a.metaKey || a.shiftKey
}
var h;
return h = {
9: 'tab',
27: 'esc',
37: 'left',
39: 'right',
13: 'enter',
38: 'up',
40: 'down'
},
c.normalizeQuery = function (a) {
return b.toStr(a).replace(/^\s*/g, '').replace(/\s{2,}/g, ' ')
},
b.mixin(c.prototype, e, {
_onBlur: function () {
this.resetInputValue(),
this.trigger('blurred')
},
_onFocus: function () {
this.queryWhenFocused = this.query,
this.trigger('focused')
},
_onKeydown: function (a) {
var b = h[a.which || a.keyCode];
this._managePreventDefault(b, a),
b && this._shouldTrigger(b, a) && this.trigger(b + 'Keyed', a)
},
_onInput: function () {
this._setQuery(this.getInputValue()),
this.clearHintIfInvalid(),
this._checkLanguageDirection()
},
_managePreventDefault: function (a, b) {
var c;
switch (a) {
case 'up':
case 'down':
c = !g(b);
break;
default:
c = !1
}
c && b.preventDefault()
},
_shouldTrigger: function (a, b) {
var c;
switch (a) {
case 'tab':
c = !g(b);
break;
default:
c = !0
}
return c
},
_checkLanguageDirection: function () {
var a = (this.$input.css('direction') || 'ltr').toLowerCase();
this.dir !== a && (this.dir = a, this.$hint.attr('dir', a), this.trigger('langDirChanged', a))
},
_setQuery: function (a, b) {
var c,
d;
c = f(a, this.query),
d = !!c && this.query.length !== a.length,
this.query = a,
b || c ? !b && d && this.trigger('whitespaceChanged', this.query)  : this.trigger('queryChanged', this.query)
},
bind: function () {
var a,
c,
d,
e,
f = this;
return a = b.bind(this._onBlur, this),
c = b.bind(this._onFocus, this),
d = b.bind(this._onKeydown, this),
e = b.bind(this._onInput, this),
this.$input.on('blur.tt', a).on('focus.tt', c).on('keydown.tt', d),
!b.isMsie() || b.isMsie() > 9 ? this.$input.on('input.tt', e)  : this.$input.on('keydown.tt keypress.tt cut.tt paste.tt', function (a) {
h[a.which || a.keyCode] || b.defer(b.bind(f._onInput, f, a))
}),
this
},
focus: function () {
this.$input.focus()
},
blur: function () {
this.$input.blur()
},
getLangDir: function () {
return this.dir
},
getQuery: function () {
return this.query || ''
},
setQuery: function (a, b) {
this.setInputValue(a),
this._setQuery(a, b)
},
hasQueryChangedSinceLastFocus: function () {
return this.query !== this.queryWhenFocused
},
getInputValue: function () {
return this.$input.val()
},
setInputValue: function (a) {
this.$input.val(a),
this.clearHintIfInvalid(),
this._checkLanguageDirection()
},
resetInputValue: function () {
this.setInputValue(this.query)
},
getHint: function () {
return this.$hint.val()
},
setHint: function (a) {
this.$hint.val(a)
},
clearHint: function () {
this.setHint('')
},
clearHintIfInvalid: function () {
var a,
b,
c,
d;
a = this.getInputValue(),
b = this.getHint(),
c = a !== b && 0 === b.indexOf(a),
d = '' !== a && c && !this.hasOverflow(),
!d && this.clearHint()
},
hasFocus: function () {
return this.$input.is(':focus')
},
hasOverflow: function () {
var a = this.$input.width() - 2;
return this.$overflowHelper.text(this.getInputValue()),
this.$overflowHelper.width() >= a
},
isCursorAtEnd: function () {
var a,
c,
d;
return a = this.$input.val().length,
c = this.$input[0].selectionStart,
b.isNumber(c) ? c === a : !document.selection || (d = document.selection.createRange(), d.moveStart('character', - a), a === d.text.length)
},
destroy: function () {
this.$hint.off('.tt'),
this.$input.off('.tt'),
this.$overflowHelper.remove(),
this.$hint = this.$input = this.$overflowHelper = a('<div>')
}
}),
c
}(), h = function () {
'use strict';
function c(c, e) {
c = c || {
},
c.templates = c.templates || {
},
c.templates.notFound = c.templates.notFound || c.templates.empty,
c.source || a.error('missing source'),
c.node || a.error('missing node'),
c.name && !h(c.name) && a.error('invalid dataset name: ' + c.name),
e.mixin(this),
this.highlight = !!c.highlight,
this.name = c.name || j(),
this.limit = c.limit || 5,
this.displayFn = d(c.display || c.displayKey),
this.templates = g(c.templates, this.displayFn),
this.source = c.source.__ttAdapter ? c.source.__ttAdapter()  : c.source,
this.async = b.isUndefined(c.async) ? this.source.length > 2 : !!c.async,
this._resetLastSuggestion(),
this.$el = a(c.node).addClass(this.classes.dataset).addClass(this.classes.dataset + '-' + this.name)
}
function d(a) {
function c(b) {
return b[a]
}
return a = a || b.stringify,
b.isFunction(a) ? a : c
}
function g(c, d) {
function e(b) {
return a('<div>').text(d(b))
}
return {
notFound: c.notFound && b.templatify(c.notFound),
pending: c.pending && b.templatify(c.pending),
header: c.header && b.templatify(c.header),
footer: c.footer && b.templatify(c.footer),
suggestion: c.suggestion || e
}
}
function h(a) {
return /^[_a-zA-Z0-9-]+$/.test(a)
}
var i,
j;
return i = {
val: 'tt-selectable-display',
obj: 'tt-selectable-object'
},
j = b.getIdGenerator(),
c.extractData = function (b) {
var c = a(b);
return c.data(i.obj) ? {
val: c.data(i.val) || '',
obj: c.data(i.obj) || null
}
 : null
},
b.mixin(c.prototype, e, {
_overwrite: function (a, b) {
b = b || [
],
b.length ? this._renderSuggestions(a, b)  : this.async && this.templates.pending ? this._renderPending(a)  : !this.async && this.templates.notFound ? this._renderNotFound(a)  : this._empty(),
this.trigger('rendered', this.name, b, !1)
},
_append: function (a, b) {
b = b || [
],
b.length && this.$lastSuggestion.length ? this._appendSuggestions(a, b)  : b.length ? this._renderSuggestions(a, b)  : !this.$lastSuggestion.length && this.templates.notFound && this._renderNotFound(a),
this.trigger('rendered', this.name, b, !0)
},
_renderSuggestions: function (a, b) {
var c;
c = this._getSuggestionsFragment(a, b),
this.$lastSuggestion = c.children().last(),
this.$el.html(c).prepend(this._getHeader(a, b)).append(this._getFooter(a, b))
},
_appendSuggestions: function (a, b) {
var c,
d;
c = this._getSuggestionsFragment(a, b),
d = c.children().last(),
this.$lastSuggestion.after(c),
this.$lastSuggestion = d
},
_renderPending: function (a) {
var b = this.templates.pending;
this._resetLastSuggestion(),
b && this.$el.html(b({
query: a,
dataset: this.name
}))
},
_renderNotFound: function (a) {
var b = this.templates.notFound;
this._resetLastSuggestion(),
b && this.$el.html(b({
query: a,
dataset: this.name
}))
},
_empty: function () {
this.$el.empty(),
this._resetLastSuggestion()
},
_getSuggestionsFragment: function (c, d) {
var e,
g = this;
return e = document.createDocumentFragment(),
b.each(d, function (b) {
var d,
f;
f = g._injectQuery(c, b),
d = a(g.templates.suggestion(f)).data(i.obj, b).data(i.val, g.displayFn(b)).addClass(g.classes.suggestion + ' ' + g.classes.selectable),
e.appendChild(d[0])
}),
this.highlight && f({
className: this.classes.highlight,
node: e,
pattern: c
}),
a(e)
},
_getFooter: function (a, b) {
return this.templates.footer ? this.templates.footer({
query: a,
suggestions: b,
dataset: this.name
})  : null
},
_getHeader: function (a, b) {
return this.templates.header ? this.templates.header({
query: a,
suggestions: b,
dataset: this.name
})  : null
},
_resetLastSuggestion: function () {
this.$lastSuggestion = a()
},
_injectQuery: function (a, c) {
return b.isObject(c) ? b.mixin({
_query: a
}, c)  : c
},
update: function (b) {
function c(a) {
g || (g = !0, a = (a || [
]).slice(0, e.limit), h = a.length, e._overwrite(b, a), h < e.limit && e.async && e.trigger('asyncRequested', b))
}
function d(c) {
c = c || [
],
!f && h < e.limit && (e.cancel = a.noop, h += c.length, e._append(b, c.slice(0, e.limit - h)), e.async && e.trigger('asyncReceived', b))
}
var e = this,
f = !1,
g = !1,
h = 0;
this.cancel(),
this.cancel = function () {
f = !0,
e.cancel = a.noop,
e.async && e.trigger('asyncCanceled', b)
},
this.source(b, c, d),
!g && c([])
},
cancel: a.noop,
clear: function () {
this._empty(),
this.cancel(),
this.trigger('cleared')
},
isEmpty: function () {
return this.$el.is(':empty')
},
destroy: function () {
this.$el = a('<div>')
}
}),
c
}(), i = function () {
'use strict';
function c(c, d) {
function e(b) {
var c = f.$node.find(b.node).first();
return b.node = c.length ? c : a('<div>').appendTo(f.$node),
new h(b, d)
}
var f = this;
c = c || {
},
c.node || a.error('node is required'),
d.mixin(this),
this.$node = a(c.node),
this.query = null,
this.datasets = b.map(c.datasets, e)
}
return b.mixin(c.prototype, e, {
_onSelectableClick: function (b) {
this.trigger('selectableClicked', a(b.currentTarget))
},
_onRendered: function (a, b, c, d) {
this.$node.toggleClass(this.classes.empty, this._allDatasetsEmpty()),
this.trigger('datasetRendered', b, c, d)
},
_onCleared: function () {
this.$node.toggleClass(this.classes.empty, this._allDatasetsEmpty()),
this.trigger('datasetCleared')
},
_propagate: function () {
this.trigger.apply(this, arguments)
},
_allDatasetsEmpty: function () {
function a(a) {
return a.isEmpty()
}
return b.every(this.datasets, a)
},
_getSelectables: function () {
return this.$node.find(this.selectors.selectable)
},
_removeCursor: function () {
var a = this.getActiveSelectable();
a && a.removeClass(this.classes.cursor)
},
_ensureVisible: function (a) {
var b,
c,
d,
e;
b = a.position().top,
c = b + a.outerHeight(!0),
d = this.$node.scrollTop(),
e = this.$node.height() + parseInt(this.$node.css('paddingTop'), 10) + parseInt(this.$node.css('paddingBottom'), 10),
b < 0 ? this.$node.scrollTop(d + b)  : e < c && this.$node.scrollTop(d + (c - e))
},
bind: function () {
var a,
c = this;
return a = b.bind(this._onSelectableClick, this),
this.$node.on('click.tt', this.selectors.selectable, a),
b.each(this.datasets, function (a) {
a.onSync('asyncRequested', c._propagate, c).onSync('asyncCanceled', c._propagate, c).onSync('asyncReceived', c._propagate, c).onSync('rendered', c._onRendered, c).onSync('cleared', c._onCleared, c)
}),
this
},
isOpen: function () {
return this.$node.hasClass(this.classes.open)
},
open: function () {
this.$node.addClass(this.classes.open)
},
close: function () {
this.$node.removeClass(this.classes.open),
this._removeCursor()
},
setLanguageDirection: function (a) {
this.$node.attr('dir', a)
},
selectableRelativeToCursor: function (a) {
var b,
c,
d,
e;
return c = this.getActiveSelectable(),
b = this._getSelectables(),
d = c ? b.index(c)  : - 1,
e = d + a,
e = (e + 1) % (b.length + 1) - 1,
e = e < - 1 ? b.length - 1 : e,
e === - 1 ? null : b.eq(e)
},
setCursor: function (a) {
this._removeCursor(),
(a = a && a.first()) && (a.addClass(this.classes.cursor), this._ensureVisible(a))
},
getSelectableData: function (a) {
return a && a.length ? h.extractData(a)  : null
},
getActiveSelectable: function () {
var a = this._getSelectables().filter(this.selectors.cursor).first();
return a.length ? a : null
},
getTopSelectable: function () {
var a = this._getSelectables().first();
return a.length ? a : null
},
update: function (a) {
function c(b) {
b.update(a)
}
var d = a !== this.query;
return d && (this.query = a, b.each(this.datasets, c)),
d
},
empty: function () {
function a(a) {
a.clear()
}
b.each(this.datasets, a),
this.query = null,
this.$node.addClass(this.classes.empty)
},
destroy: function () {
function c(a) {
a.destroy()
}
this.$node.off('.tt'),
this.$node = a('<div>'),
b.each(this.datasets, c)
}
}),
c
}(), j = function () {
'use strict';
function a() {
i.apply(this, [
].slice.call(arguments, 0))
}
var c = i.prototype;
return b.mixin(a.prototype, i.prototype, {
open: function () {
return !this._allDatasetsEmpty() && this._show(),
c.open.apply(this, [
].slice.call(arguments, 0))
},
close: function () {
return this._hide(),
c.close.apply(this, [
].slice.call(arguments, 0))
},
_onRendered: function () {
return this._allDatasetsEmpty() ? this._hide()  : this.isOpen() && this._show(),
c._onRendered.apply(this, [
].slice.call(arguments, 0))
},
_onCleared: function () {
return this._allDatasetsEmpty() ? this._hide()  : this.isOpen() && this._show(),
c._onCleared.apply(this, [
].slice.call(arguments, 0))
},
setLanguageDirection: function (a) {
return this.$node.css('ltr' === a ? this.css.ltr : this.css.rtl),
c.setLanguageDirection.apply(this, [
].slice.call(arguments, 0))
},
_hide: function () {
this.$node.hide()
},
_show: function () {
this.$node.css('display', 'block')
}
}),
a
}(), k = function () {
'use strict';
function c(c, e) {
var f,
g,
h,
i,
j,
k,
l,
m,
n,
o,
p;
c = c || {
},
c.input || a.error('missing input'),
c.menu || a.error('missing menu'),
c.eventBus || a.error('missing event bus'),
e.mixin(this),
this.eventBus = c.eventBus,
this.minLength = b.isNumber(c.minLength) ? c.minLength : 1,
this.input = c.input,
this.menu = c.menu,
this.enabled = !0,
this.active = !1,
this.input.hasFocus() && this.activate(),
this.dir = this.input.getLangDir(),
this._hacks(),
this.menu.bind().onSync('selectableClicked', this._onSelectableClicked, this).onSync('asyncRequested', this._onAsyncRequested, this).onSync('asyncCanceled', this._onAsyncCanceled, this).onSync('asyncReceived', this._onAsyncReceived, this).onSync('datasetRendered', this._onDatasetRendered, this).onSync('datasetCleared', this._onDatasetCleared, this),
f = d(this, 'activate', 'open', '_onFocused'),
g = d(this, 'deactivate', '_onBlurred'),
h = d(this, 'isActive', 'isOpen', '_onEnterKeyed'),
i = d(this, 'isActive', 'isOpen', '_onTabKeyed'),
j = d(this, 'isActive', '_onEscKeyed'),
k = d(this, 'isActive', 'open', '_onUpKeyed'),
l = d(this, 'isActive', 'open', '_onDownKeyed'),
m = d(this, 'isActive', 'isOpen', '_onLeftKeyed'),
n = d(this, 'isActive', 'isOpen', '_onRightKeyed'),
o = d(this, '_openIfActive', '_onQueryChanged'),
p = d(this, '_openIfActive', '_onWhitespaceChanged'),
this.input.bind().onSync('focused', f, this).onSync('blurred', g, this).onSync('enterKeyed', h, this).onSync('tabKeyed', i, this).onSync('escKeyed', j, this).onSync('upKeyed', k, this).onSync('downKeyed', l, this).onSync('leftKeyed', m, this).onSync('rightKeyed', n, this).onSync('queryChanged', o, this).onSync('whitespaceChanged', p, this).onSync('langDirChanged', this._onLangDirChanged, this)
}
function d(a) {
var c = [
].slice.call(arguments, 1);
return function () {
var d = [
].slice.call(arguments);
b.each(c, function (b) {
return a[b].apply(a, d)
})
}
}
return b.mixin(c.prototype, {
_hacks: function () {
var c,
d;
c = this.input.$input || a('<div>'),
d = this.menu.$node || a('<div>'),
c.on('blur.tt', function (a) {
var e,
f,
g;
e = document.activeElement,
f = d.is(e),
g = d.has(e).length > 0,
b.isMsie() && (f || g) && (a.preventDefault(), a.stopImmediatePropagation(), b.defer(function () {
c.focus()
}))
}),
d.on('mousedown.tt', function (a) {
a.preventDefault()
})
},
_onSelectableClicked: function (a, b) {
this.select(b)
},
_onDatasetCleared: function () {
this._updateHint()
},
_onDatasetRendered: function (a, b, c, d) {
this._updateHint(),
this.eventBus.trigger('render', c, d, b)
},
_onAsyncRequested: function (a, b, c) {
this.eventBus.trigger('asyncrequest', c, b)
},
_onAsyncCanceled: function (a, b, c) {
this.eventBus.trigger('asynccancel', c, b)
},
_onAsyncReceived: function (a, b, c) {
this.eventBus.trigger('asyncreceive', c, b)
},
_onFocused: function () {
this._minLengthMet() && this.menu.update(this.input.getQuery())
},
_onBlurred: function () {
this.input.hasQueryChangedSinceLastFocus() && this.eventBus.trigger('change', this.input.getQuery())
},
_onEnterKeyed: function (a, b) {
var c;
(c = this.menu.getActiveSelectable()) && this.select(c) && b.preventDefault()
},
_onTabKeyed: function (a, b) {
var c;
(c = this.menu.getActiveSelectable()) ? this.select(c) && b.preventDefault()  : (c = this.menu.getTopSelectable()) && this.autocomplete(c) && b.preventDefault()
},
_onEscKeyed: function () {
this.close()
},
_onUpKeyed: function () {
this.moveCursor( - 1)
},
_onDownKeyed: function () {
this.moveCursor(1)
},
_onLeftKeyed: function () {
'rtl' === this.dir && this.input.isCursorAtEnd() && this.autocomplete(this.menu.getTopSelectable())
},
_onRightKeyed: function () {
'ltr' === this.dir && this.input.isCursorAtEnd() && this.autocomplete(this.menu.getTopSelectable())
},
_onQueryChanged: function (a, b) {
this._minLengthMet(b) ? this.menu.update(b)  : this.menu.empty()
},
_onWhitespaceChanged: function () {
this._updateHint()
},
_onLangDirChanged: function (a, b) {
this.dir !== b && (this.dir = b, this.menu.setLanguageDirection(b))
},
_openIfActive: function () {
this.isActive() && this.open()
},
_minLengthMet: function (a) {
return a = b.isString(a) ? a : this.input.getQuery() || '',
a.length >= this.minLength
},
_updateHint: function () {
var a,
c,
d,
e,
f,
h,
i;
a = this.menu.getTopSelectable(),
c = this.menu.getSelectableData(a),
d = this.input.getInputValue(),
!c || b.isBlankString(d) || this.input.hasOverflow() ? this.input.clearHint()  : (e = g.normalizeQuery(d), f = b.escapeRegExChars(e), h = new RegExp('^(?:' + f + ')(.+$)', 'i'), i = h.exec(c.val), i && this.input.setHint(d + i[1]))
},
isEnabled: function () {
return this.enabled
},
enable: function () {
this.enabled = !0
},
disable: function () {
this.enabled = !1
},
isActive: function () {
return this.active
},
activate: function () {
return !!this.isActive() || !(!this.isEnabled() || this.eventBus.before('active')) && (this.active = !0, this.eventBus.trigger('active'), !0)
},
deactivate: function () {
return !this.isActive() || !this.eventBus.before('idle') && (this.active = !1, this.close(), this.eventBus.trigger('idle'), !0)
},
isOpen: function () {
return this.menu.isOpen()
},
open: function () {
return this.isOpen() || this.eventBus.before('open') || (this.menu.open(), this._updateHint(), this.eventBus.trigger('open')),
this.isOpen()
},
close: function () {
return this.isOpen() && !this.eventBus.before('close') && (this.menu.close(), this.input.clearHint(), this.input.resetInputValue(), this.eventBus.trigger('close')),
!this.isOpen()
},
setVal: function (a) {
this.input.setQuery(b.toStr(a))
},
getVal: function () {
return this.input.getQuery()
},
select: function (a) {
var b = this.menu.getSelectableData(a);
return !(!b || this.eventBus.before('select', b.obj)) && (this.input.setQuery(b.val, !0), this.eventBus.trigger('select', b.obj), this.close(), !0)
},
autocomplete: function (a) {
var b,
c,
d;
return b = this.input.getQuery(),
c = this.menu.getSelectableData(a),
d = c && b !== c.val,
!(!d || this.eventBus.before('autocomplete', c.obj)) && (this.input.setQuery(c.val), this.eventBus.trigger('autocomplete', c.obj), !0)
},
moveCursor: function (a) {
var b,
c,
d,
e,
f;
return b = this.input.getQuery(),
c = this.menu.selectableRelativeToCursor(a),
d = this.menu.getSelectableData(c),
e = d ? d.obj : null,
f = this._minLengthMet() && this.menu.update(b),
!f && !this.eventBus.before('cursorchange', e) && (this.menu.setCursor(c), d ? this.input.setInputValue(d.val)  : (this.input.resetInputValue(), this._updateHint()), this.eventBus.trigger('cursorchange', e), !0)
},
destroy: function () {
this.input.destroy(),
this.menu.destroy()
}
}),
c
}(); !function () {
'use strict';
function e(b, c) {
b.each(function () {
var b,
d = a(this);
(b = d.data(p.typeahead)) && c(b, d)
})
}
function f(a, b) {
return a.clone().addClass(b.classes.hint).removeData().css(b.css.hint).css(l(a)).prop('readonly', !0).removeAttr('id name placeholder required').attr({
autocomplete: 'off',
spellcheck: 'false',
tabindex: - 1
})
}
function h(a, b) {
a.data(p.attrs, {
dir: a.attr('dir'),
autocomplete: a.attr('autocomplete'),
spellcheck: a.attr('spellcheck'),
style: a.attr('style')
}),
a.addClass(b.classes.input).attr({
autocomplete: 'off',
spellcheck: !1
});
try {
!a.attr('dir') && a.attr('dir', 'auto')
} catch (a) {
}
return a
}
function l(a) {
return {
backgroundAttachment: a.css('background-attachment'),
backgroundClip: a.css('background-clip'),
backgroundColor: a.css('background-color'),
backgroundImage: a.css('background-image'),
backgroundOrigin: a.css('background-origin'),
backgroundPosition: a.css('background-position'),
backgroundRepeat: a.css('background-repeat'),
backgroundSize: a.css('background-size')
}
}
function m(a) {
var c,
d;
c = a.data(p.www),
d = a.parent().filter(c.selectors.wrapper),
b.each(a.data(p.attrs), function (c, d) {
b.isUndefined(c) ? a.removeAttr(d)  : a.attr(d, c)
}),
a.removeData(p.typeahead).removeData(p.www).removeData(p.attr).removeClass(c.classes.input),
d.length && (a.detach().insertAfter(d), d.remove())
}
function n(c) {
var d,
e;
return d = b.isJQuery(c) || b.isElement(c),
e = d ? a(c).first()  : [
],
e.length ? e : null
}
var o,
p,
q;
o = a.fn.typeahead,
p = {
www: 'tt-www',
attrs: 'tt-attrs',
typeahead: 'tt-typeahead'
},
q = {
initialize: function (e, l) {
function m() {
var c,
m,
q,
r,
s,
t,
u,
v,
w,
x,
y;
b.each(l, function (a) {
a.highlight = !!e.highlight
}),
c = a(this),
m = a(o.html.wrapper),
q = n(e.hint),
r = n(e.menu),
s = e.hint !== !1 && !q,
t = e.menu !== !1 && !r,
s && (q = f(c, o)),
t && (r = a(o.html.menu).css(o.css.menu)),
q && q.val(''),
c = h(c, o),
(s || t) && (m.css(o.css.wrapper), c.css(s ? o.css.input : o.css.inputWithNoHint), c.wrap(m).parent().prepend(s ? q : null).append(t ? r : null)),
y = t ? j : i,
u = new d({
el: c
}),
v = new g({
hint: q,
input: c
}, o),
w = new y({
node: r,
datasets: l
}, o),
x = new k({
input: v,
menu: w,
eventBus: u,
minLength: e.minLength
}, o),
c.data(p.www, o),
c.data(p.typeahead, x)
}
var o;
return l = b.isArray(l) ? l : [
].slice.call(arguments, 1),
e = e || {
},
o = c(e.classNames),
this.each(m)
},
isEnabled: function () {
var a;
return e(this.first(), function (b) {
a = b.isEnabled()
}),
a
},
enable: function () {
return e(this, function (a) {
a.enable()
}),
this
},
disable: function () {
return e(this, function (a) {
a.disable()
}),
this
},
isActive: function () {
var a;
return e(this.first(), function (b) {
a = b.isActive()
}),
a
},
activate: function () {
return e(this, function (a) {
a.activate()
}),
this
},
deactivate: function () {
return e(this, function (a) {
a.deactivate()
}),
this
},
isOpen: function () {
var a;
return e(this.first(), function (b) {
a = b.isOpen()
}),
a
},
open: function () {
return e(this, function (a) {
a.open()
}),
this
},
close: function () {
return e(this, function (a) {
a.close()
}),
this
},
select: function (b) {
var c = !1,
d = a(b);
return e(this.first(), function (a) {
c = a.select(d)
}),
c
},
autocomplete: function (b) {
var c = !1,
d = a(b);
return e(this.first(), function (a) {
c = a.autocomplete(d)
}),
c
},
moveCursor: function (a) {
var b = !1;
return e(this.first(), function (c) {
b = c.moveCursor(a)
}),
b
},
val: function (a) {
var b;
return arguments.length ? (e(this, function (b) {
b.setVal(a)
}), this)  : (e(this.first(), function (a) {
b = a.getVal()
}), b)
},
destroy: function () {
return e(this, function (a, b) {
m(b),
a.destroy()
}),
this
}
},
a.fn.typeahead = function (a) {
return q[a] ? q[a].apply(this, [
].slice.call(arguments, 1))  : q.initialize.apply(this, arguments)
},
a.fn.typeahead.noConflict = function () {
return a.fn.typeahead = o,
this
}
}()
}),
$(document).ready(function () {
function a() {
var a,
c = [
];
for (a in b) c.push(a);
return c
}
var b = {/*
'Milbemax - Chewable tablets for small dogs & puppies': 'product-brand.html#accordion-product-1',
'Milbemax - Chewable tablets for cats & kittens': 'product-brand.html#accordion-product-2',
'Fasinex - 240 cattle': 'product-brand.html#accordion-product-3',
'Elanco Partners': 'business-support.html'*/
},
c = $('#search-field'),
d = ($('.search-field-wrapper .clear-button'), new Bloodhound({
datumTokenizer: Bloodhound.tokenizers.whitespace,
queryTokenizer: Bloodhound.tokenizers.whitespace,
local: a()
}));
$('#dosage-checker').typeahead({
hint: !0,
highlight: !0,
minLength: 1
}, {
name: 'dosage-checker',
source: d,
limit: 50
}).on('typeahead:select', function (a, c) {
var d = window.location.href,
e = d.split('/'),
f = 0,
g = '';
for (f = 0; f < e.length - 1; f++) g += e[f] + '/';
g += b[c],
window.location.href = g
}),
c.typeahead({
hint: !0,
highlight: !0,
minLength: 1
}, {
name: 'search',
source: d,
limit: 50
}).on('typeahead:select', function (a, c) {
var d = window.location.href,
e = d.split('/'),
f = 0,
g = '';
for (f = 0; f < e.length - 1; f++) g += e[f] + '/';
g += b[c],
window.location.href = g
})
});