var app = angular.module("ElancoPortal", ['hc.marked','ngSanitize']);

app.config(['markedProvider', function (markedProvider) {
  markedProvider.setOptions({gfm: true});
}]);

app.config(['$compileProvider',
  function($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|tel|mailto):/);
  }
]);